import React from 'react';
import cn from 'classnames';
import { SmsText, PhoneCallback } from '@simon/ui/Icon';
import { SELECT_OPTION_TYPES } from '../constants';
import styles from './SelectOption.module.scss';

export default function SelectOption({
  title,
  type = SELECT_OPTION_TYPES.regular,
  phone = null,
  subtitle = null,
  onClick = Function.prototype,
  centered = false,
  selected = false,
}) {
  return (
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
      className={cn(styles.networkOptionLink, {
        [styles.centered]: centered,
        [styles.selected]: selected,
      })}
    >
      {title && <h4>{title}</h4>}
      {subtitle && <h5>{subtitle}</h5>}
      {type !== SELECT_OPTION_TYPES.regular && (
        <div className={styles.buttonContainer}>
          {type === SELECT_OPTION_TYPES.sms && <SmsText />}
          {type === SELECT_OPTION_TYPES.call && <PhoneCallback />}
          <p>
            {type === SELECT_OPTION_TYPES.sms && (
              <React.Fragment>Text </React.Fragment>
            )}
            {type === SELECT_OPTION_TYPES.call && (
              <React.Fragment>Call </React.Fragment>
            )}
            {phone}
          </p>
        </div>
      )}
    </a>
  );
}
