import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from './useAuth';

// Catch-all Route for non-authorized users that will save the requested URL and redirect to /auth
// To restore it later see useRestoreOriginalUrl
export default function CatchOriginalRoute({
  authUri = '/auth',
  children = <Navigate to={authUri} />,
}) {
  const { getOriginalUrl, setOriginalUrl } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (
      // Don't override previously set redirect URI.
      !getOriginalUrl() &&
      location.pathname !== '/' &&
      // Going directly to /auth routes should not redirect back.
      !location.pathname.startsWith(authUri)
    ) {
      setOriginalUrl(location.pathname + location.search);
    }
  }, [
    authUri,
    getOriginalUrl,
    location.pathname,
    location.search,
    setOriginalUrl,
  ]);

  // Allow access to deeplinks under authUri base
  if (location.hash.includes(authUri) || location.pathname.includes(authUri)) {
    return null;
  }

  return children;
}

CatchOriginalRoute.propTypes = {
  authUri: PropTypes.string,
  children: PropTypes.node,
};
