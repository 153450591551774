import React, { forwardRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import styles from './DropdownItem.module.scss';
import SelectableContext from './SelectableContext';
import useEventCallback from '@simon/core/hooks/useEventCallback';

const DropdownItem = forwardRef(
  (
    {
      children,
      className,
      as: Component = Link,
      disabled,
      eventKey,
      onClick,
      onSelect,
      ...props
    },
    ref
  ) => {
    const onSelectCtx = useContext(SelectableContext);

    const handleClick = useEventCallback(event => {
      // SafeAnchor handles the disabled case, but we handle it here
      // for other components
      if (disabled) return;
      if (onClick) onClick(event);
      if (onSelectCtx) onSelectCtx(eventKey, event);
      if (onSelect) onSelect(eventKey, event);
    });

    return (
      <Component
        {...props}
        ref={ref}
        className={cn(
          styles.dropdownItem,
          disabled && styles.disabled,
          className
        )}
        disabled={disabled}
        aria-disabled={disabled}
        onClick={handleClick}
      >
        {children}
      </Component>
    );
  }
);

DropdownItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** You can use a custom element type for this component. */
  as: PropTypes.elementType,
  /** Disable the menu item, making it unselectable. */
  disabled: PropTypes.bool,
  /** Value passed to the onSelect handler, useful for identifying the selected menu item. */
  eventKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Callback fired when the menu item is clicked. */
  onClick: PropTypes.func,
};

export default DropdownItem;
