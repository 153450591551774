import React from 'react';
import { components } from 'react-select';
import Spinner from '@simon/ui/Spinner';
import { List } from 'react-virtualized';
import { SkinnyChevron, Arrow } from '@simon/ui/Icon';

const disabledColor = 'hsl(0, 0%, 60%)';

export const underlineStyles = isDisabled => ({
  textDecoration: 'none',
  backgroundImage: `linear-gradient(to right, ${
    isDisabled ? disabledColor : '#2f66a5'
  }  40%, transparent 20%)`,
  backgroundPosition: 'bottom',
  backgroundSize: '3px 1px',
  backgroundRepeat: 'repeat-x',
});

export const sliderBigSelectStyles = {
  container: provided => ({
    ...provided,
    display: 'inline-block',
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    display: 'initial',
    minHeight: 'auto',
    color: isFocused ? '#4a90e2' : '#272727',
    cursor: 'pointer',
    border: 0,
    boxShadow: 0,
    backgroundColor: 'transparent',
    transition: 'none',
    '&:active': {
      transform: 'translateY(1px)',
    },
  }),
  menu: provided => ({
    ...provided,
    width: null,
    minWidth: 180,
    zIndex: 2,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    flexWrap: 'nowrap',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    ...underlineStyles(isDisabled),
    color: isDisabled ? 'hsl(0,0%,60%)' : 'inherit',
    fontSize: 17,
    fontWeight: 'bold',
    lineHeight: '21px',
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 205,
    position: 'relative',
    transform: null,
    top: null,
  }),
};

export const sliderSmallSelectStyles = {
  container: provided => ({
    ...provided,
    display: 'inline-block',
    marginRight: 12,
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    display: 'initial',
    color: isFocused ? '#4a90e2' : '#272727',
    cursor: 'pointer',
    borderTopColor: 'red',
    minHeight: 21,
    border: 0,
    boxShadow: 0,
    backgroundColor: 'transparent',
    transition: 'none',
    '&:active': {
      transform: 'translateY(1px)',
    },
  }),
  menu: provided => ({
    ...provided,
    width: null,
    minWidth: 140,
    zIndex: 2,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
    flexWrap: 'nowrap',
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    color: isDisabled ? 'hsl(0,0%,60%)' : 'inherit',
    fontSize: 14,
    lineHeight: '21px',
    marginLeft: 0,
    marginRight: 0,
    maxWidth: 205,
    position: 'relative',
    transform: null,
    top: null,
  }),
};

export const dropdownSelectStyles = ({
  left,
  right,
  gradient,
  isMulti,
  primary,
  secondary,
  lowProfile,
}) => ({
  container: provided => ({
    ...provided,
    flex: 1,
    minWidth: 0,
  }),
  control: (provided, { menuIsOpen, isFocused }) => ({
    ...provided,
    cursor: 'pointer',
    flex: 1,
    minHeight: 32,
    height: lowProfile ? 32 : 55,
    borderRadius: 3,
    borderColor: isFocused ? '#2f66a5' : '#d5d5d5',
    boxShadow: 0,
    '&:hover': {
      borderColor: menuIsOpen || isFocused ? '#2f66a5' : 'hsl(0, 0%, 70%)',
      ...(left && { zIndex: 1 }),
    },
    ...(gradient && {
      background: 'linear-gradient(0deg, #fff 0%, #f0f0f0 100%)',
    }),
    ...(left && {
      zIndex: menuIsOpen || isFocused ? 1 : null,
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    }),
    ...(right && {
      zIndex: menuIsOpen || isFocused ? 1 : null,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      marginLeft: -1,
    }),
  }),
  menu: provided => ({
    ...provided,
    zIndex: 2,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    marginRight: 5,
  }),
  valueContainer: provided => ({
    ...provided,
    padding: '2px 18px',
    display: 'initial',
    overflow: 'initial',
    ...(isMulti && {
      textOverflow: 'ellipsis',
      maxWidth: '90%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'initial',
      marginLeft: 0,
      marginRight: 0,
      color: secondary ? '#2f66a5' : '#272727',
      fontSize: lowProfile ? 14 : 17,
      lineHeight: '21px',
    }),
  }),
  singleValue: (provided, { isDisabled }) => ({
    ...provided,
    ...((primary || secondary) && {
      textDecoration: 'none',
      backgroundImage: `linear-gradient(to right, ${
        isDisabled ? 'hsl(0, 0%, 60%)' : '#2f66a5'
      }  40%, transparent 20%)`,
      backgroundPosition: 'bottom',
      backgroundSize: '3px 1px',
      backgroundRepeat: 'repeat-x',
    }),
    maxWidth: 'calc(100% - 18px)',
    color: secondary ? '#2f66a5' : '#272727',
    fontSize: 17,
    lineHeight: '21px',
    top: 'initial',
    transform: 'initial',
  }),
  multiValue: (provided, { isDisabled }) => ({
    ...provided,
    textDecoration: 'none',
    backgroundImage: `linear-gradient(to right, ${
      isDisabled ? 'hsl(0, 0%, 60%)' : '#2f66a5'
    }  40%, transparent 20%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '3px 1px',
    backgroundRepeat: 'repeat-x',
    display: 'inline-block',
    verticalAlign: 'middle',
    backgroundColor: 'transparent',
    borderRadius: 0,
    '&::after': {
      content: `','`,
    },
    '&:last-of-type:after': {
      content: `''`,
    },
  }),
  input: provided => ({
    ...provided,
    fontSize: 17,
    lineHeight: '21px',
    ...(isMulti && { display: 'inline-flex' }),
  }),
});

export const dropdownSearchSelectStyles = {
  clearIndicator: provided => ({
    ...provided,
    padding: '7px 8px',
    color: 'hsl(0, 0%, 50%)',
    cursor: 'pointer',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: 'hsla(0, 0%, 81%, 0.45)',
  }),
  control: (provided, { isFocused }) => ({
    ...provided,
    margin: 8,
    borderRadius: 3,
    borderColor: isFocused ? '#2f66a5' : '#d5d5d5',
    boxShadow: 0,
    '&:hover': {
      borderColor: isFocused ? '#2f66a5' : 'hsl(0, 0%, 70%)',
    },
  }),
  menu: provided => ({
    ...provided,
    borderRadius: '0 0 4px 4px',
  }),
};

export const DropdownIndicator = ({
  selectProps,
  darkMode,
  isRFQ,
  openMenu,
  ...props
}) => (
  <components.DropdownIndicator {...props}>
    {darkMode ? (
      <SkinnyChevron
        style={{
          color: 'hsl(0, 0%, 80%)',
          transform: selectProps.menuIsOpen ? 'rotate(0)' : 'rotate(180deg)',
          transition: 'transform 0.2s ease-out',
        }}
        width={13}
        height={13}
      />
    ) : isRFQ ? (
      <Arrow
        style={{
          transition: 'transform 0.2s ease-out',
          transform:
            selectProps.menuIsOpen || openMenu
              ? 'rotate(-180deg)'
              : 'rotate(0)',
          animationDuration: '200ms',
          width: 10,
          height: 10,
          fontWeight: 400,
        }}
      />
    ) : (
      <span
        style={{
          width: 0,
          height: 0,
          borderLeft: `5px solid transparent`,
          borderRight: `5px solid transparent`,
          borderTop: selectProps.isDisabled
            ? `5px solid ${disabledColor}`
            : '5px solid #333a53',
          transition: 'transform 0.2s ease-out',
          transform: selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
          animationDuration: '200ms',
        }}
        onClick={props.onClose}
      />
    )}
  </components.DropdownIndicator>
);

export const LoadingIndicator = ({ lowProfile }) => {
  const height = lowProfile ? 18 : 22;
  return (
    <span style={{ display: 'inherit', paddingLeft: 8, height }}>
      <Spinner size={height} />
    </span>
  );
};

export const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    {children} <DropdownIndicator {...props} />
  </components.ValueContainer>
);

const defaultVirtualizedConfig = {
  width: 350,
  height: 300,
  rowHeight: 40,
};
export const createVirtualizedMenuList = (conf = defaultVirtualizedConfig) => {
  // could also be just "true" and use default settings
  const config = typeof conf !== 'object' ? defaultVirtualizedConfig : conf;

  return function MenuList(props) {
    const itemsCount = props.children.length || 0;
    return (
      <List
        style={{ width: '100%' }}
        width={config.width}
        defaultMenuIsOpen
        height={Math.min(config.height, (itemsCount || 1) * config.rowHeight)}
        rowCount={itemsCount}
        rowHeight={config.rowHeight}
        rowRenderer={({ key, style, index }) => (
          <div key={key} style={style}>
            {props.children[index]}
          </div>
        )}
      />
    );
  };
};
