import { useCallback, useState, useRef, useEffect } from 'react';

/**
 * Source: https://www.npmjs.com/package/rc-util
 */
export default function useMergedState(defaultStateValue, option) {
  const { defaultValue, value, onChange, postState } = option || {};
  const [innerValue, setInnerValue] = useState(() => {
    if (value !== undefined) {
      return value;
    }
    if (defaultValue !== undefined) {
      return typeof defaultValue === 'function' ? defaultValue() : defaultValue;
    }
    return typeof defaultStateValue === 'function'
      ? defaultStateValue()
      : defaultStateValue;
  });

  let mergedValue = value !== undefined ? value : innerValue;
  if (postState) {
    mergedValue = postState(mergedValue);
  }

  // setState
  const onChangeRef = useRef(onChange);
  onChangeRef.current = onChange;
  const triggerChange = useCallback(
    newValue => {
      setInnerValue(newValue);
      if (mergedValue !== newValue && onChangeRef.current) {
        onChangeRef.current(newValue, mergedValue);
      }
    },
    [mergedValue, onChangeRef]
  );

  // Effect of reset value to `undefined`
  const prevValueRef = useRef(value);
  useEffect(() => {
    if (value === undefined && value !== prevValueRef.current) {
      setInnerValue(value);
    }
    prevValueRef.current = value;
  }, [value]);

  return [mergedValue, triggerChange];
}
