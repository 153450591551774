import { useState } from 'react';
import PropTypes from 'prop-types';

import { Close } from '@simon/ui/Icon';
import cn from 'classnames';
import colors from '@simon/core/styles/colors.module.scss';
import styles from './Alert.module.scss';

export default function Alert({
  className,
  showClose,
  showIcon,
  icon,
  message,
  description,
  type,
  children,
  textContainerClassName,
  messageClassName,
  onClose,
}) {
  const [showAlert, setShowAlert] = useState(true);

  const handleCloseClick = () => {
    setShowAlert(false);
    if (onClose) {
      onClose();
    }
  };

  if (!showAlert) {
    return null;
  }

  return (
    <div
      className={cn(
        styles.alert,
        {
          [styles.info]: !type,
          [styles.info]: type === 'info',
          [styles.error]: type === 'error',
          [styles.warning]: type === 'warning',
          [styles.success]: type === 'success',
          [styles.share]: type === 'share',
          [styles.withDescription]: !!description,
        },
        className
      )}
    >
      {showClose && (
        <Close
          width={11}
          height={11}
          color={colors.gray50}
          className={styles.close}
          onClick={handleCloseClick}
        />
      )}
      {showIcon && (
        <div
          className={cn(styles.icon, {
            [styles.withDescription]: !!description,
            [styles.share]: styles.share,
          })}
        >
          {icon}
        </div>
      )}
      <div className={cn(styles.text, textContainerClassName, styles.share)}>
        {message && (
          <h5
            className={cn(styles.message, messageClassName, {
              [styles.withDescription]: !!description,
              [styles.share]: styles.share,
            })}
          >
            {message}
          </h5>
        )}
        {description && <p className={styles.description}>{description}</p>}
        {children}
      </div>
    </div>
  );
}

Alert.propTypes = {
  className: PropTypes.any,
  showClose: PropTypes.bool,
  showIcon: PropTypes.bool,
  icon: PropTypes.any,
  message: PropTypes.any,
  description: PropTypes.any,
  type: PropTypes.string,
  children: PropTypes.any,
  textContainerClassName: PropTypes.string,
  messageClassName: PropTypes.string,
  onClose: PropTypes.func,
};
