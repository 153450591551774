export const logout = () =>
  fetch('/simon/auth/logout', {
    credentials: 'include',
    method: 'GET',
    // don't follow redirection, we just want to cleanup Kong session
    redirect: 'manual',
  }).catch(error => {
    console.warn(error);
  });

export const logoutIcn = () =>
  fetch('/api/v1/logout', {
    credentials: 'include',
  });
