import React from 'react';
import notification from 'antd/lib/notification';
import {
  Button,
  Provider as ThemeProvider,
} from '@icapitalnetwork/supernova-core';
import createTheme from '@simon/ui/createTheme';

/*
According to the documentation, key will be enough to control the behavior of the notification
For some reason we are having issues, and the notification work sometimes
that is why we added an additional flag
*/

let notificationWasDisplayed = false;

function showNotification() {
  if (notificationWasDisplayed) return;

  notification.open({
    message: (
      <ThemeProvider theme={createTheme}>
        <p>Looks like your session has expired.</p>
        <Button size="md" onClick={() => window.location.reload(true)}>
          Reload
        </Button>
        &nbsp; &nbsp;
        <Button
          variant="outlined"
          size="md"
          onClick={() => notification.close('session-expired-pop-up')}
        >
          Close
        </Button>
      </ThemeProvider>
    ),
    key: 'session-expired-pop-up',
    duration: null,
    onClose: () => (notificationWasDisplayed = true),
    style: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      margin: 'auto',
      height: 120,
      width: 340,
      textAlign: 'center',
    },
  });
}

export default showNotification;
