import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { LEFT, RIGHT } from './constants';
import { formatAddon } from './utils';
import styles from './Input.module.scss';

/**
 * Override Button, Dropdown and Select addons with necessary custom styling
 */

const BaseInput = props => {
  const {
    addonAfter,
    addonBefore,
    affixWrapperClassName,
    className,
    error,
    focused,
    groupClassName,
    inputElement,
    isDisabled,
    isReadOnly,
    prefix,
    size,
    suffix,
    wrapperClassName,
  } = props;

  const leftAddon = useMemo(
    () => addonBefore && formatAddon(addonBefore, size, LEFT),
    [addonBefore, size]
  );

  const rightAddon = useMemo(
    () => addonAfter && formatAddon(addonAfter, size, RIGHT),
    [addonAfter, size]
  );

  let textField = inputElement;

  /** Prefix & Suffix */
  if (prefix || suffix) {
    textField = (
      <span
        className={cn(
          styles.affixWrapper,
          styles[size],
          {
            [styles.disabled]: isDisabled,
            [styles.focused]: focused,
            [styles.error]: error,
            [styles.readOnly]: isReadOnly,
          },
          !addonBefore && !addonAfter && className,
          affixWrapperClassName
        )}
      >
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        {inputElement}
        {suffix && <span className={styles.suffix}>{suffix}</span>}
      </span>
    );
  }

  /** Addons */
  if (addonBefore || addonAfter) {
    return (
      <span
        className={cn(
          styles.inputGroupWrapper,
          styles[size],
          className,
          groupClassName
        )}
      >
        <span className={cn(styles.inputGroup, styles[size], wrapperClassName)}>
          {leftAddon}
          {textField}
          {rightAddon}
        </span>
      </span>
    );
  }

  /** Normal input without addons, prefix, suffix */
  return textField;
};

BaseInput.propTypes = {
  /** Base input element */
  inputElement: PropTypes.node,
  /** The label text displayed after (on the right side of) the input field. */
  addonAfter: PropTypes.node,
  /** The label text displayed before (on the left side of) the input field. */
  addonBefore: PropTypes.node,
  /** The prefix element for the Input. */
  prefix: PropTypes.node,
  /** The suffix element for the Input. */
  suffix: PropTypes.node,
  /** Additional CSS class name for input. */
  className: PropTypes.string,
  /** Additional CSS class name for wrapper around `prefix`/`suffix`. */
  affixWrapperClassName: PropTypes.string,
  /** Additional CSS class name for wrapper around `addonBefore`/`addonAfter`. */
  groupClassName: PropTypes.string,
  /** Additional CSS class name for wrapper around `groupClassName`. */
  wrapperClassName: PropTypes.string,
  /** Renders component in `readOnly` state. */
  isReadOnly: PropTypes.bool,
  /** Renders component in `disabled` state. */
  isDisabled: PropTypes.bool,
  /** The size of the input box. */
  size: PropTypes.oneOf(['compact']),
  /** If an error prop passed it will be rendered in place of caption as an error message. */
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  /** Input focus state */
  focused: PropTypes.bool,
};

export default BaseInput;
