import moment from 'moment';
import { AVAILABLE_ENV, ENV, CONFIG } from '@simon/config/env';
import favIconSvg from '@simon/core/images/favicon.svg';
import favIconPng from '@simon/core/images/favicon.png';
import { MOMENT_LOCALE } from '@simon/core/utils/time';
import favIconDevSvg from '@simon/core/images/favicon-development.svg';
import favIconDevPng from '@simon/core/images/favicon-development.png';
import { get } from './services/api';
import { captureException, SCOPES } from '@simon/core/sentry';
import { PRODUCT_LINES } from '@simon/core/sentry/constants';
import showSessionExpiredNotification from '@simon/core/utils/session-expired';
import { parse, stringify } from 'query-string';
import isEmpty from 'lodash/isEmpty';

if (typeof window.history.pushState === 'function') {
  // Removing the confusing state and code added by Okta each redirection from auth to the actual SIMON page
  const { state, code, ...search } = parse(
    decodeURIComponent(location.search.slice(1)),
    {
      arrayFormat: 'index',
    }
  );
  if (state || code) {
    window.history.pushState(
      {},
      document.title,
      [
        location.pathname,
        !isEmpty(search) && `?${stringify(search, { arrayFormat: 'index' })}`,
        location.hash,
      ]
        .filter(Boolean)
        .join('')
    );
  }
}

window.addEventListener(
  'unhandledrejection',
  function appLevelUnhandledRejection(event) {
    captureException(event, {
      type: SCOPES.NOT_HANDLED,
      tags: {
        productLine: PRODUCT_LINES.CORE,
        feature: 'unhandledrejection_listener',
      },
    });
    console.group('Unhandled Promise Rejection');
    console.error(event);
    console.groupEnd('Unhandled Promise Rejection');

    if ((event?.reason?.toString() || '').indexOf('ChunkLoadError') > -1)
      showSessionExpiredNotification();

    return event.preventDefault();
  }
);

window.addEventListener('error', function appLevelUnhandledError(event) {
  captureException(event, {
    type: SCOPES.NOT_HANDLED,
    tags: {
      productLine: PRODUCT_LINES.CORE,
      feature: 'error_listener',
    },
  });
  console.group('Unhandled Error');
  console.error(event);
  console.groupEnd('Unhandled Error');

  if (event?.message === 'Script error.') {
    console.error('Error loading resource');
    showSessionExpiredNotification();
  }

  return false;
});

// fav icons
const pngIcon = document.createElement('link');
pngIcon.rel = 'icon';
pngIcon.type = 'image/png';
pngIcon.href = ENV === AVAILABLE_ENV.PROD ? favIconPng : favIconDevPng;
document.head.appendChild(pngIcon);
const svgIcon = document.createElement('link');
svgIcon.rel = 'icon';
svgIcon.type = 'image/svg+xml';
svgIcon.href = ENV === AVAILABLE_ENV.PROD ? favIconSvg : favIconDevSvg;
document.head.appendChild(svgIcon);

// global `window.simon` object to help figuring out app version
window.simon = {
  ...CONFIG,
  getAPI: () => {
    window.simon.services = {};
    const apiRequests = [
      '/info',
      '/investment-documents/info',
      '/v1/backtest/info',
      '/v1/holdings/info',
      '/v1/learn/info',
      '/v1/my-preferences/info',
      '/v1/tradeweb/info',
      '/v1/ubertable/info',
    ].map(api =>
      get(api)
        .then(res => {
          window.simon.services[api] = res?.version || 'N/A';
        })
        .catch(() => {
          window.simon.services[api] = 'N/A';
        })
    );

    Promise.all(apiRequests)
      .catch(e => console.error(e))
      .finally(() => {
        // eslint-disable-next-line no-console
        console.log(window.simon.config);
      });
  },
};

// Moment.js / DayJS
moment.updateLocale('en', MOMENT_LOCALE);

// Remove locally saved rfq views
[
  'rfqActiveRFQView',
  'rfqActiveRFTView',
  'rfqIdeaGenerationView',
  'rfqMyTemplatesView',
  'rfqPendingRFQView',
  'rfqRequestHistoryView',
].forEach(rfqViewName => localStorage.removeItem(rfqViewName));
