export const getTextContentFromDomElement = dom => {
  const textContent = dom?.textContent;
  if (textContent) {
    if (textContent.length > 30) {
      return textContent.substring(0, 30);
    } else {
      return textContent;
    }
  }
  return '';
};

export const getBreadCrumbMessageFromHint = domElement => {
  if (!domElement) {
    return '';
  }

  const textContent = getTextContentFromDomElement(domElement);
  if (textContent) {
    return textContent;
  }
  //  This gets the actual text in some special cases
  //  ex: MUI buttons, checkboxes
  const parentNode = domElement.parentNode;
  const textContentOfButton = getTextContentFromDomElement(parentNode);
  if (textContentOfButton) {
    return textContentOfButton;
  }

  return '';
};
