import React, { forwardRef, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RowContext from './RowContext';
import ResponsiveObserve from './responsiveObserve';
import styles from './Grid.module.scss';

const responsiveArray = ['xxl', 'xl', 'lg', 'md', 'sm', 'xs'];

const Row = forwardRef((props, ref) => {
  const [screens, setScreens] = useState({
    xs: true,
    sm: true,
    md: true,
    lg: true,
    xl: true,
    xxl: true,
  });
  const gutterRef = useRef();
  gutterRef.current = props.gutter;

  useEffect(() => {
    const token = ResponsiveObserve.subscribe(screen => {
      const currentGutter = gutterRef.current || 0;
      if (
        (!Array.isArray(currentGutter) && typeof currentGutter === 'object') ||
        (Array.isArray(currentGutter) &&
          (typeof currentGutter[0] === 'object' ||
            typeof currentGutter[1] === 'object'))
      ) {
        setScreens(screen);
      }
    });
    return () => {
      ResponsiveObserve.unsubscribe(token);
    };
  }, []);

  const getGutter = () => {
    const results = [0, 0];
    const { gutter = 0 } = props;
    const normalizedGutter = Array.isArray(gutter) ? gutter : [gutter, 0];

    normalizedGutter.forEach((g, index) => {
      if (typeof g === 'object') {
        for (let i = 0; i < responsiveArray.length; i++) {
          const breakpoint = responsiveArray[i];
          if (screens[breakpoint] && g[breakpoint] !== undefined) {
            results[index] = g[breakpoint];
            break;
          }
        }
      } else {
        results[index] = g || 0;
      }
    });
    return results;
  };

  const { justify, align, className, style, children, ...others } = props;
  const gutter = getGutter();
  const classes = cn(
    styles.row,
    {
      [styles[`row-${justify}`]]: justify,
      [styles[`row-${align}`]]: align,
    },
    className
  );
  const rowStyle = {
    ...(gutter[0] > 0
      ? {
          marginLeft: gutter[0] / -2,
          marginRight: gutter[0] / -2,
        }
      : {}),
    ...(gutter[1] > 0
      ? {
          marginTop: gutter[1] / -2,
          marginBottom: gutter[1] / 2,
        }
      : {}),
    ...style,
  };
  const otherProps = { ...others };
  delete otherProps.gutter;

  return (
    <RowContext.Provider value={{ gutter }}>
      <div {...otherProps} className={classes} style={rowStyle} ref={ref}>
        {children}
      </div>
    </RowContext.Provider>
  );
});

Row.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  // Spacing between grids, could be a number or a object like { xs: 8, sm: 16, md: 24}.
  // Or you can use array to make horizontal and vertical spacing work at the same time [horizontal, vertical]
  gutter: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      xs: PropTypes.number,
      sm: PropTypes.number,
      md: PropTypes.number,
      lg: PropTypes.number,
      xl: PropTypes.number,
      xxl: PropTypes.number,
    }),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(
      PropTypes.shape({
        xs: PropTypes.number,
        sm: PropTypes.number,
        md: PropTypes.number,
        lg: PropTypes.number,
        xl: PropTypes.number,
        xxl: PropTypes.number,
      })
    ),
  ]),
  align: PropTypes.oneOf(['top', 'middle', 'bottom', 'stretch']),
  justify: PropTypes.oneOf([
    'start',
    'end',
    'center',
    'space-around',
    'space-between',
  ]),
};

export default Row;
