import { Link, Navigate, useLocation } from 'react-router-dom';
import useAuth from './useAuth';
import React, { useState } from 'react';
import PageTitle from './common/PageTitle';
import { CheckMarkCircle } from '@simon/ui/Icon';
import Button from '@icapitalnetwork/supernova-core/Button';
import CircularProgress from '@icapitalnetwork/supernova-core/CircularProgress';
import TextField from '@icapitalnetwork/supernova-core/TextField';
import InputAdornment from '@icapitalnetwork/supernova-core/InputAdornment';
import LockIcon from '@mui/icons-material/Lock';
import ErrorMessage from './common/ErrorMessage';
import Divider from '@icapitalnetwork/supernova-core/Divider';
import { EVENT } from './constants';

export default function ExpiredPassword() {
  const location = useLocation();
  const { loginSessionToken, onEvent, transaction, expiredPasswordTxCtx } =
    useAuth();
  const [formState, setFormState] = useState({
    status: 'pending',
    transaction:
      transaction.status === 'PASSWORD_EXPIRED'
        ? transaction
        : expiredPasswordTxCtx,
    error: null,
  });
  const [oldPassword, setOldPassword] = useState(
    // If coming from LoginPage we can prefill this field using the password which user just submitted.
    location.state?.oldPassword || ''
  );
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  // This page is only for PASSWORD_EXPIRED transactions.
  if (formState.transaction?.status !== 'PASSWORD_EXPIRED') {
    return <Navigate to=".." />;
  }

  const {
    user: { profile },
  } = formState.transaction;

  // Password changed successfully.
  if (formState.status === 'change-ok') {
    return (
      <React.Fragment>
        <PageTitle
          title={
            <React.Fragment>
              <CheckMarkCircle width={12} height={12} color="#24a148" /> Success
            </React.Fragment>
          }
          subtitle={
            <React.Fragment>
              Your password has been changed successfully.
              <br />
              <strong>{profile.login}</strong>
            </React.Fragment>
          }
        />
        <Button size="md" fullWidth component={Link} to="/">
          Okay
        </Button>
      </React.Fragment>
    );
  }

  // Change password
  return (
    <React.Fragment>
      <PageTitle
        title="Password Expired"
        subtitle={
          <React.Fragment>
            Enter a new password for your account.
            <br />
            <strong>{profile.login}</strong>
          </React.Fragment>
        }
      />
      <form
        onSubmit={async e => {
          e.preventDefault();
          setFormState(s => ({ ...s, status: 'change-fetching' }));
          try {
            const transaction = await formState.transaction.changePassword({
              oldPassword,
              newPassword,
            });
            if (transaction.status === 'SUCCESS') {
              // get tokens using the sessionToken
              await loginSessionToken(transaction.sessionToken);
              onEvent(EVENT.ExpiredPasswordChangeSuccess);
              setFormState(s => ({ ...s, status: 'change-ok', transaction }));
              return;
            }
            throw `Okta transaction.changePassword(). We cannot handle the ${transaction.status} status.`;
          } catch (e) {
            onEvent(EVENT.ExpiredPasswordChangeError, { error: e });
            setFormState(s => ({
              ...s,
              status: 'change-error',
              error: 'Password requirements were not met.',
            }));
          }
        }}
      >
        <TextField
          fullWidth
          hideClearButton
          autoFocus={!oldPassword}
          type="password"
          autoComplete="current-password"
          placeholder="Old Password"
          name="current-password"
          value={oldPassword}
          onChange={e => {
            setOldPassword(e.target.value);
          }}
          required
          aria-label="Old Password"
          InputProps={{
            startAdornment: (
              // @ts-ignore
              <InputAdornment position="start">
                <LockIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <Divider sx={{ m: '2rem 0' }} />
        <TextField
          fullWidth
          hideClearButton
          autoFocus={!!oldPassword}
          type="password"
          autoComplete="new-password"
          placeholder="New Password"
          name="new-password"
          value={newPassword}
          onChange={e => {
            setNewPassword(e.target.value);
          }}
          required
          aria-label="New Password"
          InputProps={{
            startAdornment: (
              // @ts-ignore
              <InputAdornment position="start">
                <LockIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          hideClearButton
          type="password"
          autoComplete="new-password"
          placeholder="Confirm New Password"
          name="confirm-new-password"
          value={confirmNewPassword}
          onChange={e => {
            setConfirmNewPassword(e.target.value);
          }}
          required
          aria-label="Confirm New Password"
          sx={{ mt: 0.5 }}
          InputProps={{
            startAdornment: (
              // @ts-ignore
              <InputAdornment position="start">
                <LockIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        {formState.error && <ErrorMessage error={formState.error} />}
        <Button
          fullWidth
          size="md"
          type="submit"
          disabled={
            formState.status === 'change-fetching' ||
            !newPassword ||
            !confirmNewPassword ||
            newPassword !== confirmNewPassword
          }
          sx={{ mt: 1 }}
          startIcon={
            formState.status === 'change-fetching' && (
              <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
            )
          }
        >
          Reset Password
        </Button>
      </form>
      <Button
        variant="text"
        size="md"
        fullWidth
        sx={{ mt: 0.5 }}
        component={Link}
        to=".."
      >
        Back to Sign-in
      </Button>
    </React.Fragment>
  );
}
