import { useCallback, useState, useEffect, useRef } from 'react';
import { AUTH_LOGOUT_REASON_KEY, AUTH_LOGOUT_REASONS } from './constants';
import useAuth from './useAuth';

/**
 * Function that starts a timer for a given interval in minutes and ticks
 * every second
 *
 * Will dispatch a warning when when the timer is 3 minutes to finish and dispatches
 * a timeout callback when the timeout is reached
 *
 * @param {number} timeout - Number in minutes for the timeout duration
 * @param {Function} onWarn - The callback that will be called when the interval if 3 minutes away from finishing
 * @param {Function} onTimeout - Tells when the specified number of minutes have passed
 * @returns - Start and Stop functions for the timer
 */
const inactivityTimer = (timeout, onWarn, onTimeout) => {
  const WARNING_MINUTES = 3;
  const TIMEOUT_TIME_IN_SECONDS = timeout * 60;
  const WARN_TIME_IN_SECONDS = TIMEOUT_TIME_IN_SECONDS - WARNING_MINUTES * 60;

  let idleTimeInSeconds = 0;
  let interval;

  const handleInterval = () => {
    idleTimeInSeconds++;

    if (idleTimeInSeconds === WARN_TIME_IN_SECONDS) {
      onWarn();
    }
    if (idleTimeInSeconds === TIMEOUT_TIME_IN_SECONDS) {
      onTimeout();
      clearInterval(interval);
    }
  };

  interval = setInterval(handleInterval, 1000);

  const resetIdleTime = () => {
    // If the warning was already dispatched, we will not reset the timer anymore
    if (idleTimeInSeconds > WARN_TIME_IN_SECONDS) return;
    idleTimeInSeconds = 0;
  };

  window.addEventListener('click', resetIdleTime);
  window.addEventListener('keypress', resetIdleTime);
  window.addEventListener('scroll', resetIdleTime);

  return () => {
    clearInterval(interval);
    window.removeEventListener('click', resetIdleTime);
    window.removeEventListener('keypress', resetIdleTime);
    window.removeEventListener('scroll', resetIdleTime);
  };
};

/**
 * Hook to set an inactivity timeout. It will start the timeout on the first render
 * and allow only ony inactivity timeout to be active
 *
 * @param {*} props
 * @param {number} props.timeout - Timeout value in minutes
 */
const useInactivityTimer = timeout => {
  const { logout } = useAuth();
  const [isInactivityWarning, setIsInactivityWarning] = useState(false);
  const stopTimers = useRef(null);

  const setInactivityLogoutStorage = useCallback(() => {
    sessionStorage.setItem(
      AUTH_LOGOUT_REASON_KEY,
      AUTH_LOGOUT_REASONS.INACTIVITY
    );
  }, []);

  const start = useCallback(() => {
    if (stopTimers.current || !timeout) return false;

    sessionStorage.removeItem(AUTH_LOGOUT_REASON_KEY);

    stopTimers.current = inactivityTimer(
      timeout,
      () => {
        setIsInactivityWarning(true);
      },
      () => {
        setIsInactivityWarning(false);
        setInactivityLogoutStorage();
        logout();
      }
    );
    return stop;
  }, [setInactivityLogoutStorage, stop, logout, timeout]);

  const stop = useCallback(() => {
    stopTimers.current && stopTimers.current();
    stopTimers.current = null;
  }, []);

  const restart = useCallback(() => {
    stop();
    start();
  }, [start, stop]);

  useEffect(() => {
    start();
  }, [start]);

  return {
    isInactivityWarning,
    setIsInactivityWarning,
    resetInactivityTimer: restart,
    setInactivityLogoutStorage,
  };
};

export { useInactivityTimer };
