import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDropdownMenu } from 'react-overlays/DropdownMenu';
import styles from './DropdownMenu.module.scss';

const DropdownMenu = ({
  alignEnd = true,
  children,
  className,
  rootCloseEvent = 'click',
  flip = true,
  fixed = false,
  offset,
  show: showProps,
  renderOnMount,
  as: Component = 'div',
  popperConfig,
  ...props
}) => {
  const [menuProps, { show }] = useDropdownMenu({
    alignEnd,
    flip,
    fixed,
    offset: offset || [alignEnd ? -5 : 5, 5],
    rootCloseEvent,
    show: showProps,
    popperConfig,
  });

  return (
    <Component
      {...props}
      {...menuProps}
      className={cn(styles.dropdownMenu, show && styles.show, className)}
    >
      {children}
    </Component>
  );
};

DropdownMenu.propTypes = {
  /**
   * Align the menu to the 'end' side of the placement side of the Dropdown toggle. The default placement is `top-start` or `bottom-start`.
   */
  alignEnd: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  /** Controls the visibility of the Dropdown menu  */
  show: PropTypes.bool,
  /** Whether to render the dropdown menu in the DOM before the first time it is shown */
  renderOnMount: PropTypes.bool,
  /**
   * Which event when fired outside the component will cause it to be closed
   */
  rootCloseEvent: PropTypes.oneOf(['click', 'mousedown']),
  /**
   * Enables the Popper.js flip modifier, allowing the Dropdown to automatically
   * adjust its placement in case of overlap with the viewport or toggle. Refer
   * to the Popper.js docs for more info.
   * */
  flip: PropTypes.bool,
  /**
   * Set this property to `true` to tell Popper.js to use the `position: fixed` strategy
   * to position the popper element. By default, it's false, meaning that it will use the
   * `position: absolute` strategy.
   */
  fixed: PropTypes.bool,
  /**
   * Define an offset distance between the Menu and the Toggle
   */
  offset: PropTypes.arrayOf(PropTypes.number),
  /**
   * Control the rendering of the DropdownMenu. All non-menu props
   * (listed here) are passed through to the `as` Component.
   *
   * If providing a custom, non DOM, component. the `show`, `close` and `placement` props
   * are also injected and should be handled appropriately.
   */
  as: PropTypes.elementType,
  /**
   * A set of popper options and props passed directly to Popper.
   */
  popperConfig: PropTypes.object,
};

export default DropdownMenu;
