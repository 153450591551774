import React from 'react';

const ICapitalLogo = ({
  width = 134,
  height = 36,
  translucent,
  className = null,
}) => (
  <svg width={width} height={height} className={className} viewBox="0 0 134 36">
    <title>iCapital</title>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={translucent ? '#fff' : '#0077AA'}>
        <path d="M23.0677 25.834C13.3261 25.8521 9.94848 23.619 9.93049 12.96C9.9125 2.30096 13.2766 0.0181747 23.0183 9.32121e-05C27.1335 -0.00894752 30.9834 0.641986 32.517 0.940331C32.9173 1.01718 33.1737 1.36525 33.1107 1.7314L32.7644 3.74748C32.6969 4.13623 32.2967 4.39841 31.8694 4.33061C30.3447 4.07747 26.8007 3.56215 23.522 3.57119C15.1971 3.58475 14.365 5.33413 14.3785 12.96C14.392 20.283 15.2286 22.403 23.4275 22.3895C26.7287 22.385 30.3672 21.8696 31.9099 21.6255C32.3281 21.5577 32.7239 21.8154 32.7959 22.1996L33.1377 24.0711C33.2052 24.4372 32.9488 24.7898 32.5485 24.8712C31.0104 25.174 27.174 25.834 23.0588 25.843" />
        <path d="M0.0314859 25.0475V23.6823C0.0314859 23.4246 0.224874 23.2031 0.50372 23.1489L1.85748 22.8912C2.31622 22.8144 2.44215 22.7014 2.43765 22.2087L2.41967 11.3191C2.41967 10.8264 2.29374 10.7134 1.83499 10.6365L0.476736 10.3879C0.19789 10.3382 0 10.1167 0 9.85902V8.49387C0 8.19552 0.260859 7.95143 0.593676 7.95143L5.94123 7.94238C6.26954 7.94238 6.5349 8.18196 6.5394 8.48483L6.56638 22.2087C6.56638 22.7014 6.65183 22.8144 7.15106 22.8912L8.46883 23.1399C8.74318 23.1941 8.94107 23.4111 8.94107 23.6687V25.0384C8.94107 25.3368 8.68021 25.5809 8.34739 25.5809L0.629657 25.5944C0.301338 25.5944 0.035981 25.3503 0.035981 25.052" />
        <path d="M76.3769 24.9073V23.5422C76.3769 23.2845 76.5703 23.063 76.8492 23.0088L78.2029 22.7511C78.6617 22.6743 78.7876 22.5612 78.7831 22.0685L78.7651 11.179C78.7651 10.6862 78.6392 10.5732 78.1804 10.4964L76.8222 10.2478C76.5433 10.198 76.3455 9.97654 76.3455 9.71888V8.35373C76.3455 8.05539 76.6063 7.81128 76.9391 7.81128L82.2867 7.80225C82.615 7.80225 82.8803 8.04182 82.8848 8.34469L82.9118 22.0685C82.9118 22.5612 82.9973 22.6743 83.4965 22.7511L84.8143 22.9997C85.0886 23.054 85.2865 23.2709 85.2865 23.5286V24.8983C85.2865 25.1966 85.0212 25.4407 84.6929 25.4407L76.9751 25.4543C76.6468 25.4543 76.3814 25.2102 76.3814 24.9118" />
        <path d="M128.4 23.0992C128.319 23.0043 128.197 22.9365 128.062 22.9139L126.745 22.6653C126.245 22.5884 126.16 22.4754 126.16 21.9827L126.119 1.14381C126.119 0.84547 125.854 0.605894 125.53 0.610414L120.174 0.619457C119.85 0.619457 119.585 0.859038 119.585 1.15738V2.53609C119.585 2.78923 119.787 3.01073 120.061 3.06045L121.42 3.31359C121.878 3.39044 122.004 3.50345 122.004 3.99617L122.036 21.2278C122.036 22.0324 121.532 22.6472 120.695 22.7105C120.552 22.7195 120.839 22.7241 120.646 22.7286C120.628 22.7286 119.657 22.7286 119.643 22.7286C119.472 22.7286 119.733 22.7286 119.603 22.7195C118.735 22.6653 118.258 21.9918 118.253 21.2504L118.24 13.055C118.24 10.6637 117.484 9.30308 115.861 8.5075C114.403 7.78876 112.195 7.52657 109.24 7.53562C105.215 7.54466 102.062 8.11874 100.839 8.3764C100.529 8.43969 100.335 8.71543 100.394 9.00022L100.767 10.8038C100.83 11.0977 101.14 11.292 101.46 11.2288C102.674 10.9982 105.624 10.501 108.62 10.4965C110.324 10.4965 111.574 10.5688 112.452 10.9078C113.697 11.3734 114.147 12.2504 114.12 13.6924C114.111 14.0314 113.819 14.3116 113.445 14.3162L106.002 14.4066C100.717 14.4925 99.1344 15.7853 99.1433 19.9983C99.1523 24.4734 100.942 25.6849 107.104 25.6759C108.098 25.6759 109.587 25.59 110.333 25.5448C110.639 25.5267 110.931 25.4544 111.21 25.3368C111.966 25.0114 113.522 24.3559 113.931 24.2971C114.327 24.2384 114.529 24.478 114.637 24.7447C114.66 24.8125 114.682 24.8803 114.696 24.9526C114.75 25.2057 114.988 25.3956 115.276 25.3956L119.009 25.3866H120.228L127.945 25.3685C128.274 25.3685 128.539 25.1244 128.539 24.826V23.4564C128.539 23.3298 128.485 23.2077 128.404 23.1128L128.4 23.0992ZM114.138 21.5759C114.138 21.8335 113.94 22.055 113.661 22.1048C112.586 22.2901 109.888 22.7105 107.522 22.715C104.567 22.7195 103.358 22.5704 103.353 19.9892C103.349 16.8385 104.846 17.1776 107.761 17.1369L113.531 17.0239C113.859 17.0194 114.134 17.2589 114.134 17.5618L114.143 21.5804L114.138 21.5759Z" />
        <path d="M94.4434 25.5676C89.3657 25.5767 88.7361 23.7956 88.7271 19.7364L88.7091 11.1612C88.7091 10.8674 88.4482 10.6323 88.1289 10.6323H85.3089C84.9896 10.6368 84.7288 10.4018 84.7288 10.1125V8.32242C84.7288 8.03312 84.9851 7.79354 85.3044 7.79354H88.1244C88.4437 7.78902 88.7046 7.54944 88.7046 7.26014L88.6956 3.6122C88.6956 3.31837 88.9519 3.08332 89.2758 3.08332H92.2396C92.5589 3.0788 92.8198 3.31385 92.8198 3.60316L92.8288 7.2511C92.8288 7.5404 93.0896 7.77998 93.409 7.77998L97.9964 7.77094C98.3158 7.77094 98.5766 8.00599 98.5766 8.29982V10.0899C98.5766 10.3837 98.3203 10.6188 98.0009 10.6188L93.418 10.6278C93.0986 10.6278 92.8378 10.8674 92.8378 11.1567L92.8558 20.3014C92.8558 22.2723 93.1121 22.8057 95.4823 22.8012C96.067 22.8012 97.0295 22.6746 97.6456 22.5842C97.965 22.539 98.2663 22.7424 98.3113 23.0362L98.5406 24.5731C98.5811 24.8444 98.3922 25.093 98.1044 25.1563C97.3848 25.3145 95.9771 25.5721 94.4479 25.5721" />
        <path d="M50.2328 25.4996C49.9315 25.4996 49.6526 25.3187 49.5897 25.052C49.5087 24.7175 49.3108 24.3333 48.8206 24.4056C48.4113 24.4644 46.8552 25.1198 46.0996 25.4453C45.8253 25.5628 45.5284 25.6352 45.2226 25.6532C44.476 25.6985 42.9873 25.7798 41.9934 25.7843C35.8318 25.7979 34.0417 24.5864 34.0328 20.1068C34.0238 15.8938 35.6069 14.601 40.8915 14.5196L48.1819 14.4292C48.8026 14.4111 48.9825 14.1625 49.005 13.6698C49.005 12.3046 48.5418 11.4683 47.3364 11.0163C46.4594 10.6773 45.2136 10.6049 43.5045 10.6049C40.5362 10.6095 37.6218 11.0931 36.3805 11.3282C36.0341 11.3915 35.7058 11.1881 35.6384 10.8716L35.2831 9.14034C35.2201 8.83748 35.427 8.54365 35.7553 8.47585C37.0011 8.21367 40.1314 7.64862 44.1207 7.6441C47.0756 7.63958 49.2838 7.90177 50.741 8.61598C52.3646 9.41157 53.1202 10.7722 53.1247 13.1635L53.1427 22.1138C53.1427 22.6065 53.2282 22.7195 53.7274 22.7964L55.0182 23.0405C55.3105 23.0947 55.5174 23.3298 55.5174 23.601V24.9119C55.5174 25.2283 55.2386 25.486 54.8922 25.486L50.2283 25.495L50.2328 25.4996ZM49.0185 17.7019C49.0185 17.381 48.7262 17.1233 48.3753 17.1278L42.65 17.2408C39.7356 17.286 38.2379 16.947 38.2424 20.0932C38.2469 22.6743 39.4568 22.8235 42.4116 22.819C44.7548 22.8145 47.4174 22.4031 48.5148 22.2178C48.8116 22.168 49.0275 21.9285 49.0275 21.6527L49.0185 17.7019Z" />
        <path d="M67.2693 7.68044C66.6711 7.68044 65.9425 7.68949 65.4253 7.72565C64.5753 7.75277 64.0311 7.86578 63.5768 8.03303L62.2141 8.56191C61.7913 8.72917 61.0852 9.03204 60.7299 9.03204C60.3071 9.03204 60.1137 8.29973 60.1137 8.29973C59.9968 8.03755 59.8124 7.92907 59.4211 7.92907L55.045 7.93811C54.7887 7.93811 54.4829 8.15961 54.4874 8.46699V9.8457C54.4874 10.0898 54.6807 10.3294 54.9776 10.3791L56.3223 10.6277C56.7766 10.7046 56.8935 10.7995 56.907 11.3013L56.9385 28.6143C56.9385 29.1115 56.8171 29.2245 56.3583 29.3014L55.0046 29.559C54.7257 29.6133 54.5278 29.8348 54.5278 30.0924V31.4576C54.5278 31.7559 54.7977 32 55.126 32L62.8437 31.9865C63.1721 31.9865 63.4374 31.7424 63.4374 31.444V30.0743C63.4374 29.8167 63.235 29.5952 62.9607 29.5455L61.6429 29.2968C61.1437 29.2245 61.0582 29.107 61.0582 28.6143V25.6489C61.0537 25.3958 61.3101 25.2059 61.5799 25.2647C62.4839 25.459 64.4224 25.8207 66.4238 25.8162C73.7907 25.8026 75.4098 23.1808 75.4008 16.6579C75.3873 10.1712 73.8042 7.6714 67.2648 7.68044H67.2693ZM66.0864 22.8192C64.112 22.8192 62.4435 22.6429 61.6114 22.5299C61.2831 22.4847 61.0447 22.2315 61.0447 21.9332L61.0267 11.7171C61.0267 11.4052 61.2651 11.1385 61.6024 11.0843C62.5199 10.9396 64.3954 10.682 66.1854 10.6775C70.3051 10.6684 71.1821 11.8437 71.1911 16.6986C71.2001 21.8202 70.2871 22.8101 66.082 22.8146" />
        <path d="M77.8386 4.47075V1.1528C77.8296 0.85446 78.0995 0.610361 78.4278 0.610361L82.2776 0.601318C82.606 0.601318 82.8713 0.840896 82.8713 1.14376V4.46172C82.8803 4.76006 82.6105 5.00416 82.2821 5.00416L78.4368 5.0132C78.1085 5.0132 77.8431 4.7691 77.8431 4.47075" />
        <path d="M1.50657 4.47075V1.1528C1.49757 0.85446 1.76742 0.610361 2.09574 0.610361L5.94562 0.601318C6.27393 0.601318 6.53929 0.840896 6.53929 1.14376V4.46172C6.54829 4.76006 6.27843 5.00416 5.95011 5.00416L2.10474 5.0132C1.77642 5.0132 1.51106 4.7691 1.51106 4.47075" />
        <path d="M131.251 23.1941H131.957C132.155 23.1941 132.308 23.2483 132.407 23.3523C132.492 23.4382 132.542 23.5602 132.542 23.7094C132.542 23.9671 132.407 24.1253 132.209 24.1976L132.587 24.7536H132.187L131.854 24.2564H131.588V24.7536H131.247V23.1986L131.251 23.1941ZM131.935 23.949C132.101 23.949 132.196 23.8586 132.196 23.7275C132.196 23.5738 132.092 23.5015 131.93 23.5015H131.593V23.949H131.939H131.935Z" />
        <path d="M131.84 22.7465C132.51 22.7465 133.059 23.2935 133.059 23.9715C133.059 24.6496 132.515 25.1966 131.84 25.1966C131.166 25.1966 130.621 24.6496 130.621 23.9715C130.621 23.2935 131.166 22.7465 131.84 22.7465ZM131.84 22.3804C130.968 22.3804 130.257 23.0901 130.257 23.9715C130.257 24.853 130.963 25.5627 131.84 25.5627C132.717 25.5627 133.419 24.853 133.419 23.9715C133.419 23.0901 132.713 22.3804 131.84 22.3804Z" />
      </g>
    </g>
  </svg>
);

export default ICapitalLogo;
