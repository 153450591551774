import isString from 'lodash/isString';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';
import { publicPathOrigin } from '@simon/config/autoPublicPath';
import { set, get } from 'idb-keyval';

export const AJAX_STATUS = {
  ERROR: 'error',
  OK: 'ok',
  PENDING: 'pending',
  PROCESSING: 'in-progress',
};
const ERROR_ARRAY = [];
export function getErrorList() {
  return ERROR_ARRAY;
}

export const getErrorCodeMessage = code =>
  ({
    400: 'Bad Request.',
    401: 'Unauthorized.',
    403: 'The access to the resource is forbidden.',
    404: 'The resource is not available.',
    500: 'The system was not able to process the request properly.',
    503: 'The server is not available.',
    504: 'The server failed to respond.',
  }[code] || 'Something went wrong.');

class NetworkError extends Error {
  constructor({ status, statusText, headers }, body, uri) {
    super();
    // Maintains proper stack trace for where our error was thrown (only available on V8)
    // if (Error.captureStackTrace) {
    //   Error.captureStackTrace(this, NetworkError);
    // }
    this.name = `NetworkError (${status})`;
    this.status = status;
    this.statusText = statusText;
    this.headers = headers ? Object.fromEntries(headers) : {};

    storeErrorDetails(this.headers, uri);

    if (isString(body)) {
      // body is text (might be empty)
      this.message = body || getErrorCodeMessage(status);
    } else if (isPlainObject(body)) {
      Object.assign(this, body);

      // response body is an object (possibly with a custom `message` prop)
      // but if not, use a generic message
      this.message =
        body.message || body.messages?.[0] || getErrorCodeMessage(status);
    } else {
      this.message = getErrorCodeMessage(status);
    }
  }
}

const defaultInterceptors = {
  request: (url, config) => [url, config],
  response: async res => {
    const textBody = (await res.text()) || res.statusText;

    if (
      process.env.NODE_ENV === 'production' ||
      process.env.NODE_ENV === 'test'
    ) {
      // detect a special HTML response indicating an expired session
      if (textBody?.slice(0, 12) === '<html><head>') {
        throw new NetworkError({ status: 401 });
      }
    }

    try {
      // try to parse as json
      return JSON.parse(textBody);
    } catch (e) {
      return textBody;
    }
  },
  responseError: error => {
    // throwing inside async fn === Promise.reject
    throw error;
  },
};

/**
 * This function will cache any result provided by the given function
 *
 * It will use indexedDb and cache only one time with the arguments as the DB key
 *
 * @param  fn - The function that will send the result to cache
 * @returns fn or previously cached results
 */
const cached =
  fn =>
  async (...args) => {
    const key = JSON.stringify(args);

    const cache = await get(key);

    if (cache) {
      return cache;
    }

    const res = await fn(...args);
    set(key, res);

    return res;
  };

/**
 Factory function which returns an enhanced interface to work with backend APIs
 Supports attaching interceptors at different events, inspired by Angular interceptors.

 @param fetch : Function - Fetch implementation, `window.Fetch` for browsers, `node-fetch` for Node
 @param interceptors : Object - see defaultInterceptors
 */
function createApi({
  fetch,
  interceptors: {
    request: interceptRequest = defaultInterceptors.request,
    response: interceptResponse = defaultInterceptors.response,
    responseError: interceptResponseError = defaultInterceptors.responseError,
  } = {},
}) {
  const CACHE_ENABLED = process.env.REACT_APP_ENABLE_API_CACHE === 'true';
  const api = async (uri, params = { headers: {} }) => {
    const [url, config] = await interceptRequest(uri, params);

    try {
      const res = await fetch(url, {
        // by default always include cookies
        credentials: 'include',
        ...config,
      });
      let body;

      // 4xx || 5xx status, throw an error after parsing the body using default response interceptor.
      // There might be additional error info (as JSON) in the response.
      if (!res.ok) {
        body = await defaultInterceptors.response(res);
        throw new NetworkError(res, body, uri);
      }

      // 2xx

      if (params.as === 'blob') {
        // Parse body as Blob.
        body = await res.blob();
      } else {
        // Parse using interceptors.response
        body = await interceptResponse(res, defaultInterceptors.response);
      }

      // Special flag to return an object with response headers included.
      if (params.returnResHeaders) {
        return { body, headers: res.headers, status: res.status };
      }

      return body;
    } catch (e) {
      return interceptResponseError(e, { ...params, uri });
    }
  };

  const get = (url, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'GET',
          headers: {
            Accept: 'application/json, text/javascript, */*',
          },
        },
        params
      )
    );

  const post = (url, body, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        params
      )
    );

  const postWithFormData = (url, body, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'POST',
          body,
        },
        params
      )
    );

  const put = (url, body, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        params
      )
    );

  const patch = (url, body, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'PATCH',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        },
        params
      )
    );

  const remove = (url, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'DELETE',
        },
        params
      )
    );

  const head = (url, params = {}) =>
    api(
      url,
      merge(
        {
          method: 'HEAD',
          headers: {
            Accept: 'application/json, text/javascript, */*',
          },
        },
        params
      )
    );

  return {
    get: CACHE_ENABLED ? cached(get) : get,
    patch: CACHE_ENABLED ? cached(patch) : patch,
    post: CACHE_ENABLED ? cached(post) : post,
    postWithFormData: CACHE_ENABLED
      ? cached(postWithFormData)
      : postWithFormData,
    put: CACHE_ENABLED ? cached(put) : put,
    remove: CACHE_ENABLED ? cached(remove) : remove,
    head: CACHE_ENABLED ? cached(head) : head,
  };
}

function storeErrorDetails(headers, url) {
  ERROR_ARRAY.push({
    xTraceId: headers['x-trace-id'],
    date: headers.date,
    url,
  });
}

// Absolute URL to SIMON's API Gateway based on the current script that depends on this.
export const SIMON_API_URL = `${publicPathOrigin}/simon/api`;
export const SIMON_ICN_API_URL = `${publicPathOrigin}/icn/api`;
export const SIMON_KMS_API_URL = `${publicPathOrigin}/kms/api`;
export const ICN_API_URL = `${publicPathOrigin}/api`;
export const ICN_STAGING_API_URL = `https://s.staging.icapitalnetwork.com/api`;

export default createApi;
