import { useEffect } from 'react';
import { getTargetOrigin } from '@simon/ui/IFrameResizeDetector/postMessageOrigins';
import { isIFrame } from '@simon/config/env';

export const postMessage = msg => {
  if (!isIFrame) return;

  const targetOrigin = getTargetOrigin(document.referrer);
  if (!targetOrigin) return;

  window.parent.postMessage(msg, targetOrigin);
  console.log('postMessage: ', msg); // eslint-disable-line no-console
};

/**
 * Custom hook to handle incoming post messages. Sets up the event listener and handles
 * validation of the event origin before passing the data to the `onMessage` callback.
 * @param {Function} onMessage Callback that will receive the event data
 */
export function useMessageListener(onMessage) {
  useEffect(() => {
    function handleMessage(event) {
      if (!getTargetOrigin(event.origin || event.originalEvent.origin)) return;
      const eventData = event.data || event.originalEvent.data;
      onMessage(eventData);
    }

    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage, false);
  }, [onMessage]);
}
