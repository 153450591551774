import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import PageTitle from './common/PageTitle';
import Input2 from '@simon/ui/Input2';
import {
  Button,
  CircularProgress,
  TextField,
} from '@icapitalnetwork/supernova-core';
import Select from '@simon/ui/Select';
import Select2 from '@simon/ui/Select2';
import ErrorMessage from './common/ErrorMessage';
import MfaAlert from './common/MfaAlert';
import {
  EVENT,
  COUNTRY_CODES,
  TRANSACTION_STATUS,
  FACTOR_TYPE,
} from './constants';
import SelectOption from './common/SelectOption';
import MfaEnrollActivate from './MfaEnrollActivate';
import { formattedPhoneNumber, getFactorTitle } from './utils';
import styles from './MfaEnroll.module.scss';

export default MfaEnrollRouter;

// https://github.com/okta/okta-auth-js/blob/master/docs/authn.md#mfa_enroll
function MfaEnrollRouter() {
  const { transaction } = useAuth();

  if (
    transaction.status === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE &&
    (transaction.factor?.factorType === FACTOR_TYPE.SMS ||
      transaction.factor?.factorType === FACTOR_TYPE.CALL)
  ) {
    return <Navigate replace to=".." />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          transaction.status === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE ? (
            <Navigate replace to="activate" />
          ) : (
            <MfaEnroll />
          )
        }
      />
      <Route path="activate" element={<MfaEnrollActivate />} />
    </Routes>
  );
}

function MfaEnroll() {
  const navigate = useNavigate();
  const {
    loginSessionToken,
    onEvent,
    setTransaction,
    transaction,
    selectedFactorCtx,
  } = useAuth();

  const factors = useMemo(
    () => transaction.factors.filter(getFactorTitle),
    [transaction.factors]
  );

  const [selectedFactor, setSelectedFactor] = useState(
    () =>
      // automatically select the first required, not setup factor
      selectedFactorCtx ||
      factors.find(
        factor =>
          factor.status === 'NOT_SETUP' && factor.enrollment === 'REQUIRED'
      ) ||
      factors[0]
  );

  const onSuccess = useCallback(
    async transaction => {
      if (transaction.status === TRANSACTION_STATUS.SUCCESS) {
        onEvent(EVENT.MFAEnrollSuccess, {
          provider: selectedFactor.provider,
          factorType: selectedFactor.factorType,
        });

        // get tokens using the sessionToken
        return loginSessionToken(transaction.sessionToken);
      }

      if (transaction.status === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE) {
        if (
          transaction.factor?.factorType === FACTOR_TYPE.SMS ||
          transaction.factor?.factorType === FACTOR_TYPE.CALL
        ) {
          setTransaction(transaction);
          return navigate('..');
        }
      }

      if (transaction.status === TRANSACTION_STATUS.MFA_ENROLL) {
        // More required factors.
        // todo setTransaction()
        return setSelectedFactor(null);
      }

      // Unexpected status
      onEvent(EVENT.MFAEnrollError, {
        provider: selectedFactor.provider,
        factorType: selectedFactor.factorType,
      });
      throw `MFA factor ${selectedFactor.provider}:${selectedFactor.factorType} enroll(). We cannot handle the ${transaction.status} status.`;
    },
    [navigate, loginSessionToken, onEvent, selectedFactor]
  );

  const subtitle =
    transaction.status === TRANSACTION_STATUS.MFA_ENROLL ? (
      <React.Fragment>
        To verify your identity, we will send you a verification code to your
        phone each time you sign in.
      </React.Fragment>
    ) : (
      <React.Fragment>
        Multi-factor authentication set up for:
        <br />
        <strong>{transaction.user.profile.login}</strong>
      </React.Fragment>
    );

  return (
    <React.Fragment>
      <PageTitle title="Authentication Required" subtitle={subtitle} />
      {!selectedFactor &&
        factors.map(factor => (
          <SelectOption
            key={factor.provider + factor.factorType}
            title={<React.Fragment>{getFactorTitle(factor)}</React.Fragment>}
            onClick={() => setSelectedFactor(factor)}
          />
        ))}
      {selectedFactor?.provider === 'OKTA' &&
        selectedFactor?.factorType === FACTOR_TYPE.QUESTION && (
          <OktaQuestionsFactor factor={selectedFactor} onSuccess={onSuccess} />
        )}
      {selectedFactor?.provider === 'OKTA' &&
        selectedFactor?.factorType === FACTOR_TYPE.PUSH && (
          <OktaPushFactor factor={selectedFactor} onSuccess={onSuccess} />
        )}
      {selectedFactor?.provider === 'OKTA' &&
        (selectedFactor?.factorType === FACTOR_TYPE.SMS ||
          selectedFactor?.factorType === FACTOR_TYPE.CALL) && (
          <PhoneFactor factor={selectedFactor} onSuccess={onSuccess} />
        )}
    </React.Fragment>
  );
}

function OktaQuestionsFactor({ factor, onSuccess }) {
  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setFormState(s => ({ ...s, status: 'questions-fetching' }));
        const res = await factor.questions();
        setQuestions(res);
        setFormState(s => ({ ...s, status: 'questions-ok' }));
      } catch (e) {
        setFormState(s => ({ ...s, status: 'questions-error', error: e }));
      }
    })();
  }, [factor]);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        try {
          setFormState(s => ({ ...s, status: 'enroll-fetching' }));
          const transaction = await factor.enroll({
            profile: {
              question: question.value,
              answer,
            },
          });
          await onSuccess(transaction);
        } catch (e) {
          setFormState(s => ({ ...s, status: 'enroll-error', error: e }));
        }
      }}
    >
      <label htmlFor="answer" className={styles.securityQuestionLabel}>
        Security Question
      </label>
      <Select
        height={40}
        isLoading={formState.status === 'questions-fetching'}
        value={question}
        onChange={setQuestion}
        placeholder="Select question"
        options={questions.map(question => ({
          value: question.question,
          label: question.questionText,
        }))}
      />
      <TextField
        fullWidth
        hideClearButton
        sx={{ mt: 0.5 }}
        placeholder="Enter answer"
        value={answer}
        onChange={e => setAnswer(e.target.value)}
      />
      {formState.error && <ErrorMessage error={formState.error} />}
      <Button
        size="md"
        fullWidth
        type="submit"
        disabled={
          formState.status === 'enroll-fetching' || !question || !answer
        }
        sx={{ mt: 1 }}
        startIcon={
          formState.status === 'enroll-fetching' && (
            <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
          )
        }
      >
        Submit
      </Button>
    </form>
  );
}

function OktaPushFactor({ factor, onSuccess }) {
  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));

  async function enroll(e) {
    e?.preventDefault();
    try {
      setFormState(s => ({ ...s, status: 'enroll-fetching' }));
      const transaction = await factor.enroll();
      await onSuccess(transaction);
    } catch (e) {
      setFormState(s => ({ ...s, status: 'enroll-error', error: e }));
    }
  }

  // automatically enroll
  useEffect(() => {
    enroll();
  }, []);

  return (
    <form onSubmit={enroll}>
      <label htmlFor="answer" className={styles.securityQuestionLabel}>
        OKTA Verify
      </label>
      {formState.error && <ErrorMessage error={formState.error} />}
      <Button
        fullWidth
        size="md"
        type="submit"
        sx={{ mt: 1 }}
        disabled={formState.status === 'enroll-fetching'}
        startIcon={
          formState.status === 'enroll-fetching' && (
            <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
          )
        }
      >
        Submit
      </Button>
    </form>
  );
}

function PhoneFactor({ factor, onSuccess }) {
  const countryCodeOptions = COUNTRY_CODES.map(c => ({
    value: c.code,
    label: `${c.abbreviation} +${c.code}`,
  }));

  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState(countryCodeOptions[0]);

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        try {
          setFormState(s => ({ ...s, status: 'enroll-phone-fetching' }));
          const transaction = await factor.enroll({
            profile: {
              phoneNumber: formattedPhoneNumber(countryCode.value, phoneNumber),
              updatePhone: true,
            },
          });
          await onSuccess(transaction);
        } catch (e) {
          setFormState(s => ({
            ...s,
            status: 'enroll-phone-error',
            error:
              'Invalid Phone Number. Please enter a number only. Do not include any spaces or special characters.',
          }));
        }
      }}
    >
      {formState.error && <MfaAlert isError>{formState.error}</MfaAlert>}
      <label htmlFor="phone" className={styles.phoneNumberLabel}>
        Phone number
      </label>
      <Input2
        addonBefore={
          <Select2
            options={countryCodeOptions}
            value={countryCode}
            onChange={setCountryCode}
          />
        }
        autoFocus
        placeholder="123456789"
        value={phoneNumber}
        onChange={e => setPhoneNumber(e.target.value)}
        className={styles.phoneNumberInput}
      />
      <Button
        fullWidth
        size="md"
        type="submit"
        sx={{ mt: 1 }}
        disabled={formState.status === 'enroll-phone-fetching'}
        startIcon={
          formState.status === 'enroll-phone-fetching' && (
            <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
          )
        }
      >
        Continue
      </Button>
    </form>
  );
}
