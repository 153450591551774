import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useDropdownToggle } from 'react-overlays/DropdownToggle';
import Button from '@simon/ui/Button2';
import { Arrow } from '@simon/ui/Icon';
import styles from './DropdownToggle.module.scss';

const DropdownToggle = ({
  children,
  className,
  id,
  as: Component = Button,
  split = false,
  caret = true,
  ...props
}) => {
  const [toggleProps, { show }] = useDropdownToggle();

  return (
    <Component
      {...toggleProps}
      {...props}
      active={show}
      className={cn(
        styles.dropdownToggle,
        split && styles.split,
        props.variant && styles[props.variant],
        className
      )}
    >
      {children}
      {caret && (
        <Arrow
          width={12}
          height={12}
          className={cn(styles.caret, { [styles.flipCaret]: show })}
        />
      )}
    </Component>
  );
};

DropdownToggle.propTypes = {
  /**
   * An html id attribute, necessary for assistive technologies, such as screen readers.
   */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * Toggle dropdown caret visibility.
   */
  caret: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  /**
   * You can use a custom element type for this component.
   */
  as: PropTypes.elementType,
  /**
   * Enable split button dropdown.
   */
  split: PropTypes.bool,
};

export default DropdownToggle;
