import { sentryInit } from '@simon/core/sentry';
import * as Sentry from '@sentry/browser';

export function onSentryLocationChange(location) {
  Sentry.configureScope(scope => scope.setTag('Fragment', location.pathname));
}

export function initSentry() {
  sentryInit({
    dsn: 'https://730ba1a47ca448f3aa5aabf9e1c6cfa8@sentry.io/1475595',
  });
}

export function sentryIdentify({ maskedIds: { userId, networkId } }) {
  Sentry.setUser({ id: userId });
  if (networkId) Sentry.setTag('Network', networkId);
}
