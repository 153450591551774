import React, { memo, forwardRef, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';
import cn from 'classnames';

import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';
import { List } from 'react-virtualized';

import Spinner from '@simon/ui/Spinner';
import { EMPTY_OBJECT, WHITESPACE_REGEX } from '@simon/core/constants/globals';
import { DropdownIndicator } from './selectOverrides';
import styles from './Select.module.scss';

const LIST_WIDTH = 350;
const LIST_HEIGHT = 300;
const ROW_HEIGHT = 40;

export const generateSelectOption = ({ title, value, ...props }) => ({
  value: value || title,
  title: title || value,
  label: title || value,
  ...props,
});

export const MenuList =
  ({
    menuHeader,
    menuFooter,
    menuListHeight,
    menuRowHeight,
    noOptionsMessage = () => 'No Data',
    onScroll,
    scrollTop,
    scrollToIndex,
  }) =>
  props => {
    const itemsCount = props.children.length;

    const totalHeightOfRows = (itemsCount || 1) * (menuRowHeight || ROW_HEIGHT);
    return (
      <React.Fragment>
        <div style={{ width: '100%', zIndex: '999' }}>
          {menuHeader}
          <List
            scrollToIndex={scrollToIndex}
            onScroll={onScroll}
            scrollTop={scrollTop}
            style={{ width: '100%' }}
            width={LIST_WIDTH}
            defaultMenuIsOpen
            height={menuListHeight || Math.min(LIST_HEIGHT, totalHeightOfRows)}
            rowCount={itemsCount || 1}
            rowHeight={menuRowHeight || ROW_HEIGHT}
            className={styles.listContainer}
            rowRenderer={({ key, style, index }) => (
              <div
                key={key}
                style={style}
                title={itemsCount && props.children[index].props.data.title}
                className={cn({
                  [styles.selectOptionWrapper]: itemsCount,
                  [styles.selectNoMatch]: !itemsCount,
                })}
              >
                {itemsCount ? props.children[index] : noOptionsMessage()}
              </div>
            )}
          />
          {menuFooter}
        </div>
      </React.Fragment>
    );
  };

MenuList.propTypes = {
  children: PropTypes.any,
  options: PropTypes.array,
};

const LabeledValueContainer = (label, labelClass) => props =>
  (
    <React.Fragment>
      {label && <span className={cn(styles.label, labelClass)}>{label}</span>}
      <components.ValueContainer {...props} />
    </React.Fragment>
  );

const componentOverrides = (
  menuHeader,
  menuFooter,
  label,
  labelClass,
  menuListHeight,
  menuRowHeight,
  useNonVirtualizedMenu,
  darkMode,
  additionalComponentOverrides,
  isRFQ,
  noOptionsMessage
) => ({
  ...(useNonVirtualizedMenu
    ? {}
    : {
        MenuList: MenuList({
          menuHeader,
          menuFooter,
          menuListHeight,
          menuRowHeight,
          noOptionsMessage,
        }),
      }),
  ValueContainer: LabeledValueContainer(label, labelClass),
  DropdownIndicator: props => (
    <DropdownIndicator darkMode={darkMode} isRFQ={isRFQ} {...props} />
  ),
  LoadingIndicator: () => (
    <span style={{ height: 18 }}>
      <Spinner size={18} />
    </span>
  ),
  Option: props => (
    <components.Option
      {...props}
      innerProps={{
        ...props.innerProps,
        // remove laggy mouse event handlers
        onMouseMove: Function.prototype,
        onMouseOver: Function.prototype,
        onMouseEnter: props.selectProps.onMouseEnter || Function.prototype,
        onMouseLeave: props.selectProps.onMouseLeave || Function.prototype,
        'data-id': props.data.title,
      }}
      className={styles.option}
    />
  ),
  ...additionalComponentOverrides,
});

//  https://react-select.com/styles#provided-styles-and-state
const styleOverrides = defaultMemoize(
  (width, height, secondary, darkMode, stylesProp) => ({
    indicatorSeparator: styles => ({ ...styles, visibility: 'hidden' }),
    dropdownIndicator: styles => ({ ...styles, marginRight: 5 }),
    clearIndicator: styles => ({ ...styles, padding: 5 }),
    menu: styles => ({ ...styles, zIndex: 11 }),
    placeholder: styles => ({
      ...styles,
      ...(darkMode && {
        color: '#fff',
      }),
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      cursor: 'pointer',
      ...(height ? { height, minHeight: 'auto' } : { minHeight: 32 }),
      ...(secondary && {
        minHeight: 'auto',
        border: 'none',
        boxShadow: 'none',
        backgroundColor: 'none',
        ...(isFocused && { backgroundColor: '#f2f2f2' }),
        '&:hover': { backgroundColor: '#f2f2f2' },
      }),
      ...(darkMode && {
        boxShadow: 'none',
        color: '#fff',
        backgroundColor: 'rgba(255,255,255,0.1)',
        borderColor: isFocused ? '#2684ff' : 'rgba(255,255,255,0.35)',
        '&:hover': {
          borderColor: isFocused ? '#2684ff' : 'rgba(255,255,255,0.55)',
        },
      }),
    }),
    ...(Boolean(width) && {
      container: styles => ({
        ...styles,
        width,
      }),
    }),
    singleValue: styles => ({
      ...styles,
      ...(darkMode && {
        color: '#fff',
      }),
    }),
    input: (styles, { isDisabled }) => ({
      ...styles,
      padding: '0',
      width: '100%',
      // the input gets hidden on blur (probably a react-select bug?)
      // that causes an issue when `inputValue` is controlled (and retained after blur) - looks like it's empty
      '& input': { opacity: '1 !important' },
      ...(isDisabled && {
        visibility: 'visible',
        color: '#9c9c9b',
      }),
      ...(darkMode && {
        color: '#fff',
      }),
    }),
    option: (styles, { isSelected }) => ({
      ...styles,
      //  rest of css styles are in select.module.scss for perf reasons, please add additional styles there
      ...(isSelected && {
        backgroundColor: '#4a90e2 !important',
        color: 'white !important',
      }),
    }),
    // menuPortal's z-index is changed in order to properly
    // display the custom select in the modals
    // the z-index of the modals is 9000050 due to the Olark chat button
    menuPortal: styles => ({ ...styles, zIndex: 9000051 }),
    ...stylesProp,
  })
);

export const SelectWithSelectAllOption = ({ allOption, options, ...props }) => (
  <CustomSelect
    {...props}
    options={[allOption, ...options]}
    onChange={(selected, event) => {
      if (selected[selected.length - 1]?.value === allOption.value) {
        return props.onChange([allOption, ...options]);
      }
      if (selected?.length <= 0 || selected.length !== options.length) {
        return props.onChange(selected);
      }

      let result = [];

      if (selected.some(({ value }) => value === allOption.value)) {
        result = selected.filter(option => option.value !== allOption.value);
      } else if (event.action === 'select-option') {
        result = [allOption, ...options];
      }
      return props.onChange(result);
    }}
  />
);

SelectWithSelectAllOption.defaultProps = {
  allOption: { label: 'Select All', value: 'Select All' },
};

const CustomSelect = memo(
  forwardRef(
    (
      {
        width,
        height,
        menuHeader,
        menuFooter,
        menuListHeight,
        menuRowHeight,
        useNonVirtualizedMenu,
        label,
        labelClass,
        secondary,
        maxOptions,
        options = [],
        lazy,
        filterOption,
        creatable,
        toolTip,
        isTooltipOpen,
        darkMode,
        componentOverrides: additionalComponentOverrides = EMPTY_OBJECT,
        styles = {},
        isRFQ,
        isLoading,
        isRFQTemplate,
        ...props
      },
      ref
    ) => {
      const [showLoadingState, setShowLoadingState] = useState(false);

      const SelectComponent = lazy
        ? AsyncSelect
        : creatable
        ? CreatableSelect
        : Select;

      const lazyProps = React.useMemo(
        () =>
          lazy
            ? {
                defaultOptions: options.slice(0, maxOptions),
                loadOptions: (inputValue, cb) =>
                  setTimeout(() => {
                    cb(
                      (inputValue
                        ? options.filter(o => filterOption(o, inputValue))
                        : options
                      ).slice(0, maxOptions)
                    );
                  }, 150),
              }
            : {
                options,
                filterOption,
              },
        [filterOption, lazy, maxOptions, options]
      );

      return (
        <React.Fragment>
          <SelectComponent
            ref={ref}
            components={useMemo(
              () =>
                componentOverrides(
                  menuHeader,
                  menuFooter,
                  label,
                  labelClass,
                  menuListHeight,
                  menuRowHeight,
                  useNonVirtualizedMenu,
                  darkMode,
                  additionalComponentOverrides,
                  isRFQ,
                  props.noOptionsMessage
                ),
              [
                label,
                labelClass,
                menuFooter,
                menuHeader,
                menuListHeight,
                menuRowHeight,
                useNonVirtualizedMenu,
                darkMode,
                additionalComponentOverrides,
                isRFQ,
                props.noOptionsMessage,
              ]
            )}
            onMenuOpen={() => setShowLoadingState(true)}
            onMenuClose={() => setShowLoadingState(false)}
            styles={styleOverrides(
              width,
              height,
              secondary,
              darkMode,
              styles,
              isRFQ
            )}
            isLoading={isRFQ && isLoading && showLoadingState}
            menuPortalTarget={isRFQTemplate ? document.body : undefined} // Needed to lift the menu above the container
            menuPosition={isRFQTemplate ? 'absolute' : undefined} // Needed to lift the menu above the container
            {...lazyProps}
            {...props}
          />
          {isTooltipOpen && toolTip}
        </React.Fragment>
      );
    }
  )
);

CustomSelect.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array, // when using a multi select
    PropTypes.string,
  ]),
  isClearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  secondary: PropTypes.bool,
  lazy: PropTypes.bool,
  maxOptions: PropTypes.number,
  filterOption: PropTypes.func,
  // Useful for displaying multiline options
  useNonVirtualizedMenu: PropTypes.bool,
  darkMode: PropTypes.bool,
  components: PropTypes.object,
  styles: PropTypes.object,
};

CustomSelect.defaultProps = {
  onChange: Function.prototype,
  isSearchable: true,
  maxOptions: 100,
  filterOption: (option, input) =>
    option.label
      .replace(WHITESPACE_REGEX, '')
      .toLowerCase()
      .includes(input.replace(WHITESPACE_REGEX, '').toLowerCase()),
};

CustomSelect.displayName = 'Select';

export default CustomSelect;
