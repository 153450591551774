import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styles from './DropdownHeader.module.scss';

const DropdownHeader = forwardRef(
  ({ as: Component = 'div', ...props }, ref) => (
    <Component ref={ref} className={styles.dropdownHeader} {...props} />
  )
);

DropdownHeader.propTypes = {
  /** You can use a custom element type for this component. */
  as: PropTypes.elementType,
};

export default DropdownHeader;
