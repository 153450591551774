import { Link } from 'react-router-dom';
import { isIFrame } from '@simon/config/env';

const isTrivialHref = href =>
  !href || (typeof href === 'string' && href.trim() === '#');

/**
 * Most of the heavy lifting in Button comes from the useButtonProps hook.
 * You can use the hook directly to turn any component into an accessible button.
 *
 * If an explicit tagName isn't provided, the hook will pick between 'button'
 * and 'a' depending on whether any anchor specific options, like href, were provided.
 */
export default function useButtonProps({
  tagName,
  disabled,
  href,
  to,
  target,
  rel,
  onClick,
  tabIndex = 0,
  type,
}) {
  if (!tagName) {
    if (to != null) {
      tagName = Link;
    } else if (href != null || target != null || rel != null) {
      tagName = 'a';
    } else {
      tagName = 'button';
    }
  }

  const meta = { tagName };
  if (tagName === 'button') {
    return [{ type: type || 'button', disabled }, meta];
  }

  const handleClick = event => {
    if (
      disabled ||
      (tagName === 'a' && isTrivialHref(href)) ||
      (tagName === Link && isTrivialHref(to))
    ) {
      event.preventDefault();
    }

    if (disabled) {
      event.stopPropagation();
      return;
    }

    onClick?.(event);
  };

  const handleKeyDown = event => {
    if (event.key === ' ') {
      event.preventDefault();
      handleClick(event);
    }
  };

  if (tagName === 'a') {
    // Ensure there's a href so Enter can trigger anchor button.
    href || (href = '#');
    if (disabled) {
      href = undefined;
    }
  }

  return [
    {
      role: 'button',
      // explicitly undefined so that it overrides the props disabled in a spread
      // e.g. <Tag {...props} {...hookProps} />
      disabled: undefined,
      tabIndex: disabled ? undefined : tabIndex,
      href,
      target: tagName === 'a' ? (isIFrame ? '_self' : target) : undefined,
      'aria-disabled': !disabled ? undefined : disabled,
      rel: tagName === 'a' ? rel : undefined,
      onClick: handleClick,
      onKeyDown: handleKeyDown,
    },
    meta,
  ];
}
