import { useEffect, useRef } from 'react';

// function Counter() {
//   const [count, setCount] = useState(0);
//   const prevCount = usePrevious(count)

//   return <h1> Now: {count}, before: {prevCount} </h1>;
// }
/**  Hook that allows access to a previous rendered value. */
function usePrevious(value, initialValue) {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default usePrevious;
