import React, { forwardRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ReactSelect from 'react-select';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import useComponents from './useComponents';
import colors from '@simon/core/styles/colors.module.scss';
import inputStyles from '@simon/ui/Input2/Input.module.scss';
import styles from './Select.module.scss';

const Select = forwardRef(
  (
    {
      className,
      closeMenuOnSelect,
      error,
      helperText,
      hideSelectedOptions = false, // hide selected options from the menu dropdown
      id,
      isMulti = false,
      isRequired,
      isSearchable = false,
      label,
      labelClassName,
      maxShownValues = 2,
      styles: stylesProp,
      valueLabel,
      valueLabelClassName,
      components,
      ...props
    },
    ref
  ) => {
    const customComponents = useComponents({
      isMulti,
      maxShownValues,
      error,
      valueLabel,
      valueLabelClassName,
    });

    return (
      <Fragment>
        {label && (
          <label htmlFor={id} className={cn(inputStyles.label, labelClassName)}>
            {label}
            {isRequired && <span className={inputStyles.required}>*</span>}
          </label>
        )}
        <ReactSelect
          {...props}
          ref={ref}
          inputId={id}
          className={cn(styles.select, className)}
          isMulti={isMulti}
          isOptionDisabled={option => option.disabled}
          isSearchable={isSearchable}
          closeMenuOnSelect={closeMenuOnSelect || !isMulti}
          hideSelectedOptions={hideSelectedOptions}
          components={{ ...customComponents, ...components }}
          styles={{
            control: (provided, { isFocused }) => ({
              ...provided,
              ...(isFocused && {
                boxShadow: `0 0 0 3px ${error ? colors.red20 : colors.blue20}`,
                zIndex: 2,
              }),
              ...(error && {
                borderColor: colors.red60,
                '&:hover': {
                  borderColor: colors.red60,
                },
              }),
              minHeight: '40px',
              transition: 'all 200ms cubic-bezier(0.2, 0.8, 0.4, 1)',
              ...stylesProp?.control,
            }),
            menu: provided => ({
              ...provided,
              boxShadow:
                '0 0 1px rgba(51, 58, 83, 0.3), 0 4px 8px rgba(51, 58, 83, 0.25)',
              zIndex: 2,
              ...stylesProp?.menu,
            }),
            multiValue: provided => ({
              ...provided,
              boxShadow: `0 0 0 1px ${colors.gray15}`,
              ...stylesProp?.multiValue,
            }),
            multiValueLabel: provided => ({
              ...provided,
              padding: `2px`,
              paddingRight: '6px',
              fontSize: '14px',
              lineHeight: '20px',
              ...stylesProp?.multiValueLabel,
            }),
            multiValueRemove: (provided, { isFocused }) => ({
              ...provided,
              paddingLeft: '5px',
              paddingRight: '5px',
              color: colors.gray90,
              ...(isFocused && {
                backgroundColor: colors.red30,
                boxShadow: `0 0 0 1px ${colors.red30}`,
              }),
              '&:hover': {
                color: colors.red60,
                backgroundColor: colors.red30,
                boxShadow: `0 0 0 1px ${colors.red30}`,
              },
              '&:active': {
                color: colors.red70,
                backgroundColor: colors.red40,
                boxShadow: `0 0 0 1px ${colors.red40}`,
              },
              ...stylesProp?.multiValueRemove,
            }),
            option: (provided, { isSelected, isFocused, isDisabled }) => ({
              ...provided,
              color: isDisabled ? colors.gray30 : colors.gray90,
              '&:hover': {
                backgroundColor: isSelected ? colors.blue20 : colors.gray10,
              },
              '&:active': {
                backgroundColor: isSelected ? colors.blue20 : colors.gray13,
              },
              ...(isSelected && {
                backgroundColor: colors.blue10,
              }),
              ...(isFocused && {
                backgroundColor: isSelected ? colors.blue20 : colors.gray10,
              }),
              ...stylesProp?.option,
            }),
          }}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: colors.blue50,
              primary25: colors.blue20,
              primary75: colors.blue30,
              neutral10: colors.gray10,
              neutral20: colors.gray15,
              neutral50: colors.gray50,
              neutral80: colors.gray90,
              danger: colors.red60,
            },
          })}
        />
        {(isString(error) || isObject(error) || helperText) && (
          <div
            className={cn(inputStyles.helperText, {
              [inputStyles.error]: Boolean(error),
            })}
          >
            {error || helperText}
          </div>
        )}
      </Fragment>
    );
  }
);

Select.propTypes = {
  /** Maximum number of options to display when `isMulti` is set to `true`. */
  maxShownValues: PropTypes.number,
  /** A label rendered above the input field. */
  label: PropTypes.string,
  /** A caption rendered below the input field. */
  helperText: PropTypes.string,
  /** If an error prop passed it will be rendered in place of caption as an error message. */
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  /** Set required for form that the field is part of. */
  isRequired: PropTypes.bool,
  /** HTML ID of an element containing an error message related to the input */
  'aria-errormessage': PropTypes.string,
  /** Indicate if the value entered inside the field is invalid */
  'aria-invalid': PropTypes.string,
  /** Aria label (for assistive tech) */
  'aria-label': PropTypes.string,
  /** HTML ID of an element that should be used as the label (for assistive tech) */
  'aria-labelledby': PropTypes.string,
  /** Used to set the priority with which screen reader should treat updates to live regions. The possible settings are: off, polite (default) or assertive */
  'aria-live': PropTypes.oneOf(['polite', 'off', 'assertive']),
  /** Customize the messages used by the aria-live component */
  ariaLiveMessages: PropTypes.object,
  /** Focus the control when it is mounted */
  autoFocus: PropTypes.bool,
  /** Remove the currently focused option when the user presses backspace when Select isClearable or isMulti */
  backspaceRemovesValue: PropTypes.bool,
  /** Remove focus from the input when the user selects an option (handy for dismissing the keyboard on touch devices) */
  blurInputOnSelect: PropTypes.bool,
  /** When the user reaches the top/bottom of the menu, prevent scroll on the scroll-parent  */
  captureMenuScroll: PropTypes.bool,
  /** Sets a className attribute on the outer component */
  className: PropTypes.string,
  /**
   * If provided, all inner components will be given a prefixed className attribute.
   *
   * This is useful when styling via CSS classes instead of the Styles API approach.
   */
  classNamePrefix: PropTypes.string,
  /** Close the select menu when the user selects an option */
  closeMenuOnSelect: PropTypes.bool,
  /**
   * If `true`, close the select menu when the user scrolls the document/body.
   *
   * If a function, takes a standard javascript `ScrollEvent` you return a boolean:
   *
   * `true` => The menu closes
   *
   * `false` => The menu stays open
   *
   * This is useful when you have a scrollable modal and want to portal the menu out,
   * but want to avoid graphical issues.
   */
  closeMenuOnScroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  /**
   * This complex object includes all the compositional components that are used
   * in `react-select`. If you wish to overwrite a component, pass in an object
   * with the appropriate namespace.
   *
   * If you only wish to restyle a component, we recommend using the `styles` prop
   * instead. For a list of the components that can be passed in, and the shape
   * that will be passed to them, see [the components docs](/components)
   */
  components: PropTypes.object,
  /** Whether the value of the select, e.g. SingleValue, should be displayed in the control. */
  controlShouldRenderValue: PropTypes.bool,
  /** Delimiter used to join multiple values into a single HTML Input value */
  delimiter: PropTypes.string,
  /** Clear all values when the user presses escape AND the menu is closed */
  escapeClearsValue: PropTypes.bool,
  /** Custom method to filter whether an option should be displayed in the menu */
  filterOption: PropTypes.func,
  /**
   * Formats group labels in the menu as React components
   *
   * An example can be found in the [Replacing builtins](/advanced#replacing-builtins) documentation.
   */
  formatGroupLabel: PropTypes.func,
  /** Formats option labels in the menu and control as React components */
  formatOptionLabel: PropTypes.func,
  /**
   * Resolves option data to a string to be displayed as the label by components
   *
   * Note: Failure to resolve to a string type can interfere with filtering and
   * screen reader support.
   */
  getOptionLabel: PropTypes.func,
  /** Resolves option data to a string to compare options and specify value attributes */
  getOptionValue: PropTypes.func,
  /** Hide the selected option from the menu */
  hideSelectedOptions: PropTypes.bool,
  /** The id to set on the SelectContainer component. */
  id: PropTypes.string,
  /** The value of the search input */
  inputValue: PropTypes.string,
  /** The id of the search input */
  inputId: PropTypes.string,
  /** Define an id prefix for the select components e.g. {your-id}-value */
  instanceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /** Is the select value clearable */
  isClearable: PropTypes.bool,
  /** Is the select disabled */
  isDisabled: PropTypes.bool,
  /** Is the select in a state of loading (async) */
  isLoading: PropTypes.bool,
  /**
   * Override the built-in logic to detect whether an option is disabled
   *
   * An example can be found in the [Replacing builtins](/advanced#replacing-builtins) documentation.
   */
  isOptionDisabled: PropTypes.func,
  /** Override the built-in logic to detect whether an option is selected */
  isOptionSelected: PropTypes.func,
  /** Support multiple selected options */
  isMulti: PropTypes.bool,
  /** Is the select direction right-to-left */
  isRtl: PropTypes.bool,
  /** Whether to enable search functionality */
  isSearchable: PropTypes.bool,
  /** Async: Text to display when loading options */
  loadingMessage: PropTypes.func,
  /** Minimum height of the menu before flipping */
  minMenuHeight: PropTypes.number,
  /** Maximum height of the menu before scrolling */
  maxMenuHeight: PropTypes.number,
  /** Whether the menu is open */
  menuIsOpen: PropTypes.bool,
  /**
   * Default placement of the menu in relation to the control. 'auto' will flip
   * when there isn't enough space below the control.
   */
  menuPlacement: PropTypes.oneOf(['auto', 'bottom', 'top']),
  /** The CSS position value of the menu, when "fixed" extra layout management is required */
  menuPosition: PropTypes.oneOf(['absolute', 'fixed']),
  /**
   * Whether the menu should use a portal, and where it should attach
   *
   * An example can be found in the [Portaling](/advanced#portaling) documentation
   */
  menuPortalTarget: PropTypes.node,
  /** Whether to block scroll events when the menu is open */
  menuShouldBlockScroll: PropTypes.bool,
  /** Whether the menu should be scrolled into view when it opens */
  menuShouldScrollIntoView: PropTypes.bool,
  /** Name of the HTML Input (optional - without this, no input will be rendered) */
  name: PropTypes.string,
  /** Text to display when there are no options */
  noOptionsMessage: PropTypes.func,
  /** Handle blur events on the control */
  onBlur: PropTypes.func,
  /** Handle change events on the select */
  onChange: PropTypes.func,
  /** Handle focus events on the control */
  onFocus: PropTypes.func,
  /** Handle change events on the input */
  onInputChange: PropTypes.func,
  /** Handle key down events on the select */
  onKeyDown: PropTypes.func,
  /** Handle the menu opening */
  onMenuOpen: PropTypes.func,
  /** Handle the menu closing */
  onMenuClose: PropTypes.func,
  /** Fired when the user scrolls to the top of the menu */
  onMenuScrollToTop: PropTypes.func,
  /** Fired when the user scrolls to the bottom of the menu */
  onMenuScrollToBottom: PropTypes.func,
  /** Allows control of whether the menu is opened when the Select is focused */
  openMenuOnFocus: PropTypes.bool,
  /** Allows control of whether the menu is opened when the Select is clicked */
  openMenuOnClick: PropTypes.bool,
  /** Array of options that populate the select menu */
  options: PropTypes.array,
  /** Number of options to jump in menu when page{up|down} keys are used */
  pageSize: PropTypes.number,
  /** Placeholder for the select value */
  placeholder: PropTypes.node,
  /** Status to relay to screen readers */
  screenReaderStatus: PropTypes.func,
  /**
   * Style modifier methods
   *
   * A basic example can be found at the bottom of the [Replacing builtins](/advanced#replacing-builtins) documentation.
   */
  styles: PropTypes.object,
  /** Theme modifier method */
  theme: PropTypes.object,
  /** Sets the tabIndex attribute on the input */
  tabIndex: PropTypes.number,
  /** Select the currently focused option when the user presses tab */
  tabSelectsValue: PropTypes.bool,
  /** The value of the select; reflected by the selected option */
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  /** Sets the form attribute on the input */
  form: PropTypes.string,
};

export default Select;
