import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import useAuth from './useAuth';
import { AVAILABLE_ENV } from '@simon/config/env';
import React, { useEffect, useState } from 'react';
import PageTitle from './common/PageTitle';
import { CheckMarkCircle, Info } from '@simon/ui/Icon';
import {
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
} from '@icapitalnetwork/supernova-core';
import Tooltip from '@icapitalnetwork/supernova-core/Tooltip';
import Spinner from '@simon/ui/Spinner';
import ErrorMessage from './common/ErrorMessage';
import Alert from '@simon/ui/Alert';
import { PLACEMENT } from '@simon/core/constants/globals';
import { EVENT } from './constants';
import styles from './NewPassword.module.scss';
import PersonIcon from '@mui/icons-material/Person';
import { ClickAwayListener } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

export default function NewPassword() {
  const navigate = useNavigate();
  const { authClient, resumedTransaction, onEvent, loginSessionToken, env } =
    useAuth();
  const { recoveryToken } = useParams();
  const [formState, setFormState] = useState({
    // Transaction resumed with a verified token
    status: resumedTransaction ? 'verify-ok' : 'pending',
    transaction: resumedTransaction,
    error: null,
  });
  const [openTooltip, setOpenTooltip] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [email, setEmail] = useState('');
  const [answer, setAnswer] = useState('');

  const handleTooltipClose = () => {
    setOpenTooltip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTooltip(true);
  };

  // Verify recovery token
  useEffect(() => {
    if (formState.status !== 'pending' || !recoveryToken) return;

    (async () => {
      setFormState({ status: 'verify-fetching' });
      try {
        const transaction = await authClient.verifyRecoveryToken({
          recoveryToken,
        });
        if (transaction.status === 'RECOVERY') {
          onEvent(EVENT.RecoveryTokenVerifySuccess);
          setFormState({ status: 'verify-ok', transaction, error: null });
          return;
        }
        throw `Okta verifyRecoveryToken(). We cannot handle the ${transaction.status} status.`;
      } catch (e) {
        onEvent(EVENT.RecoveryTokenVerifyError, { error: e });
        setFormState({ status: 'verify-error', error: e });
      }
    })();
  }, [authClient, formState.status, onEvent, recoveryToken]);

  // Verification error. Form to request a new one.
  if (formState.status === 'verify-error') {
    return (
      <React.Fragment>
        <PageTitle title="Reset Password" />
        <Alert
          showIcon
          icon={<Info width={14} height={14} color="#f5222d" />}
          message="Your password reset link has been expired."
          type="error"
        />
        <form
          onSubmit={e => {
            e.preventDefault();
            navigate('/', {
              search: `?email=${email}`,
              replace: true,
            });
          }}
        >
          <p className={styles.resendInstructions}>
            Please enter your account&apos;s email below to receive a password
            reset link.
          </p>
          <TextField
            autoFocus
            fullWidth
            hideClearButton
            type="email"
            autoComplete="email"
            placeholder="Email"
            name="email"
            value={email}
            onChange={e => {
              setEmail(e.target.value);
            }}
            required
            aria-label="Email"
            InputProps={{
              startAdornment: (
                // @ts-ignore
                <InputAdornment position="start">
                  <PersonIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <Button
            size="md"
            fullWidth
            type="submit"
            sx={{ mt: 1 }}
            disabled={!email}
          >
            Send Email
          </Button>
        </form>
        <Button
          variant="text"
          size="md"
          fullWidth
          sx={{ mt: 0.5 }}
          component={Link}
          to=".."
        >
          Back to Sign-in
        </Button>
      </React.Fragment>
    );
  }

  // Resuming transaction failed.
  if (
    resumedTransaction &&
    !['RECOVERY', 'PASSWORD_RESET'].includes(resumedTransaction.status)
  ) {
    return <Navigate replace to=".." />;
  }

  // Verifying recovery token.
  if (
    formState.status === 'pending' ||
    formState.status === 'verify-fetching'
  ) {
    return (
      <div>
        <PageTitle />
        <Spinner isMainLoader />
      </div>
    );
  }

  const {
    user: { profile, recovery_question: { question } = {} },
  } = formState.transaction;

  // Verification ok. Answer security question.
  if (formState.transaction.status === 'RECOVERY') {
    return (
      <React.Fragment>
        <PageTitle
          title="Reset Password"
          subtitle={
            <React.Fragment>
              Please answer the following security question.
              <br />
              <strong>{profile.login}</strong>
            </React.Fragment>
          }
        />
        <form
          onSubmit={async e => {
            e.preventDefault();
            setFormState(s => ({ ...s, status: 'answer-fetching' }));
            try {
              const transaction = await formState.transaction.answer({
                answer,
              });
              if (transaction.status === 'PASSWORD_RESET') {
                onEvent(EVENT.SecurityAnswerSuccess);
                setFormState({ status: 'answer-ok', transaction, error: null });
                return;
              }
              throw `Okta transaction.answer(). We cannot handle the ${transaction.status} status.`;
            } catch (e) {
              onEvent(EVENT.SecurityAnswerError);
              setFormState(s => ({
                ...s,
                status: 'answer-error',
                error: 'The answer did not match our records.',
              }));
            }
          }}
        >
          <label htmlFor="answer" className={styles.securityQuestionLabel}>
            <strong>Security Question</strong>
            <br />
            <span>{question}</span>
          </label>
          <TextField
            autoFocus
            fullWidth
            hideClearButton
            autoComplete="false"
            placeholder="Security Answer"
            name="answer"
            value={answer}
            onChange={e => {
              setAnswer(e.target.value);
            }}
            required
            aria-label="Security Answer"
          />
          {formState.error && <ErrorMessage error={formState.error} />}
          <Button
            fullWidth
            size="md"
            sx={{ mt: 1 }}
            type="submit"
            disabled={formState.status === 'answer-fetching' || !answer}
            startIcon={
              formState.status === 'answer-fetching' && (
                <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
              )
            }
          >
            Next
          </Button>
        </form>
        <Button
          variant="text"
          size="md"
          fullWidth
          sx={{ mt: 0.5 }}
          component={Link}
          to=".."
        >
          Back to Sign-in
        </Button>
      </React.Fragment>
    );
  }

  // Security answer ok. Enter new password.
  if (formState.transaction.status === 'PASSWORD_RESET') {
    return (
      <React.Fragment>
        <PageTitle
          title="Reset Password"
          subtitle={
            <React.Fragment>
              Enter a new password for your account.
              <br />
              <strong>{profile.login}</strong>
            </React.Fragment>
          }
        />
        <form
          onSubmit={async e => {
            e.preventDefault();
            setFormState(s => ({ ...s, status: 'change-fetching' }));
            try {
              const transaction = await formState.transaction.resetPassword({
                newPassword,
              });
              if (transaction.status === 'SUCCESS') {
                // get tokens using the sessionToken
                await loginSessionToken(transaction.sessionToken);
                onEvent(EVENT.ResetPasswordChangeSuccess);
                setFormState(s => ({ ...s, status: 'change-ok', transaction }));
                return;
              }
              throw `Okta transaction.resetPassword(). We cannot handle the ${transaction.status} status.`;
            } catch (e) {
              onEvent(EVENT.ResetPasswordChangeError, { error: e });
              setFormState(s => ({
                ...s,
                status: 'change-error',
                error: 'Password requirements were not met.',
              }));
            }
          }}
        >
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                title={
                  <div className={styles.tooltipContentWrapper}>
                    <span className={styles.tooltipTitle}>
                      {' '}
                      New password must:
                    </span>
                    <ul>
                      <li>
                        be at least {env === AVAILABLE_ENV.PROD ? '12' : '8'}{' '}
                        characters
                      </li>
                      <li>contain at least 1 lowercase</li>
                      <li>contain at least 1 uppercase</li>
                      <li>contain at least 1 number</li>
                      <li>not contain parts of your username</li>
                      <li>not contain you first/last name</li>
                      <li>not be any of the last 8 passwords</li>
                    </ul>
                  </div>
                }
                placement={PLACEMENT.RIGHT}
                open={openTooltip}
                variant="info"
                className={styles.passwordRequirementsTooltip}
              >
                <TextField
                  onClick={handleTooltipOpen}
                  autoFocus
                  fullWidth
                  hideClearButton
                  type="password"
                  autoComplete="new-password"
                  placeholder="New Password"
                  name="new-password"
                  value={newPassword}
                  onChange={e => {
                    setNewPassword(e.target.value);
                  }}
                  required
                  aria-label="New Password"
                  InputProps={{
                    startAdornment: (
                      // @ts-ignore
                      <InputAdornment position="start">
                        <LockIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Tooltip>
            </div>
          </ClickAwayListener>
          <TextField
            fullWidth
            hideClearButton
            type="password"
            autoComplete="new-password"
            placeholder="Confirm New Password"
            name="confirm-new-password"
            value={confirmNewPassword}
            onChange={e => {
              setConfirmNewPassword(e.target.value);
            }}
            required
            aria-label="Confirm New Password"
            sx={{ mt: 0.5 }}
            InputProps={{
              startAdornment: (
                // @ts-ignore
                <InputAdornment position="start">
                  <LockIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          {formState.error && <ErrorMessage error={formState.error} />}
          <Button
            fullWidth
            size="md"
            type="submit"
            sx={{ mt: 1 }}
            disabled={
              formState.status === 'change-fetching' ||
              !newPassword ||
              !confirmNewPassword ||
              newPassword !== confirmNewPassword
            }
            startIcon={
              formState.status === 'change-fetching' && (
                <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
              )
            }
          >
            Reset Password
          </Button>
        </form>
        <Button
          variant="text"
          size="md"
          fullWidth
          sx={{ mt: 0.5 }}
          component={Link}
          to=".."
        >
          Back to Sign-in
        </Button>
      </React.Fragment>
    );
  }

  // Password changed successfully
  return (
    <React.Fragment>
      <PageTitle
        title={
          <React.Fragment>
            <CheckMarkCircle width={12} height={12} color="#24a148ƒ" /> Success
          </React.Fragment>
        }
        subtitle={
          <React.Fragment>
            Your password has been changed successfully.
            <br />
            <strong>{profile.login}</strong>
          </React.Fragment>
        }
      />
      <Button size="md" fullWidth component={Link} to="/">
        Okay
      </Button>
    </React.Fragment>
  );
}
