import React, { ReactNode } from 'react';
import MascotAnimated from '@simon/core/images/mascots/simon-mascot-small.svg?inline';
import cn from 'classnames';
import Button from '@icapitalnetwork/supernova-core/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './PageTitle.module.scss';

export default function PageTitle({
  title,
  subtitle,
  small,
  className,
  onGoBack,
}: {
  title?: ReactNode;
  subtitle?: string;
  small?: boolean;
  className?: string;
  onGoBack?: () => void;
}) {
  return (
    <div className={cn(styles.wrapper, className)}>
      {typeof onGoBack === 'function' && (
        // @ts-ignore
        <Button
          variant="text"
          color="text"
          size="md"
          onClick={onGoBack}
          className={styles.backArrowWrapper}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
      )}
      <img src={MascotAnimated} width={104} height={91} alt="Mascot" />
      {title && (
        <h2 className={cn(styles.title, { [styles.sm]: small })}>{title}</h2>
      )}
      {subtitle && <h3 className={styles.subtitle}>{subtitle}</h3>}
    </div>
  );
}
