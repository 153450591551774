import React from 'react';
import { Info } from '@simon/ui/Icon';
import styles from './ErrorMessage.module.scss';

export default function ErrorMessage({ error }) {
  return (
    <p className={styles.submitError}>
      <Info width={12} height={12} /> {error.message || error.name || error}
    </p>
  );
}
