// Test if a WebStorage interface is available.
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
import { isBrowser } from '@simon/config/env';

export default function storageAvailable(type) {
  if (!isBrowser) return false;
  let storage;
  try {
    storage = window[type];
    const x = '__storage_test__';
    storage.setItem(x, x);
    if (storage.getItem(x) !== x) {
      return false;
    }
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
}
