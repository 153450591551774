import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Spinner.module.scss';

/**
 * Spinners provide a visual cue that an action is processing awaiting a course of change or a result.
 */
const Spinner = ({
  color,
  size,
  radius,
  isMainLoader,
  className,
  containerClassName,
}) => (
  <div className={cn({ [styles.centered]: isMainLoader }, containerClassName)}>
    <div
      className={cn(styles.spinner, className)}
      style={{
        width: size,
        height: size,
        borderTopColor: color,
        borderWidth: Math.floor(size / radius),
      }}
    />
  </div>
);

Spinner.defaultProps = {
  color: '#4a90e2',
  size: 38,
  radius: 7.5,
  isMainLoader: false,
};

Spinner.propTypes = {
  /** Primary color of the spinner */
  color: PropTypes.string,
  /** Size of the spinner */
  size: PropTypes.number,
  /** Radius of the spinner */
  radius: PropTypes.number,
  /** Center the spinner */
  isMainLoader: PropTypes.bool,
  /** Custom CSS class for the circle */
  className: PropTypes.string,
  /** Custom CSS class for the container */
  containerClassName: PropTypes.string,
};

export default Spinner;
