import React, { useCallback, useEffect, useState } from 'react';
import { Route, useLocation, Link, Navigate, Routes } from 'react-router-dom';
import useAuth from './useAuth';
import PageTitle from './common/PageTitle';
import PersonIcon from '@mui/icons-material/Person';
import {
  Button,
  CircularProgress,
  TextField,
  InputAdornment,
  Link as SupernovaLink,
} from '@icapitalnetwork/supernova-core';
import NewPassword from './NewPassword';
import { EVENT } from './constants';
import {
  PHONE_NUMBER,
  SUPPORT_EMAIL_ADDRESS,
} from '@simon/core/constants/branding';

export default function ResetPassword() {
  return (
    <Routes>
      <Route index element={<ResetPasswordForm />} />
      <Route path=":recoveryToken" element={<NewPassword />} />
    </Routes>
  );
}

function ResetPasswordForm() {
  const { authClient, onEvent, resumedTransaction } = useAuth();
  const location = useLocation();
  const [formState, setFormState] = useState({
    status: 'pending',
  });
  const [masterUser] = useState(location.state?.masterUser);
  // login email from master-user
  const [login] = useState(location.state?.login || '');
  // user-facing email
  const [email, setEmail] = useState(
    masterUser?.email || location.state?.email || ''
  );

  // Resume transaction. Email already sent.
  useEffect(() => {
    if (resumedTransaction?.status === 'RECOVERY_CHALLENGE') {
      return setFormState({ status: 'ok' });
    }
  }, [resumedTransaction]);

  const onSubmit = useCallback(
    async e => {
      e?.preventDefault();
      setFormState({ status: 'fetching' });
      try {
        const transaction = await authClient.forgotPassword({
          username: login,
          factorType: 'EMAIL',
        });
        if (transaction.status === 'RECOVERY_CHALLENGE') {
          onEvent(EVENT.ResetPasswordRequestSuccess);
          return setFormState({ status: 'ok' });
        }
        throw `Okta forgotPassword(). We cannot handle the ${transaction.status} status.`;
      } catch (e) {
        onEvent(EVENT.ResetPasswordRequestError, { error: e });
        setFormState({ status: 'error' });
      }
    },
    [authClient, login, onEvent]
  );

  // Automatically submit the form when there is a valid email entered
  // To be removed if we want to allow direct access (see next lines)
  useEffect(() => {
    if (!login || resumedTransaction?.status === 'RECOVERY_CHALLENGE') return;
    onSubmit();
  }, [login, onSubmit, resumedTransaction]);

  // Direct access is forbidden
  if (!login) {
    return <Navigate to=".." />;
  }

  if (formState.status === 'ok' || formState.status === 'error') {
    return (
      <React.Fragment>
        <PageTitle
          title="Reset Password"
          subtitle={
            <React.Fragment>
              An email has been sent to <strong>{email}</strong> with
              instructions on how to reset the password. If you did not receive
              an email then please reach out to our help desk at{' '}
              <SupernovaLink href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
                <strong>{SUPPORT_EMAIL_ADDRESS}</strong>
              </SupernovaLink>{' '}
              or <strong>{PHONE_NUMBER}</strong>.
            </React.Fragment>
          }
        />
        <Button size="md" fullWidth component={Link} to="..">
          Okay
        </Button>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <PageTitle
        title="Reset Password"
        subtitle="Please enter your account's email address below to receive a password reset link."
      />
      <form onSubmit={onSubmit}>
        <TextField
          autoFocus
          fullWidth
          hideClearButton
          type="email"
          autoComplete="email"
          placeholder="Email"
          name="email"
          readOnly={formState.status === 'fetching'}
          value={email}
          onChange={e => {
            setEmail(e.target.value);
          }}
          required
          aria-label="Email"
          InputProps={{
            startAdornment: (
              // @ts-ignore
              <InputAdornment position="start">
                <PersonIcon fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
        <Button
          size="md"
          fullWidth
          type="submit"
          disabled={formState.status === 'fetching' || !email}
          sx={{ mt: 1 }}
          startIcon={
            formState.status === 'fetching' && (
              <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
            )
          }
        >
          Send Email
        </Button>
      </form>
      <Button
        variant="text"
        size="md"
        fullWidth
        sx={{ mt: 0.5 }}
        component={Link}
        to=".."
      >
        Back to Sign-in
      </Button>
    </React.Fragment>
  );
}
