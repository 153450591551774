import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './DropdownDivider.module.scss';

const DropdownDivider = forwardRef(({ className, ...props }, ref) => (
  <hr
    ref={ref}
    aria-orientation="horizontal"
    className={cn(styles.divider, className)}
    {...props}
  />
));

DropdownDivider.propTypes = {
  className: PropTypes.string,
};

export default DropdownDivider;
