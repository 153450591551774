import { AVAILABLE_ENV, ENV } from '@simon/config/env';

// Approved origins for window.postMessage() communication.
// Subdomains will also be allowed.
const APPROVED_ORIGINS = [
  'simonmarkets.com',
  'figmarketing.com',
  'figdevteam.com',
  'subscribeplatformdemo.com',
  'subscribeplatform.com',
  'envestnet.com',
  'fid-x.com',
  // FIDx sends postMessage's when iFraming SIMON QA into their UI localhost
  (process.env.NODE_ENV !== 'production' || ENV === AVAILABLE_ENV.QA) &&
    'localhost',
].filter(Boolean);

export const getTargetOrigin = referrer => {
  try {
    const { hostname } = new URL(referrer);
    if (
      APPROVED_ORIGINS.some(approvedHost => hostname.includes(approvedHost))
    ) {
      return referrer;
    }
  } catch (e) {
    return null;
  }
  return null;
};
