import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {
  forkBlur,
  forkFocus,
  isFocusVisible,
} from '@simon/core/utils/focusVisible';
import Spinner from '@simon/ui/Spinner';
import useButtonProps from './useButtonProps';
import styles from './Button.module.scss';

const getSpinnerSize = buttonSize => {
  switch (buttonSize) {
    case 'small':
      return 16;
    case 'large':
      return 20;
    default:
      return 18;
  }
};

const Button = forwardRef(
  (
    {
      as,
      variant,
      size,
      shape,
      action,
      active,
      block,
      isLoading,
      disabled,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = event => {
      if (isFocusVisible(event)) {
        setFocused(true);
      }
    };

    const handleBlur = () => {
      if (isFocusVisible !== false) {
        setFocused(false);
      }
    };

    // helper hook to turn component into an accessible button
    const [buttonProps, { tagName }] = useButtonProps({
      tagName: as,
      disabled: disabled || isLoading,
      ...props,
    });

    const Component = tagName;

    return (
      <React.Fragment>
        <Component
          {...buttonProps}
          {...props}
          ref={ref}
          className={cn(
            styles.button,
            shape === 'circle' && styles.circle,
            active && styles.active,
            action && styles.action,
            block && styles.block,
            variant && styles[variant],
            size && styles[size],
            focused && styles.focus,
            (props.href || props.to) &&
              (disabled || isLoading) &&
              styles.disabled,
            className
          )}
          onFocus={forkFocus(props, handleFocus)}
          onBlur={forkBlur(props, handleBlur)}
        >
          {isLoading && (
            <Spinner
              size={getSpinnerSize(size)}
              containerClassName={styles.loading}
            />
          )}
          {React.Children.map(children, child => {
            if (typeof child === 'string') {
              return <span>{child}</span>;
            }
            return child;
          })}
        </Component>
      </React.Fragment>
    );
  }
);

Button.propTypes = {
  /** Defines the kind (purpose) of a button. */
  variant: PropTypes.oneOf([
    'primary',
    'branding',
    'secondary',
    'tertiary',
    'ghost',
    'link',
    'danger',
    'warning',
    'success',
  ]),
  /** Specifies a large or small button. */
  size: PropTypes.oneOf(['small', 'large']),
  /** Set the button shape. */
  shape: PropTypes.oneOf(['default', 'circle']),
  /** Set the visual state of the button to `action` (green color) */
  action: PropTypes.bool,
  /** Manually set the visual state of the button to `:active`. */
  active: PropTypes.bool,
  /** Show loading indicator */
  isLoading: PropTypes.bool,
  /** Spans the full width of the Button parent. */
  block: PropTypes.bool,
  /** Disables the Button, preventing mouse events, even if the underlying component is an `<a>` or `<Link>` element. */
  disabled: PropTypes.bool,
  /** Providing a `href` will render an `<a>` element, styled as a button. */
  href: PropTypes.string,
  /** Providing a `to` will render `<Link>` element, styled as a button. */
  to: PropTypes.string,
  /** Defines HTML button type attribute. */
  type: PropTypes.oneOf(['button', 'reset', 'submit', null]),
  /** You can use a custom element type for this component. */
  as: PropTypes.elementType,
  /** Optional class name to override button styles. */
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  variant: 'primary',
  shape: 'default',
};

Button.displayName = 'Button';

export default Button;
