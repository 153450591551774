import { useEffect, useRef } from 'react';

// Inspired by https://overreacted.io/making-setinterval-declarative-with-react-hooks/
// onSuccess's return value is used to determine whether polling should continue or not.
export default function usePolling(fn, { onSuccess, onError, delay }) {
  // Support dynamic callbacks via refs.
  const _fn = useRef();
  const _onSuccess = useRef();
  const _onError = useRef();

  useEffect(() => {
    _fn.current = fn;
  }, [fn]);
  useEffect(() => {
    _onSuccess.current = onSuccess;
  }, [onSuccess]);
  useEffect(() => {
    _onError.current = onError;
  }, [onError]);

  useEffect(() => {
    let id;

    async function tick() {
      try {
        const shouldContinue = await _onSuccess.current?.(await _fn.current());

        if (shouldContinue) {
          id = setTimeout(tick, delay);
        }
      } catch (e) {
        _onError.current?.(e);
      }
    }

    if (delay !== null) {
      tick();
      return () => clearTimeout(id);
    }
  }, [delay]);
}
