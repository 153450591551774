export const PRODUCT_LINES = {
  CORE: 'core',
  ENVESTNET: 'envestnet',
  SMA: 'sma',
  SI: 'structured_investments',
  ETFs: 'etfs',
  ANN: 'annuities',
  SPECTRUM: 'spectrum',
  ARCHITECT: 'architect',
};
