import { useEffect } from 'react';
import useAuth from './useAuth';
import { getTargetOrigin } from '@simon/ui/IFrameResizeDetector/postMessageOrigins';

const defaultValidate = origin => !!getTargetOrigin(origin);

// Emits and reacts to postMessage messages related to auth.
export default function usePostMessage(validateOrigin = defaultValidate) {
  const { authClient, logout } = useAuth();

  useEffect(() => {
    if (typeof validateOrigin !== 'function') {
      throw new Error(
        'usePostMessage - validateOrigin arg should be a function.'
      );
    }

    function listener(evt) {
      if (!validateOrigin(evt.origin || evt.originalEvent.origin)) return;

      const msg = evt.data || evt.originalEvent?.data || {};

      if (msg === 'logout' || msg.command === 'logout') {
        return logout();
      }

      if (msg.command === 'login') {
        if (msg.token) {
          const {
            access_token,
            token_type = 'Bearer',
            expires_in,
            scope,
          } = msg.token;

          if (!access_token)
            throw new Error(
              `command: 'login' - token object should container 'access_token' field`
            );

          // Enhance externally provided tokens to match the signature of tokens coming through regular flow.
          // Mimics https://github.com/okta/okta-auth-js/blob/a57a689fedf4484d694865f7a0c04b14837f9bf3/lib/oidc/handleOAuthResponse.ts#L90
          const accessJwt = authClient.token.decode(access_token);

          const accessToken = {
            accessToken: access_token,
            claims: accessJwt.payload,
            expiresAt: Number(expires_in) + Math.floor(Date.now() / 1000),
            tokenType: token_type,
            scopes: scope?.split(' ') || accessJwt.payload.scp,
          };

          return authClient.tokenManager.add('accessToken', accessToken);
        }
      }
    }

    window.addEventListener('message', listener, false);
    return () => window.removeEventListener('message', listener, false);
  }, [authClient.token, authClient.tokenManager, logout, validateOrigin]);
}
