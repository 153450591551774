import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './Input.module.scss';

const InputGroup = ({ children, className }) => (
  <div className={cn(styles.inputGroup, className)} role="group">
    {children}
  </div>
);

InputGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default InputGroup;
