import { useCallback, useContext, useMemo } from 'react';
import {
  UNSAFE_DataRouterContext,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import querystring from 'query-string';

const trimLeadingQuestionMark = search => search.slice(1);

export function useQueryParam(
  queryParamName,
  defaultValue = '',
  parseOptions = {}
) {
  // we need the router context directly, so we can access the current version
  // of location in case of multiple updates within a render
  const router = useContext(UNSAFE_DataRouterContext)?.router;
  const location = useLocation();
  const navigate = useNavigate();

  const currentLocation = router?.state?.location || location;

  const paramValueInQueryString = querystring.parse(
    trimLeadingQuestionMark(currentLocation.search),
    {
      arrayFormat: 'index',
      ...parseOptions,
    }
  )[queryParamName];

  const paramValue =
    paramValueInQueryString !== undefined
      ? paramValueInQueryString
      : defaultValue;

  // removes the url query param entirely from the URL
  const removeParamValue = useCallback(() => {
    const currentLocation = router?.state?.location || location;
    const updatedSearch = {
      ...querystring.parse(trimLeadingQuestionMark(currentLocation.search), {
        arrayFormat: 'index',
        ...parseOptions,
      }),
    };

    delete updatedSearch[queryParamName];

    navigate(
      {
        ...currentLocation,
        search: querystring.stringify(updatedSearch, {
          arrayFormat: 'index',
          ...parseOptions,
        }),
      },
      { replace: true }
    );
  }, [
    location,
    navigate,
    parseOptions,
    queryParamName,
    router?.state?.location,
  ]);

  const setParamValue = useCallback(
    newParamValue => {
      const currentLocation = router?.state?.location || location;
      const updatedSearch = {
        ...querystring.parse(trimLeadingQuestionMark(currentLocation.search), {
          arrayFormat: 'index',
          ...parseOptions,
        }),
        [queryParamName]: newParamValue,
      };
      navigate(
        {
          ...currentLocation,
          search: querystring.stringify(updatedSearch, {
            arrayFormat: 'index',
            ...parseOptions,
          }),
        },
        { replace: true }
      );
    },
    [location, navigate, parseOptions, queryParamName, router?.state?.location]
  );

  return [paramValue, setParamValue, removeParamValue];
}

/**
 * A hook which can update multiple params, and update the path of the URL
 * @returns queryParamsObject - current params as an object
 * @returns setMultipleQueryParams - func to add params, or path
 * @returns setRemoveQueryParams - func to remove params
 */
export function useMultipleQueryParams(parseOptions = {}) {
  const router = useContext(UNSAFE_DataRouterContext)?.router;
  const location = useLocation();
  const navigate = useNavigate();

  const currentLocation = router?.state?.location || location;

  const queryParamsObject = useMemo(
    () =>
      querystring.parse(currentLocation.search, {
        arrayFormat: 'index',
        ...parseOptions,
      }),
    [currentLocation.search, parseOptions]
  );

  const setMultipleQueryParams = useCallback(
    newUpdatedParamsObject => {
      const { newParams, path } = newUpdatedParamsObject;
      const stringified = querystring.stringify(
        {
          ...queryParamsObject,
          ...newParams,
        },
        { arrayFormat: 'index', ...parseOptions }
      );
      const newHistoryObject = { search: stringified };
      if (path !== '') {
        newHistoryObject.pathname = path;
      }
      navigate(newHistoryObject);
    },
    [navigate, parseOptions, queryParamsObject]
  );

  const setRemoveQueryParams = useCallback(
    (paramsToDelete = [], method = 'push') => {
      const parsedParams = { ...queryParamsObject };
      paramsToDelete.forEach(param => {
        delete parsedParams[param];
      });
      const stringified = querystring.stringify(
        {
          ...parsedParams,
        },
        { arrayFormat: 'index', ...parseOptions }
      );
      navigate({ search: stringified }, { replace: method === 'replace' });
    },
    [navigate, parseOptions, queryParamsObject]
  );

  return [queryParamsObject, setMultipleQueryParams, setRemoveQueryParams];
}
