import moment from 'moment-timezone';
import { MOMENT_US_FORMAT, TIMEZONE_NYC } from '@simon/core/constants/time';
import dayjs from '@simon/core/utils/dayjs';

// Dayjs localized formats - https://day.js.org/docs/en/display/format#list-of-localized-formats
export const localizedFormats = {
  LT: 'LT', //	h:mm A	8:02 PM
  LTS: 'LTS', //	h:mm:ss A	8:02:18 PM
  L: 'L', //	MM/DD/YYYY	08/16/2018
  LL: 'LL', //	MMMM D, YYYY	August 16, 2018
  LLL: 'LLL', //	MMMM D, YYYY h:mm A	August 16, 2018 8:02 PM
  LLLL: 'LLLL', //	dddd, MMMM D, YYYY h:mm A	Thursday, August 16, 2018 8:02 PM
  l: 'l', //	M/D/YYYY	8/16/2018
  ll: 'll', //	MMM D, YYYY	Aug 16, 2018
  lll: 'lll', //	MMM D, YYYY h:mm A	Aug 16, 2018 8:02 PM
  llll: 'llll', //	ddd, MMM D, YYYY h:mm A	Thu, Aug 16, 2018 8:02 PM
};

export const MOMENT_LOCALE = {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few seconds',
    ss: '%d seconds',
    m: 'a minute',
    mm: '%d minutes',
    h: '1h',
    hh: '%dh',
    d: 'a day',
    dd: '%dd',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
};

const rawFormats = {
  short:
    /^(19[0-9][0-9]|20[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01]){0,1}$/,
  long: /^(19[0-9][0-9]|20[0-9][0-9])[-](0[1-9]|1[012])[-](0[1-9]|[12][0-9]|3[01])[T]([01][0-9]|[2][0-3])[:]([0-5][0-9])[:]([0-5][0-9])([.]([0-9]+))(([+|-]([01][0-9]|[2][0-3])[:]([0-5][0-9]))|[Z]){0,1}$/,
};

export const subtractMonths = function (endTime, months) {
  let start = new Date(endTime);
  start.setUTCMonth(start.getUTCMonth() - months);
  return Number(start);
};

export const addMonths = function (startTime, months) {
  let end = new Date(startTime);
  end.setUTCMonth(end.getUTCMonth() + months);
  return Number(end);
};

export const findNearestIndex = function (times, desiredTime) {
  let minIndex = 0;
  let maxIndex = times.length - 1;

  while (minIndex <= maxIndex) {
    let midIndex = Math.floor((minIndex + maxIndex) / 2);
    let midTime = times[midIndex];

    if (midTime === desiredTime) {
      return midIndex;
    }
    if (midTime < desiredTime) {
      minIndex = midIndex + 1;
    } else {
      maxIndex = midIndex - 1;
    }
  }

  return maxIndex;
};

export const untilBooksClose = function (booksCloseDateTime) {
  let now = Date.now();
  let delta = booksCloseDateTime - now;

  let days = delta / 1000 / 60 / 60 / 24;

  if (days <= 0) {
    return '0 days';
  }

  if (days > 1) {
    days = Math.floor(days);
    return days + ' day' + maybeS(days);
  }

  let hours = Math.floor(delta / 1000 / 60 / 60);

  if (hours === 0) {
    let minutes = Math.floor(delta / 1000 / 60);
    return minutes + ' minute' + maybeS(minutes);
  }

  let minutesLeft = Math.floor(delta / 1000 / 60) % (hours * 60);

  let returnValue = hours + ' hour' + maybeS(hours);
  if (minutesLeft > 0) {
    returnValue += ', ' + minutesLeft + ' minute' + maybeS(minutesLeft);
  }
  return returnValue;
};

function maybeS(number) {
  return number === 1 ? '' : 's';
}

export const NYDateFormat = function (value, format) {
  return !value
    ? '-'
    : moment
        .utc(value)
        .tz(TIMEZONE_NYC)
        .format(format || MOMENT_US_FORMAT);
};

const clientTimezone = dayjs.tz.guess();

export const clientTimezoneFormat = function (value, format) {
  return !value
    ? '-'
    : moment
        .utc(value)
        .tz(clientTimezone || TIMEZONE_NYC)
        .format(format || MOMENT_US_FORMAT);
};

export const UTCDateFormat = function (value, format) {
  return !value ? '-' : moment.utc(value).format(format || MOMENT_US_FORMAT);
};

export const formatRawBackendData = function (value, format) {
  if (!value) return '-';

  const matched = Object.values(rawFormats).reduce(
    (acc, test) => ((acc = acc || value.match(test)), acc),
    null
  );

  if (matched) {
    const timeMap = {
      YYYY: matched[1],
      YY: matched[1].slice(-2),
      MM: matched[2],
      DD: matched[3],
      hh: matched[4],
      mm: matched[5],
      ss: matched[6],
      SSS: matched[8],
      Z: matched[9],
      ZZ: matched[9]?.replace(':', ''),
      z: matched[9],
      zz: matched[9]?.replace(':', ''),
    };
    const formatted = Object.keys(timeMap).reduce(
      (acc, timeElement) => (
        (acc = acc.replace(timeElement, timeMap[timeElement])), acc
      ),
      format
    );
    return formatted;
  }
};
export const startOfToday = moment().startOf('day');

export const lastDayOfNextMonth = function () {
  return moment().add(1, 'month').endOf('month');
};

export const endOfNYDayMomentDate = moment().tz(TIMEZONE_NYC).endOf('day');

export const nextNYDayMomentDate = moment().tz(TIMEZONE_NYC).add(1, 'days');

export const getLastWorkingWeekDay = function (date) {
  const weekDayNumber = date.day();
  if (weekDayNumber > 0 && weekDayNumber <= 5) return date;
  // get last friday
  return moment.utc(date).date(date.date() - ((weekDayNumber + 2) % 7));
};

export const convertMonthsToYears = months => Math.floor(months / 12);

export const get4pmET = date =>
  date.tz(TIMEZONE_NYC).startOf('day').hour(16).tz(moment.tz.guess());

const getLabel = (number, label) =>
  `${number} ${label}${number > 1 ? 's' : ''}`;
export const formatYearAndMonth = date => {
  const exactMonths = dayjs(date).diff(dayjs(), 'months', 'true');
  const months = Math.round(exactMonths % 12);
  const years = Math.floor(exactMonths / 12) + Math.floor(months / 12);

  return months < 0 || Math.round(exactMonths) < 1
    ? dayjs(date).fromNow()
    : [
        'in',
        years > 0 && getLabel(years, 'year'),
        months > 0 && months < 12 && getLabel(months, 'month'),
      ]
        .filter(Boolean)
        .join(' ');
};

export function convertSecondsToHoursMinutesSeconds(totalSeconds) {
  if (!totalSeconds) {
    return;
  }

  let returnStr = '';

  const hours = Math.floor(totalSeconds / 3600);
  if (hours) {
    returnStr += hours + 'h ';
  }
  totalSeconds = totalSeconds - hours * 3600;

  const minutes = Math.floor(totalSeconds / 60);
  if (minutes) {
    returnStr += minutes + 'm ';
  }

  totalSeconds = totalSeconds - minutes * 60;
  if (totalSeconds) {
    returnStr += totalSeconds + 's';
  }

  return returnStr.trim();
}

/**
 * Build a todays date string for the specified format
 *
 * @example
 * ```javascript
 * import {formattedString, localizedFormats.LL} from '@simon/code/utils/time'
 *
 * formattedString(dayjs(), localizedFormats.LL); //OUTPUT - June 3, 2023
 * ```
 * @param {string} format - Formatting options, must use time.localized formats
 *
 * @returns Returns a string representing todays date in the specified format
 */
export const todayLocalizedFormat = format => dayjs().format(format);
