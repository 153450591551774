class AuthService {
  _authClient;

  get authClient() {
    if (!this._authClient) {
      console.warn(
        'AuthService.authClient is not defined. Maybe you forgot to AuthService.setAuthClient() ?'
      );
    }
    return this._authClient;
  }

  async setAuthClient(obj) {
    this._authClient = obj;
  }

  getAccessToken() {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    return this.authClient?.getAccessToken();
  }

  async getClaims() {
    if (process.env.NODE_ENV === 'test') {
      return;
    }
    const tokenManager = await this.authClient.tokenManager.get('accessToken');
    return tokenManager?.claims;
  }

  _isAuthenticated = false;

  isAuthenticated() {
    return this._isAuthenticated;
    //   return (
    //     this.authClient?.authStateManager.getAuthState()?.isAuthenticated || false
    //   );
  }

  /**
   * A delay exists between initiating user impersonation and its actual commencement. During this interim period,
   * the "isAuthenticated" variable briefly holds a false value, causing the AuthPage to momentarily appear.
   * To address this, we should set this variable when entering and exiting user impersonation,
   * enabling the showing of a loading indicator instead of the AuthPage.
   */
  _isImpersonationLoading;

  getIsImpersonationLoading() {
    return this._isImpersonationLoading;
  }

  setIsImpersonationLoading(isLoading) {
    this._isImpersonationLoading = isLoading;
  }
}

// Singleton
export default new AuthService();
