import Cookies from 'js-cookie';
import createApi, { SIMON_API_URL } from '@simon/core/services/api';
import showSessionExpiredNotification from '@simon/core/utils/session-expired';
import { isBrowser } from '@simon/config/env';
import { authService } from '@simon/auth';

const EXPECTED_401_URIS = [
  // 401 is an expected response when a user tries to login to the CRM
  // with improper credentials
  '/v1/crm-integrations/enable',
];

export default createApi({
  fetch: isBrowser ? window.fetch : global.fetch,
  interceptors: {
    request: async (uri, config) => {
      // add CSRF token (if present) to all non-GET requests
      if (config.method !== 'GET') {
        const csrfToken = Cookies.get('simon-csrf-token');
        if (csrfToken) {
          config.headers['x-simon-csrf-token'] = csrfToken;
        }
      }
      // Implicitly true
      if (config.authorization !== false) {
        const token = await authService.getAccessToken();
        if (token) {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          console.warn(
            `${config.method} ${uri} request marked as secure but no tokens available.`
          );
        }
      }
      if (uri.startsWith('http')) {
        // Absolute URL
        return [uri, config];
      }
      // Relative URL, prepend SIMON API base URL
      return [`${SIMON_API_URL}${uri}`, config];
    },

    responseError: (err, { uri }) => {
      // Show popup for expired sessions
      if (
        err.status === 401 &&
        !EXPECTED_401_URIS.includes(uri) &&
        // don't show failures from non-cancelled requests after logout
        authService.isAuthenticated()
      ) {
        showSessionExpiredNotification();
      }

      // reject the promise
      throw err;
    },
  },
});
