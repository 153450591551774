import { ENV, AVAILABLE_ENV, CONFIG } from '@simon/config/env';
import { datadogRum } from '@datadog/browser-rum';

// Comment out host check to test DD integration locally
// Disable datadog so that it doesn't collect PII
let canUseDataDog =
  typeof window !== 'undefined' &&
  location.hostname !== 'localhost' &&
  ENV !== AVAILABLE_ENV.PROD;

let debug = false;

export function onDatadogLocationChange(location) {
  if (canUseDataDog) {
    datadogRum.startView(location.pathname);
  }
}

export function initDatadogRum(config) {
  try {
    if (!canUseDataDog) return;

    const defaultConfig = config || {};
    debug = defaultConfig.debug;

    const applicationId =
      process.env.SIMONMARKETS_DD_APPLICATION_ID ||
      process.env.REACT_APP_SIMONMARKETS_DD_APPLICATION_ID;
    const clientToken =
      process.env.SIMONMARKETS_DD_ClIENT_ID ||
      process.env.REACT_APP_SIMONMARKETS_DD_ClIENT_ID;

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service: 'simonmarkets',
      env: ENV,
      version: CONFIG.release,
      sampleRate: 100,
      trackInteractions: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingOrigins: [
        process.env.NODE_ENV !== 'production' && 'http://localhost:3000',
        process.env.NODE_ENV !== 'production' && 'https://localhost:3000',
        'https://dev.simonmarkets.com',
        'https://www.dev.simonmarkets.com',
        'https://qa.simonmarkets.com',
        'https://www.qa.simonmarkets.com',
        'https://simonmarkets.com',
        'https://www.simonmarkets.com',
      ].filter(Boolean),
      trackViewsManually: true,
    });

    datadogRum.startSessionReplayRecording();

    if (debug) {
      console.info('DD RUM: Started');
    }
  } catch (e) {
    canUseDataDog = false;
    console.error('DataDog cannot start: ' + e.message);

    if (debug) {
      console.info('DD RUM: Failed to start');
    }
  }
}

export function datadogRumIdentify({ maskedIds: { userId } }) {
  if (canUseDataDog) {
    datadogRum.setUser({ id: userId });

    if (debug) {
      console.info('DD RUM: Identified ' + userId);
    }
  }
}
