import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  Fragment,
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import useMergedState from '@simon/core/hooks/useMergedState';
import InputGroup from './InputGroup';
import BaseInput from './BaseInput';
import styles from './Input.module.scss';

const Input = forwardRef(
  (
    {
      addonAfter,
      addonBefore,
      affixWrapperClassName,
      autoComplete,
      className,
      defaultValue,
      error,
      groupClassName,
      helperText,
      htmlSize,
      id,
      inputClassName,
      isFocused,
      isDisabled,
      isReadOnly,
      isRequired,
      label,
      labelClassName,
      onBlur,
      onChange,
      onFocus,
      onKeyDown,
      onPressEnter,
      prefix,
      size,
      suffix,
      innerPrefix, // alias for prefix (useful for react-number-format)
      innerSuffix, // alias for suffix (useful for react-number-format)
      testId,
      type = 'text',
      value,
      wrapperClassName,
      ...props
    },
    ref
  ) => {
    // alias is needed due to conflict with NumberFormat library that also uses suffix/prefix
    prefix = innerPrefix || prefix;
    suffix = innerSuffix || suffix;

    const [innerValue, setInnerValue] = useMergedState('', {
      value,
      defaultValue,
    });
    const [focused, setFocused] = useMergedState(false, isFocused);

    const inputRef = useRef(null);

    useImperativeHandle(ref, () => ({
      focus: () => {
        inputRef.current?.focus();
      },
      blur: () => {
        inputRef.current?.blur();
      },
      setSelectionRange: (start, end, direction) => {
        inputRef.current?.setSelectionRange(start, end, direction);
      },
      select: () => {
        inputRef.current?.select();
      },
      input: inputRef.current,
    }));

    useEffect(() => {
      setFocused(prev => (prev && isDisabled ? false : prev));
    }, [isDisabled]);

    const handleChange = event => {
      if (value === undefined) {
        setInnerValue(event.target.value);
      }
      if (onChange) {
        onChange(event);
      }
    };

    const handleKeyDown = event => {
      if (onPressEnter && event.key === 'Enter') {
        onPressEnter(event);
      }
      if (onKeyDown) {
        onKeyDown(event);
      }
    };

    const handleFocus = event => {
      setFocused(true);
      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = event => {
      setFocused(false);
      if (onBlur) {
        onBlur(event);
      }
    };

    const inputElement = (
      <input
        {...props}
        id={id}
        value={innerValue}
        className={cn(
          styles.input,
          styles[size],
          {
            [styles.error]: error,
            [styles.disabled]: isDisabled,
          },
          inputClassName,
          !addonBefore && !addonAfter && !prefix && !suffix && className
        )}
        ref={inputRef}
        size={htmlSize}
        type={type}
        readOnly={isReadOnly}
        required={isRequired}
        disabled={isDisabled}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        data-testid={testId}
      />
    );

    const labelNode = label && (
      <label htmlFor={id} className={cn(styles.label, labelClassName)}>
        {label}
        {isRequired && <span className={styles.required}>*</span>}
      </label>
    );

    const feedbackNode = (isString(error) || isObject(error) || helperText) && (
      <div
        className={cn(styles.helperText, {
          [styles.error]: error,
        })}
      >
        {error || helperText}
      </div>
    );

    return (
      <Fragment>
        {labelNode}
        <BaseInput
          inputElement={inputElement}
          focused={focused}
          prefix={prefix}
          suffix={suffix}
          addonBefore={addonBefore}
          addonAfter={addonAfter}
          className={className}
          affixWrapperClassName={affixWrapperClassName}
          groupClassName={groupClassName}
          wrapperClassName={wrapperClassName}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          error={error}
          size={size}
        />
        {feedbackNode}
      </Fragment>
    );
  }
);

Input.Group = InputGroup;

Input.propTypes = {
  /** The label text displayed after (on the right side of) the input field. */
  addonAfter: PropTypes.node,
  /** The label text displayed before (on the left side of) the input field. */
  addonBefore: PropTypes.node,
  /** The prefix element for the Input. */
  prefix: PropTypes.node,
  innerPrefix: PropTypes.node, // alias for prefix
  /** The suffix element for the Input. */
  suffix: PropTypes.node,
  innerSuffix: PropTypes.node, // alias for suffix
  /** A label rendered above the input field. */
  label: PropTypes.string,
  /** A caption rendered below the input field. */
  helperText: PropTypes.string,
  /** If an error prop passed it will be rendered in place of caption as an error message. */
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.node,
  ]),
  /** The type of input. See MDN: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#Form_%3Cinput%3E_types */
  type: PropTypes.string,
  /** Specifies that an input element should automatically get focus when the page loads. */
  autoFocus: PropTypes.bool,
  /** Specifies whether an input element should have autocomplete enabled. */
  autoComplete: PropTypes.oneOf(['on', 'off']),
  /** The size attribute specifies the visible width, in characters, of an input element. */
  htmlSize: PropTypes.number,
  /** The size of the input box. */
  size: PropTypes.oneOf(['compact']),
  /** Sets aria-describedby attribute. */
  'aria-describedby': PropTypes.string,
  /** Sets aria-label attribute. */
  'aria-label': PropTypes.string,
  /** Sets aria-labelledby attribute. */
  'aria-labelledby': PropTypes.string,
  /** Maximum number of characters. */
  maxLength: PropTypes.number,
  /** Additional CSS class name for input. */
  className: PropTypes.string,
  /** Additional CSS class name for wrapper around `prefix`/`suffix`. */
  affixWrapperClassName: PropTypes.string,
  /** Additional CSS class name for wrapper around `addonBefore`/`addonAfter`. */
  groupClassName: PropTypes.string,
  /** Additional CSS class name for wrapper around `groupClassName`. */
  wrapperClassName: PropTypes.string,
  /** Additional CSS class name for input, regardless if addons, prefix, suffix are used. */
  inputClassName: PropTypes.string,
  /** Input's value attribute. */
  value: PropTypes.string,
  /** Input's defaultValue attribute. */
  defaultValue: PropTypes.string,
  /** Input's placeholder. */
  placeholder: PropTypes.string,
  /** Id attribute value to be added to the input element and as a label's `for` attribute value. */
  id: PropTypes.string,
  /** Name of the input form control. */
  name: PropTypes.string,
  /** Called the onBlur event triggers. */
  onBlur: PropTypes.func,
  /** Called when input value is changed. */
  onChange: PropTypes.func,
  /** Called the onFocus event triggers. */
  onFocus: PropTypes.func,
  /** Called the onKeyDown event triggers. */
  onKeyDown: PropTypes.func,
  /** Called the onKeyPress event triggers. */
  onKeyPress: PropTypes.func,
  /** Called the onKeyUp event triggers. */
  onKeyUp: PropTypes.func,
  /** The callback function that is triggered when Enter key is pressed */
  onPressEnter: PropTypes.func,
  /** If true, prevents the value of the input from being edited. */
  isReadOnly: PropTypes.bool,
  /** Set required for form that the field is part of. */
  isRequired: PropTypes.bool,
  /** Sets the field as uneditable, with a changed hover state. */
  isDisabled: PropTypes.bool,
  /** A `testId` prop is provided for specified elements, which is a unique string that appears as a data attribute `data-testid` in the rendered code, serving as a hook for automated tests. */
  testId: PropTypes.string,
};

export default Input;
