import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Navigate,
  useLocation,
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import useAuth from './useAuth';
import PageTitle from './common/PageTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Button,
  CircularProgress,
  Link,
  TextField,
} from '@icapitalnetwork/supernova-core';
import ErrorMessage from './common/ErrorMessage';
import MfaAlert from './common/MfaAlert';
import { EVENT, FACTOR_TYPE, TRANSACTION_STATUS } from './constants';
import MfaEnroll from './MfaEnroll';
import { getFactorTitle } from './utils';
import SelectOption from './common/SelectOption';
import Divider from '@icapitalnetwork/supernova-core/Divider';
import usePolling from '@simon/core/hooks/usePolling';
import styles from './Mfa.module.scss';
import {
  PHONE_NUMBER,
  SUPPORT_EMAIL_ADDRESS,
} from '@simon/core/constants/branding';

export default function MfaRouter() {
  const { transaction } = useAuth();

  if (!transaction) {
    return <Navigate replace to="/" />;
  }

  return (
    <Routes>
      <Route
        index
        element={
          transaction.status.includes(TRANSACTION_STATUS.MFA_ENROLL) &&
          !transaction?.factors?.length > 1 &&
          !transaction?.factor?.factorType === FACTOR_TYPE.SMS &&
          !transaction?.factor?.factorType === FACTOR_TYPE.CALL ? (
            <Navigate replace to="enroll" />
          ) : (
            <Mfa />
          )
        }
      />
      <Route path="enroll/*" element={<MfaEnroll />} />
    </Routes>
  );
}

// https://developer.okta.com/docs/reference/api/authn/#verify-factor
function Mfa() {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    loginSessionToken,
    onEvent,
    transaction,
    setExpiredPasswordTxCtx,
    setSelectedFactorCtx,
  } = useAuth();
  const email = location.state?.email;

  const factors = useMemo(
    () =>
      // MFA_REQUIRED || MFA_CHALLENGE
      (transaction.factors || [transaction.factor]).filter(getFactorTitle),
    [transaction.factor, transaction.factors]
  );

  const [isVerifyCode, setIsVerifyCode] = useState(
    transaction.status === TRANSACTION_STATUS.MFA_CHALLENGE ||
      transaction.status === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE ||
      (factors.length === 1 &&
        transaction.status !== TRANSACTION_STATUS.MFA_ENROLL &&
        (factors[0].factorType === FACTOR_TYPE.SMS ||
          factors[0].factorType === FACTOR_TYPE.CALL))
  );

  const [selectedFactor, setSelectedFactor] = useState(() => {
    // Look for push factor
    const pushOrTotpFactor = factors.find(
      factor =>
        factor.provider === 'OKTA' &&
        (factor.factorType === FACTOR_TYPE.PUSH ||
          factor.factorType === FACTOR_TYPE.TOTP)
    );

    // Look for question factor
    const questionFactor = factors.find(
      factor =>
        factor.provider === 'OKTA' && factor.factorType === FACTOR_TYPE.QUESTION
    );

    const prioritizedFactor = pushOrTotpFactor || questionFactor;

    if (isVerifyCode && transaction.factor) {
      // If verifying code, we select the factor from the transaction
      return transaction.factor;
    } else if (prioritizedFactor) {
      // If there is a prioritized factor, we select it
      return prioritizedFactor;
    } else if (factors.length > 1) {
      // If there is more than one factor, we need to select one
      return undefined;
    } else {
      // If there is only one factor, we select it
      return factors[0];
    }
  });

  const onSuccess = useCallback(
    async transaction => {
      if (transaction.status === TRANSACTION_STATUS.SUCCESS) {
        onEvent(EVENT.MFARequiredSuccess, {
          provider: selectedFactor.provider,
          factorType: selectedFactor.factorType,
        });
        // get tokens using the sessionToken
        await loginSessionToken(transaction.sessionToken);
        return;
      }

      if (transaction.status === TRANSACTION_STATUS.PASSWORD_EXPIRED) {
        setExpiredPasswordTxCtx(transaction);
        navigate('../expired');
        return;
      }

      if (transaction.status === TRANSACTION_STATUS.MFA_ENROLL) {
        // Additional factors setup is needed.
        navigate('enroll');
        return;
      }

      throw `MFA: We cannot handle the ${transaction.status} status.`;
    },
    [navigate, loginSessionToken, onEvent, selectedFactor]
  );

  const showOktaQuestionsFactor =
    selectedFactor?.provider === 'OKTA' &&
    selectedFactor?.factorType === FACTOR_TYPE.QUESTION;
  const showOktaPushFactor =
    selectedFactor?.provider === 'OKTA' &&
    (selectedFactor?.factorType === FACTOR_TYPE.PUSH ||
      selectedFactor?.factorType === FACTOR_TYPE.TOTP);
  const showMfaVerifyCode =
    selectedFactor?.provider === 'OKTA' &&
    (selectedFactor?.factorType === FACTOR_TYPE.SMS ||
      selectedFactor?.factorType === FACTOR_TYPE.CALL) &&
    isVerifyCode;

  const mfaTitle = (
    <PageTitle
      title="Authentication Required"
      subtitle={
        <React.Fragment>
          Multi-factor authentication for:
          <br />
          <strong>{email}</strong>
        </React.Fragment>
      }
    />
  );

  const selectTitle = (
    <PageTitle
      title="Authentication Required"
      subtitle={
        <React.Fragment>
          Select a multi-factor authentication method{' '}
          {transaction.status === TRANSACTION_STATUS.MFA_ENROLL &&
            ' to set up '}{' '}
          for: <strong>{email}</strong>
        </React.Fragment>
      }
    />
  );

  const verifyTitle = (
    <PageTitle
      title="Verify your identity"
      subtitle={
        <React.Fragment>
          Enter the code you recieved by phone.{' '}
          <strong>This code will expire in 5 minutes</strong>
        </React.Fragment>
      }
    />
  );

  return (
    <React.Fragment>
      {!showOktaPushFactor && !showOktaQuestionsFactor && !isVerifyCode && (
        <SelectFactor
          pageTitle={selectTitle}
          factors={factors}
          transaction={transaction}
          selectedFactor={selectedFactor}
          setSelectedFactor={factor => {
            setSelectedFactor(factor);
            setSelectedFactorCtx(factor);
          }}
          setIsVerifyCode={setIsVerifyCode}
          onSuccess={onSuccess}
        />
      )}
      {showOktaQuestionsFactor && (
        <OktaQuestionsFactor
          pageTitle={mfaTitle}
          factor={selectedFactor}
          transaction={transaction}
          onSuccess={onSuccess}
        />
      )}
      {showOktaPushFactor && (
        <OktaPushFactor
          pageTitle={mfaTitle}
          factor={selectedFactor}
          factorTotp={transaction.factors?.find(
            factor =>
              factor.provider === 'OKTA' &&
              factor.factorType === FACTOR_TYPE.TOTP
          )}
          transaction={transaction}
          onSuccess={onSuccess}
        />
      )}
      {showMfaVerifyCode && (
        <MfaVerifyCode
          pageTitle={verifyTitle}
          factor={selectedFactor}
          transaction={transaction}
          onSuccess={onSuccess}
        />
      )}
    </React.Fragment>
  );
}

function SelectFactor({
  pageTitle,
  factors,
  transaction,
  selectedFactor,
  setSelectedFactor,
  setIsVerifyCode,
}) {
  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {pageTitle}
      {formState.error && <MfaAlert isError>{formState.error}</MfaAlert>}
      {factors.map(factor => (
        <SelectOption
          key={factor.provider + factor.factorType}
          type={factor.factorType}
          phone={factor.profile?.phoneNumber}
          onClick={() => {
            setSelectedFactor(factor);
          }}
          selected={selectedFactor === factor}
        />
      ))}
      <Button
        size="md"
        type="submit"
        disabled={!selectedFactor}
        sx={{ mt: 1 }}
        onClick={() => {
          if (transaction.status === TRANSACTION_STATUS.MFA_ENROLL) {
            // Additional factors setup is needed.
            navigate('enroll');
            return;
          }

          if (
            selectedFactor.factorType === FACTOR_TYPE.SMS ||
            selectedFactor.factorType === FACTOR_TYPE.CALL
          ) {
            setIsVerifyCode(true);
          } else {
            setFormState({
              status: 'no-selected-factor',
              error: 'Please select a factor to continue',
            });
          }
        }}
      >
        {transaction.status.includes(TRANSACTION_STATUS.MFA_ENROLL)
          ? 'Enroll device'
          : 'Send verification code'}
      </Button>
    </React.Fragment>
  );
}

function OktaQuestionsFactor({ pageTitle, factor, onSuccess }) {
  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));
  const [answer, setAnswer] = useState('');

  return (
    <React.Fragment>
      {pageTitle}
      <form
        onSubmit={async e => {
          e.preventDefault();
          try {
            setFormState(s => ({ ...s, status: 'verify-fetching' }));
            const transaction = await factor.verify({
              answer,
            });
            setFormState({ transaction, status: 'verify-ok' });
            onSuccess(transaction);
          } catch (e) {
            setFormState(s => ({
              ...s,
              ...e,
              status: 'verify-error',
              error:
                e.errorCode === 'E0000069' ? (
                  <React.Fragment>
                    This account has been locked due to too many attempts.
                    <br />
                    To get help setting up your account, reach out to our team
                    at{' '}
                    <a href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
                      {SUPPORT_EMAIL_ADDRESS}
                    </a>{' '}
                    or <a href={`tel:${PHONE_NUMBER}`}>{PHONE_NUMBER}</a>.
                  </React.Fragment>
                ) : (
                  'Invalid answer.'
                ),
            }));
          }
        }}
      >
        <label htmlFor="answer" className={styles.securityQuestionLabel}>
          <strong>Security Question</strong>
          <br />
          <span>{factor.profile.questionText}</span>
        </label>
        <TextField
          fullWidth
          hideClearButton
          autoFocus
          autoComplete="false"
          placeholder="Enter answer"
          name="answer"
          value={answer}
          onChange={e => {
            setAnswer(e.target.value);
          }}
          required
          aria-label="Security Answer"
        />
        {formState.error && <ErrorMessage error={formState.error} />}
        <Button
          size="md"
          type="submit"
          disabled={
            formState.status === 'verify-fetching' ||
            !answer ||
            formState.error?.errorCode === 'E0000069'
          }
          sx={{ mt: 1 }}
          startIcon={
            formState.status === 'verify-fetching' && (
              <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
            )
          }
        >
          Submit
        </Button>
      </form>
    </React.Fragment>
  );
}

function OktaPushFactor({
  pageTitle,
  factor,
  factorTotp,
  transaction: _transaction,
  onSuccess,
}) {
  const navigate = useNavigate();
  const [transaction, setTransaction] = useState(_transaction);
  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));
  const [otpCodeVisible, setOtpCodeVisible] = useState(false);
  const [passCode, setPassCode] = useState('');

  // Send Push
  useEffect(() => {
    // Already sent
    if (transaction.status === TRANSACTION_STATUS.MFA_CHALLENGE) return;

    (async () => {
      try {
        setTransaction(
          await factor.verify({
            autoPush: true,
          })
        );
        // startPoll();
      } catch (e) {
        setFormState({
          status: 'verify-error',
          error: e?.errorSummary || e,
        });
      }
    })();
  }, [factor, navigate, onSuccess, transaction.status]);

  // Start polling
  usePolling(
    async () => {
      setFormState({ status: 'poll-fetching' });
      return transaction.poll();
    },
    {
      onSuccess: async nextTransaction => {
        if (nextTransaction.status === TRANSACTION_STATUS.SUCCESS) {
          // login
          onSuccess(nextTransaction);
          // stop polling
          return false;
        }
        if (nextTransaction.factorResult !== 'WAITING') {
          setFormState({
            status: 'poll-error',
            error:
              nextTransaction.factorResult === 'TIMEOUT'
                ? 'Push notification has expired'
                : nextTransaction.factorResult === 'REJECTED'
                ? 'Push notification has been rejected'
                : `Error: ${nextTransaction.factorResult}`,
          });
          // stop polling
          return false;
        }

        // continue polling
        setTransaction(nextTransaction);
        return true;
      },
      onError: e => setFormState({ status: 'poll-error', error: e }),
      delay: transaction.factorResult === 'WAITING' ? 14000 : null,
    }
  );

  // if (transaction.status === 'MFA_CHALLENGE') {
  //   if (transaction.factorResult !== 'WAITING') {
  //     return navigate('..');
  //   }
  //   startPoll();
  // }

  return (
    <React.Fragment>
      {pageTitle}
      <label htmlFor="answer" className={styles.securityQuestionLabel}>
        OKTA Verify ({factor.profile.name})
      </label>
      <div className={styles.oktaPushContainer}>
        <Button
          size="md"
          fullWidth
          disabled={formState.status === 'poll-fetching'}
          startIcon={
            formState.status === 'poll-fetching' && (
              <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
            )
          }
          onClick={async () => {
            setTransaction(await transaction.resend('push'));
          }}
        >
          {formState.status === 'poll-fetching' ? 'Push Sent!' : 'Send Push'}
        </Button>
      </div>
      {formState.status === 'poll-fetching' && (
        <Button
          variant="outlined"
          size="md"
          fullWidth
          sx={{ mt: 0.5 }}
          onClick={async () => {
            setTransaction(await transaction.resend('push'));
          }}
        >
          Resend
        </Button>
      )}
      {formState.error && formState.status === 'poll-error' && (
        <ErrorMessage error={formState.error} />
      )}
      {factorTotp && (
        <React.Fragment>
          <div>
            <Divider sx={{ m: '1rem 0' }}>or</Divider>
          </div>
          {!otpCodeVisible && (
            <Button
              variant="outlined"
              size="md"
              fullWidth
              onClick={() => {
                setOtpCodeVisible(true);
              }}
            >
              Enter code
            </Button>
          )}
          {otpCodeVisible && (
            <React.Fragment>
              <form
                onSubmit={async e => {
                  e.preventDefault();
                  try {
                    setFormState({ status: 'otp-fetching' });
                    const nextTransaction = await factorTotp.verify({
                      passCode,
                    });
                    if (nextTransaction.status === 'SUCCESS') {
                      return onSuccess(nextTransaction);
                    }
                    setFormState({
                      status: 'otp-error',
                      error: `Error: ${nextTransaction.factorResult}`,
                    });
                  } catch (e) {
                    setFormState({ status: 'otp-error', error: e });
                  }
                }}
                className={styles.oktaPushCodeContainer}
              >
                <TextField
                  autoFocus
                  hideClearButton
                  value={passCode}
                  onChange={e => setPassCode(e.target.value)}
                  placeholder="Enter code"
                />
                <Button
                  size="md"
                  type="submit"
                  sx={{ ml: 0.5 }}
                  disabled={
                    formState.status === 'otp-fetching' || passCode?.length < 6
                  }
                  startIcon={
                    formState.status === 'otp-fetching' && (
                      <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
                    )
                  }
                >
                  Verify
                </Button>
              </form>
              {formState.error && formState.status === 'otp-error' && (
                <ErrorMessage error={formState.error} />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <Button
        variant="text"
        size="md"
        fullWidth
        sx={{ mt: 0.5 }}
        onClick={async () => {
          await transaction.cancel();
          navigate('..', { state: { transaction: null } });
        }}
      >
        Back to Sign-in
      </Button>
    </React.Fragment>
  );
}

function MfaVerifyCode({
  pageTitle,
  factor,
  transaction: _transaction,
  onSuccess,
}) {
  const navigate = useNavigate();
  const { setTransaction: setTransactionCtx } = useAuth();
  const [transaction, setTransaction] = useState(_transaction);
  const [passCode, setPassCode] = useState('');
  const [formState, setFormState] = useState({
    status: 'pending',
    error: null,
  });

  //Send initial code with transaction.verify()
  // Send Push
  useEffect(() => {
    // Already sent
    if (
      transaction.status === TRANSACTION_STATUS.MFA_CHALLENGE ||
      transaction.status === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE
    )
      return;

    (async () => {
      try {
        setTransaction(await factor.verify());
      } catch (e) {
        setFormState({
          status: 'verify-code-error',
          error: e?.errorSummary || e,
        });
      }
    })();
  }, [factor, transaction.status]);

  const resendCode = async e => {
    e.preventDefault();
    try {
      const trx = await transaction.resend(factor?.factorType);
      setTransaction(trx);
      setFormState({
        status: 'verify-code-resent',
        error: null,
      });
    } catch (e) {
      if (e.errorCode === 'E0000109') {
        setFormState({
          status: 'verify-code-error',
          error: (
            <React.Fragment>
              An SMS was recently sent. Please wait 30 seconds before trying
              again.{' '}
              <Link href="#" onClick={resendCode}>
                Resend Code
              </Link>
            </React.Fragment>
          ),
        });
        return;
      }
      setFormState({
        status: 'verify-code-error',
        error: e.errorSummary,
      });
    }
  };

  const InvalidCode = () => (
    <React.Fragment>
      Invalid code. Please try again or{' '}
      <Link href="#" onClick={resendCode}>
        request a new code.
      </Link>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {pageTitle}
      {formState.error && <MfaAlert isError>{formState.error}</MfaAlert>}
      {formState.status === 'verify-code-resent' && (
        <MfaAlert>A new security code was sent successfully.</MfaAlert>
      )}
      <form
        onSubmit={async e => {
          e.preventDefault();
          try {
            setFormState(s => ({ ...s, status: 'verify-code-fetching' }));

            const nextTransaction =
              transaction.status === TRANSACTION_STATUS.MFA_ENROLL_ACTIVATE
                ? await transaction.activate({ passCode })
                : await transaction.verify({ passCode });

            if (
              nextTransaction.status === TRANSACTION_STATUS.SUCCESS ||
              nextTransaction.status === TRANSACTION_STATUS.PASSWORD_EXPIRED
            ) {
              return onSuccess(nextTransaction);
            }

            setFormState(s => ({
              ...s,
              status: 'verify-code-error',
              error: <InvalidCode />,
            }));
          } catch (e) {
            setFormState(s => ({
              ...s,
              status: 'verify-code-error',
              error: <InvalidCode />,
            }));
          }
        }}
      >
        <TextField
          autoFocus
          hideClearButton
          fullWidth
          id="passCode"
          label="Enter the verification code"
          placeholder="123456"
          value={passCode}
          onChange={e => setPassCode(e.target.value)}
          type="password"
          maxLength={6}
        />
        <Button
          size="md"
          fullWidth
          type="submit"
          sx={{ mt: 1 }}
          disabled={formState.status === 'verify-code-fetching'}
          startIcon={
            formState.status === 'verify-code-fetching' && (
              <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
            )
          }
        >
          Verify
        </Button>
      </form>
      <Divider sx={{ m: '1rem 0' }} />
      <div className={styles.verifyFooter}>
        <p>
          Need a new code?{' '}
          <Link href="#" onClick={resendCode}>
            Resend
          </Link>
        </p>
        <Button
          variant="text"
          size="md"
          onClick={async () => {
            await transaction.cancel();
            setTransactionCtx(null);
            navigate('..');
          }}
          startIcon={<ArrowBackIcon />}
        >
          Start over
        </Button>
      </div>
    </React.Fragment>
  );
}
