import React from 'react';
import useAuth from './useAuth';

// HOC for class based components
export default function withAuth(Component) {
  return function WithAuthComponent(props) {
    const auth = useAuth();
    return <Component {...props} auth={auth} />;
  };
}
