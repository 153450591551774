import { isBrowser } from '@simon/config/env';

const generateSsoLink = (ssoPrefix, uri, fallbackOrigin) => {
  if (!ssoPrefix) {
    return isBrowser
      ? `${window.location.origin}${uri}`
      : fallbackOrigin
      ? `${fallbackOrigin}${uri}`
      : '';
  }

  const delimiter = ssoPrefix.baseUrl.includes('?') ? '&' : '?';

  const simonUri = uri.startsWith('/#')
    ? `${ssoPrefix.simonBase}${uri}`
    : `${ssoPrefix.simonBase}/#/redirect${
        uri.startsWith('/') ? uri : '/' + uri
      }`;

  if (ssoPrefix.isRedirectionKeyEncoded) {
    // There is already an encoded redirect url in the baseUrl.
    // We should append ours by encoding everything including `${delimiter}${sso.redirectionKey}=` part
    // ex. https://ida.jpmorganchase.com/adfs/ls/idpinitiatedsignon.aspx?RelayState=RPID%3Dhttps%253A%252F%252Fwww.okta.com%252Fsaml2%252Fservice-provider%252Fspgscxthegmnothfokfv
    return `${ssoPrefix.baseUrl}${encodeURIComponent(
      `${delimiter}${ssoPrefix.redirectionKey}=${simonUri}`
    )}`;
  }

  // Encode only the `uri`
  return `${ssoPrefix.baseUrl}${delimiter}${
    ssoPrefix.redirectionKey
  }=${encodeURIComponent(simonUri)}`;
};

// Prepend HashRouter prefix.
// Centralized here so that it's easy to change if we migrate to BrowserRouter.
export const generateSsoRouteLink = (ssoPrefix, uri, fallbackOrigin) =>
  generateSsoLink(ssoPrefix, `/#${uri}`, fallbackOrigin);

export const generateSsoApiLink = (ssoPrefix, uri, fallbackOrigin) =>
  generateSsoLink(ssoPrefix, uri, fallbackOrigin);
