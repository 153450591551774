import React, { useEffect } from 'react';
import { Col, Row } from '@simon/ui/Grid';

import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import ThemeProvider from '@icapitalnetwork/supernova-core/Provider';
import SupernovaLink from '@icapitalnetwork/supernova-core/Link';
import LoginPage from './LoginPage';
import ResetPassword from './ResetPassword';
import useAuth from './useAuth';
import { AVAILABLE_ENV } from '@simon/config/env';
import {
  ANNUITIES_SERVICE_NAME,
  COMPANY_NAME,
  INC_NAME,
  LLC_COMPANY_NAME,
  NETWORK_COMPANY_NAME,
  SUPPORT_EMAIL_ADDRESS,
} from '@simon/core/constants/branding';
import Chip from '@simon/ui/Chip';
import ExpiredPassword from './ExpiredPassword';
import ActivateAccount from './ActivateAccount';
import MfaRouter from './Mfa';
import styles from './AuthPage.module.scss';
import { IMasterUserResponse } from './types';
import { TRANSACTION_STATUS } from './constants';
import createTheme from '@simon/ui/createTheme';

// Temporary theme overrides for SIMON styling
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    [key: string]: any;
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    [key: string]: any;
  }

  interface ThemeOptions {
    [key: string]: any;
  }
}

export default function AuthPage({
  initialMasterUser = null,
}: {
  initialMasterUser?: IMasterUserResponse;
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { env, resumedTransaction, disposeResumedTransaction, theme } =
    useAuth();

  // Route resumed transaction to the appropriate page.
  useEffect(() => {
    if (!resumedTransaction) return;

    // User activation.
    // Important: Should be the first condition because PASSWORD_RESET is used also for reset requests.
    if (
      resumedTransaction.status === TRANSACTION_STATUS.PASSWORD_RESET &&
      resumedTransaction.recoveryType !== 'ACCOUNT_ACTIVATION'
    ) {
      navigate('./activate', { replace: true });
    }
    // MFA Required, Enroll or Activation
    else if (resumedTransaction.status.includes('MFA_')) {
      navigate('./mfa', { replace: true });
    }
    // Password recovery token has been verified.
    else if (
      resumedTransaction.status === TRANSACTION_STATUS.RECOVERY ||
      resumedTransaction.status === TRANSACTION_STATUS.PASSWORD_RESET
    ) {
      navigate('./reset/resume', { replace: true });
    }
    // Password expired.
    else if (
      resumedTransaction.status === TRANSACTION_STATUS.PASSWORD_EXPIRED
    ) {
      navigate('./expired', { replace: true });
    }
    disposeResumedTransaction();
  }, [navigate, location, resumedTransaction, disposeResumedTransaction]);

  return (
    // @ts-ignore
    <ThemeProvider theme={createTheme}>
      {/* @ts-ignore */}
      <Row className={styles.mainRow}>
        {/* @ts-ignore */}
        <Col sm={24} md={12} className={styles.jumboCol}>
          <div className={styles.jumboColContent}>
            <Link to="/">
              {theme.logo}
              {env !== AVAILABLE_ENV.PROD && (
                <Chip className={styles.envPill}>{env}</Chip>
              )}
            </Link>
            <hr />
            <h1>Structured Investments and Annuities Platform</h1>
          </div>
        </Col>
        {/* @ts-ignore */}
        <Col sm={24} md={12} className={styles.rightCol}>
          <div className={styles.rightColContent}>
            <Routes>
              <Route
                index
                element={
                  <LoginPage
                    showDevLogin={theme.showDevLogin}
                    initialMasterUser={initialMasterUser}
                  />
                }
              />
              <Route path="reset/*" element={<ResetPassword />} />
              <Route path="expired" element={<ExpiredPassword />} />
              <Route path="mfa/*" element={<MfaRouter />} />
              <Route path="activate/:token?" element={<ActivateAccount />} />
            </Routes>
            <div className={styles.help}>
              <strong>Need Assistance? Contact Us.</strong>
              <SupernovaLink href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
                {SUPPORT_EMAIL_ADDRESS}
              </SupernovaLink>
              <span className={styles.dot}> • </span>
              <SupernovaLink href="tel:+18773174666">
                +1-877-317-4666
              </SupernovaLink>
            </div>
          </div>
        </Col>
      </Row>
      {theme.showFooter && (
        <React.Fragment>
          {/* @ts-ignore */}
          <Row gutter={16} justify="center" className={styles.footerRow}>
            {/* @ts-ignore */}
            <Col>
              <SupernovaLink
                href="//simon.io/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </SupernovaLink>
            </Col>
            {/* @ts-ignore */}
            <Col>
              <SupernovaLink
                href="//simon.io/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of use
              </SupernovaLink>
            </Col>
            {/* @ts-ignore */}
            <Col>
              <SupernovaLink
                href="//simon.io/business-continuity-planning"
                target="_blank"
                rel="noopener noreferrer"
              >
                BCP Disclosure
              </SupernovaLink>
            </Col>
            {/* @ts-ignore */}
            <Col>
              <SupernovaLink
                href="https://brokercheck.finra.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FINRA&apos;s BrokerCheck
              </SupernovaLink>
            </Col>
          </Row>
          <p className={styles.copyright}>
            &copy; {new Date().getFullYear()} {INC_NAME.toUpperCase()} ALL
            RIGHTS RESERVED.
          </p>
          {theme.disclaimers ? (
            <div className={styles.disclaimers}>{theme.disclaimers}</div>
          ) : (
            <p className={styles.disclaimers}>
              Securities products and services offered by {LLC_COMPANY_NAME}, an
              SEC registered broker/dealer, member{' '}
              <SupernovaLink
                href="https://www.finra.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                FINRA
              </SupernovaLink>{' '}
              and{' '}
              <SupernovaLink
                href="https://www.sipc.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                SIPC
              </SupernovaLink>
              , and an affiliate of {COMPANY_NAME}, Inc and {INC_NAME} (“
              {COMPANY_NAME}”). These registrations and memberships in no way
              imply that the SEC, FINRA, or SIPC have endorsed the entities,
              products or services discussed herein. Annuities and insurance
              services provided by {ANNUITIES_SERVICE_NAME}, an {COMPANY_NAME}{' '}
              affiliate. {COMPANY_NAME} and {NETWORK_COMPANY_NAME} are
              registered trademarks of {INC_NAME} Additional information is
              available upon request.
            </p>
          )}
        </React.Fragment>
      )}
    </ThemeProvider>
  );
}
