import * as Sentry from '@sentry/browser';
import { getBreadCrumbMessageFromHint } from './utils';
import { AVAILABLE_ENV, CONFIG, ENV } from '@simon/config/env';

export const SCOPES = {
  UI: 'ui-failure',
  API: 'api-failure',
  NOT_HANDLED: 'unhandled-failure',
  NOT_RECOVERABLE: 'unrecoverable-failure',
  MIXPANEL: 'no-mixpanel-ids',
};

export function sentryInit(options, appConfig = CONFIG) {
  // Do not initialize Sentry unless you are debugging or is production
  if (!appConfig.isSentryEnabled) return;

  if (!options.dsn) {
    throw new Error(`Sentry: 'dsn' option should be provided.`);
  }

  Sentry.init({
    debug: process.env.REACT_APP_DEBUG_SENTRY === 'true',
    release: appConfig.release,
    environment: ENV,
    ignoreErrors: [
      /SecurityError/,
      /ResizeObserver loop limit exceeded/,
      /Something went wrong./,
    ],
    denyUrls: [/\/sockjs\-node\/info/i, /simon\/api\/eventbus/i],
    beforeBreadcrumb(breadcrumb, hint) {
      const { category, data, level, message } = breadcrumb;

      if (breadcrumb.category === 'ui.click') {
        const textContent = getBreadCrumbMessageFromHint(hint.event?.target);
        if (textContent) {
          breadcrumb.message = textContent;
        }
      }

      /*
        I don't want to mix or enhance the conditional at the moment
        for now we want to keep checking any nested condition under the next categories
       */

      if (category === 'console' && level === 'error') {
        /*
          Because the amount of data generated by the errors, we want to include the information which is relevant.
          For that reason, we will include some error logs coming from issues, only console error and avoiding anything provided by React Props.
        */

        const shouldIgnoreError = [
          'https://fb.me/',
          '[antd:',
          "Can't perform a React state update on an unmounted component",
        ].find(curr => message.includes(curr));
        if (shouldIgnoreError) return null;
        // This is not useful, for that reason we can omit the content
        delete breadcrumb.data.logger;
        delete breadcrumb.data.extra;
      }

      if (category === 'navigation') {
        if (data.from === data.to) {
          return null;
        }
      }

      if (category === 'xhr') {
        const blacklistedUrls = [
          'mixpanel.simonmarkets.com',
          'qa.mixpanel.icapitalnetwork.com',
          'sockjs-node/info',
          'simon/api/eventbus',
        ];

        if (blacklistedUrls.find(curr => data.url.includes(curr))) {
          return null;
        }
      }

      return breadcrumb;
    },
    beforeSend(event, hint) {
      if (hint && hint.originalException) {
        const { message = '' } = hint.originalException;

        // Session is expired. We can't handle on our side yet https://github.com/okta/okta-auth-js/issues/977
        if (
          message.match(
            /The client specified not to prompt, but the user is not logged in|OAuth flow timed out/i
          )
        )
          return null;

        // In non-prod envs, some learning center videos might be missing (bad data).
        if (ENV !== AVAILABLE_ENV.PROD && message.match(/Error playing Video/i))
          return null;

        // Users browsing an old version that is not accessible anymore
        if (message.match(/Loading( CSS)? chunk/i)) return null;

        // When using realtime services, we need to ignore wrong responses because are not needed anyways because session is expired
        if (message.match(/_jp\.[A-Za-z\d]{5,10} is not a function/i))
          return null;

        // Avoid sent network errors, this errors are not worth it because do not provide useful information to Sentry
        if (message.match(/NetworkError \((404|503|504)\)/i)) return null;
      }

      console.error('Error to report', event.event_id);
      return event;
    },
    ...options,
  });
}

/*
NOTES:
- We don't have a consistent way to handle errors, in the app time to time we are passing, strings, objects, Errors, and that's why we need to classify the errors in a better way. For errors sentry will take directly as parameter, for strings it will generate an Error from and retrieve the trace error in addition we have a default value.
- I prefer to create 2 functions for now, in the future those can be too different
*/

export const captureException = (
  error,
  { report = true, type = SCOPES.UI, tags = {} } = {}
) => {
  if (!CONFIG.isSentryEnabled || !report) {
    console.error(error);
    return;
  }

  let eventId = null;

  Sentry.withScope(scope => {
    scope.setTag('error-type', type);

    // add any tag data provided
    for (const key of Object.keys(tags)) {
      scope.setTag(key, tags[key]);
    }

    if (error instanceof Error) {
      eventId = Sentry.captureException(error);
    } else if (error instanceof ErrorEvent) {
      eventId = Sentry.captureException(error.error);
    } else if (typeof error === 'string') {
      eventId = Sentry.captureException(new Error(error));
    } else if (error && error.message && typeof error.message === 'string') {
      eventId = Sentry.captureException(new Error(error.message));
    } else {
      eventId = Sentry.captureException(new Error('Something went wrong.'));
    }
  });

  return eventId;
};
