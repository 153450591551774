import React, { cloneElement, forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Button from '@icapitalnetwork/supernova-core/Button';
import Dropdown from '@simon/ui/Dropdown';
import styles from './ButtonGroup.module.scss';

const ButtonGroup = forwardRef(
  ({ children, className, size, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(styles.btnGroup, className)}
      role="group"
      {...props}
    >
      {React.Children.map(children, child =>
        cloneElement(child, {
          className: cn(
            (child.type === Button || child.type === Dropdown.Toggle) &&
              styles.btn,
            child.type === Dropdown.Toggle && styles.dropdownToggle,
            child.props.className
          ),
          size,
        })
      )}
    </div>
  )
);

ButtonGroup.propTypes = {
  /** Sets the size for all Buttons in the group. */
  size: PropTypes.oneOf(['small', 'large']),
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ButtonGroup;
