import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from '@icapitalnetwork/supernova-core';
import { useInactivityTimer } from './useInactivityTimer';
import useAuth from './useAuth';

const UserInactivityDialog = ({ timeout }) => {
  const { logout } = useAuth();
  const {
    isInactivityWarning,
    setIsInactivityWarning,
    resetInactivityTimer,
    setInactivityLogoutStorage,
  } = useInactivityTimer(timeout);

  const handleContinue = useCallback(() => {
    resetInactivityTimer();
    setIsInactivityWarning(false);
  }, [resetInactivityTimer, setIsInactivityWarning]);

  const handleLogout = useCallback(() => {
    setInactivityLogoutStorage();
    logout();
  }, [logout, setInactivityLogoutStorage]);

  return (
    <Dialog open={isInactivityWarning} onClose={handleContinue}>
      <DialogTitle onClose={handleContinue}>Session Timeout</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Your session will expire in 3 minutes. Click &quot;Continue&quot; to
          extend your session.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="sm" variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
        <Button
          autoFocus
          size="sm"
          variant="contained"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserInactivityDialog;
