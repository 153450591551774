import { useCallback, useRef, useState } from 'react';

/** For more info https://www.npmjs.com/package/uncontrollable */
export default function useUncontrolledProp(propValue, defaultValue, handler) {
  const wasPropRef = useRef(propValue !== undefined);
  const [stateValue, setState] = useState(defaultValue);

  const isProp = propValue !== undefined;
  const wasProp = wasPropRef.current;

  wasPropRef.current = isProp;

  /**
   * If a prop switches from controlled to Uncontrolled
   * reset its value to the defaultValue
   */
  if (!isProp && wasProp && stateValue !== defaultValue) {
    setState(defaultValue);
  }

  return [
    isProp ? propValue : stateValue,
    useCallback(
      (value, ...args) => {
        if (handler) handler(value, ...args);
        setState(value);
      },
      [handler]
    ),
  ];
}
