import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useEventCallback from '@simon/core/hooks/useEventCallback';
import useUncontrolledProp from '@simon/core/hooks/useUncontrolledProp';
import BaseDropdown from 'react-overlays/Dropdown';
import SelectableContext from './SelectableContext';
import DropdownToggle from './DropdownToggle';
import DropdownMenu from './DropdownMenu';
import DropdownItem from './DropdownItem';
import DropdownDivider from './DropdownDivider';
import DropdownHeader from './DropdownHeader';

const Dropdown = ({
  children,
  className,
  as: Component = 'div',
  defaultShow = false,
  show: showProp,
  onToggle: onToggleProp,
  onSelect,
  focusFirstItemOnShow = 'keyboard',
  ...props
}) => {
  const [show, onToggle] = useUncontrolledProp(
    showProp,
    defaultShow,
    onToggleProp
  );

  const onSelectCtx = useContext(SelectableContext);

  const handleToggle = useEventCallback(
    (nextShow, event, source = event.type) => {
      if (
        event.currentTarget === document &&
        (source !== 'keydown' || event.key === 'Escape')
      )
        source = 'rootClose';
      if (onToggle) {
        onToggle(nextShow, event, { source });
      }
    }
  );

  const handleSelect = useEventCallback((key, event) => {
    if (onSelectCtx) onSelectCtx(key, event);
    if (onSelect) onSelect(key, event);
    handleToggle(false, event, 'select');
  });

  return (
    <SelectableContext.Provider value={handleSelect}>
      <BaseDropdown
        defaultShow={defaultShow}
        drop="down"
        show={show}
        onToggle={handleToggle}
        focusFirstItemOnShow={focusFirstItemOnShow}
        itemSelector="a:not([aria-disabled='true'])"
      >
        <Component {...props} className={className}>
          {children}
        </Component>
      </BaseDropdown>
    </SelectableContext.Provider>
  );
};

Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
Dropdown.Divider = DropdownDivider;
Dropdown.Header = DropdownHeader;

Dropdown.propTypes = {
  /**
   * You can use a custom element type for this component.
   */
  as: PropTypes.elementType,
  /**
   * Allow Dropdown to flip in case of an overlapping on the reference element. For more information refer to
   * Popper.js's flip [docs](https://popper.js.org/docs/v2/modifiers/flip/).
   */
  children: PropTypes.node,
  className: PropTypes.string,
  flip: PropTypes.bool,

  /**
   * Toggle the visibility of dropdown menu component.
   */
  show: PropTypes.bool,
  /**
   * Sets the initial show position of the Dropdown.
   */
  defaultShow: PropTypes.bool,
  /**
   * A callback fired when the Dropdown wishes to change visibility. Called with the requested
   * `show` value, the DOM event, and the source that fired it: `'click'`,`'keydown'`,`'rootClose'`, or `'select'`.
   *
   * ```js
   * function(
   *   isOpen: boolean,
   *   event: SyntheticEvent,
   *   metadata: {
   *     source: 'select' | 'click' | 'rootClose' | 'keydown'
   *   }
   * ): void
   * ```
   *
   * @controllable show
   */
  onToggle: PropTypes.func,
  /**
   * A callback fired when a menu item is selected.
   *
   * ```js
   * (eventKey: any, event: Object) => any
   * ```
   */
  onSelect: PropTypes.func,
  /**
   * Controls the focus behavior for when the Dropdown is opened. Set to
   * `true` to always focus the first menu item, `keyboard` to focus only when
   * navigating via the keyboard, or `false` to disable completely
   *
   * The Default behavior is `false` **unless** the Menu has a `role="menu"`
   * where it will default to `keyboard` to match the recommended [ARIA Authoring practices](https://www.w3.org/TR/wai-aria-practices-1.1/#menubutton).
   */
  focusFirstItemOnShow: PropTypes.oneOf([false, true, 'keyboard']),
};

export default Dropdown;
