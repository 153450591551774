import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import RowContext from './RowContext';
import styles from './Grid.module.scss';

function parseFlex(flex) {
  if (typeof flex === 'number') {
    return `${flex} ${flex} 0`;
  }

  // eslint-disable-next-line security/detect-unsafe-regex
  if (/^\d+(\.\d+)?(px|em|rem|%)$/.test(flex)) {
    return `0 0 ${flex}`;
  }

  return flex;
}

const Col = forwardRef((props, ref) => {
  const {
    span,
    order,
    offset,
    push,
    pull,
    className,
    children,
    flex,
    style,
    ...others
  } = props;

  let sizeClassObj = {};

  ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].forEach(size => {
    let sizeProps = {};
    const propSize = props[size];
    if (typeof propSize === 'number') {
      sizeProps.span = propSize;
    } else if (typeof propSize === 'object') {
      sizeProps = propSize || {};
    }

    delete others[size];

    sizeClassObj = {
      ...sizeClassObj,
      [styles[`col-${size}-${sizeProps.span}`]]: sizeProps.span !== undefined,
      [styles[`col-${size}-order-${sizeProps.order}`]]:
        sizeProps.order || sizeProps.order === 0,
      [styles[`col-${size}-offset-${sizeProps.offset}`]]:
        sizeProps.offset || sizeProps.offset === 0,
      [styles[`col-${size}-push-${sizeProps.push}`]]:
        sizeProps.push || sizeProps.push === 0,
      [styles[`col-${size}-pull-${sizeProps.pull}`]]:
        sizeProps.pull || sizeProps.pull === 0,
    };
  });

  const classes = cn(
    styles.col,
    {
      [styles[`col-${span}`]]: span !== undefined,
      [styles[`col-order-${order}`]]: order,
      [styles[`col-offset-${offset}`]]: offset,
      [styles[`col-push-${push}`]]: push,
      [styles[`col-pull-${pull}`]]: pull,
    },
    className,
    sizeClassObj
  );

  return (
    <RowContext.Consumer>
      {({ gutter }) => {
        let mergedStyle = { ...style };
        if (gutter) {
          mergedStyle = {
            ...(gutter[0] > 0
              ? {
                  paddingLeft: gutter[0] / 2,
                  paddingRight: gutter[0] / 2,
                }
              : {}),
            ...(gutter[1] > 0
              ? {
                  paddingTop: gutter[1] / 2,
                  paddingBottom: gutter[1] / 2,
                }
              : {}),
            ...mergedStyle,
          };
        }
        if (flex) {
          mergedStyle.flex = parseFlex(flex);
        }

        return (
          <div {...others} style={mergedStyle} className={classes} ref={ref}>
            {children}
          </div>
        );
      }}
    </RowContext.Consumer>
  );
});

const ColSpanPropType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
]);

const ColSizePropType = PropTypes.shape({
  flex: ColSpanPropType,
  span: ColSpanPropType,
  order: ColSpanPropType,
  offset: ColSpanPropType,
  push: ColSpanPropType,
  pull: ColSpanPropType,
});

Col.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  style: PropTypes.object,
  flex: ColSpanPropType,
  span: ColSpanPropType,
  order: ColSpanPropType,
  offset: ColSpanPropType,
  push: ColSpanPropType,
  pull: ColSpanPropType,
  xs: PropTypes.oneOfType([ColSpanPropType, ColSizePropType]),
  sm: PropTypes.oneOfType([ColSpanPropType, ColSizePropType]),
  md: PropTypes.oneOfType([ColSpanPropType, ColSizePropType]),
  lg: PropTypes.oneOfType([ColSpanPropType, ColSizePropType]),
  xl: PropTypes.oneOfType([ColSpanPropType, ColSizePropType]),
  xxl: PropTypes.oneOfType([ColSpanPropType, ColSizePropType]),
};

export default Col;
