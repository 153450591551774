import React, { cloneElement } from 'react';
import Button from '@simon/ui/Button2';
import cn from 'classnames';
import Dropdown from '@simon/ui/Dropdown';
import ButtonGroup from '@simon/ui/ButtonGroup';
import Select from '@simon/ui/Select2';
import { LEFT, RIGHT } from './constants';
import styles from './Input.module.scss';

/**
 * Override Button, Dropdown and Select addons with necessary custom styling
 */
export const formatAddon = (addonNode, size, position) => {
  if (addonNode?.type === Button) {
    // [Button] addon component
    return cloneElement(addonNode, {
      className: cn(styles.inputGroupAddonButton, addonNode.props.className),
    });
  } else if (addonNode?.type === Dropdown || addonNode?.type === ButtonGroup) {
    // [Dropdown] or [ButtonGroup] addon components
    return cloneElement(addonNode, {
      className: cn(
        styles.inputGroupAddonButtonGroup,
        addonNode.props.className
      ),
    });
  } else if (addonNode?.type === Select) {
    // [Select] addon component
    return cloneElement(addonNode, {
      className: cn(styles.inputGroupAddonSelect, addonNode.props.className),
      styles: {
        control: {
          ...(size === 'compact' && {
            minHeight: '32px',
            lineHeight: 1,
          }),
          borderTopRightRadius: position === LEFT && '0px',
          borderBottomRightRadius: position === LEFT && '0px',
          borderTopLeftRadius: position === RIGHT && '0px',
          borderBottomLeftRadius: position === RIGHT && '0px',
        },
        ...addonNode.props.styles,
      },
    });
  }
  // Text or icon addon
  return <span className={styles.inputGroupAddonText}>{addonNode}</span>;
};
