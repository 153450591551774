import React, { Fragment, useCallback } from 'react';
import Checkbox from '@icapitalnetwork/supernova-core/Checkbox';
import FormControlLabel from '@icapitalnetwork/supernova-core/FormControlLabel';
import { components } from 'react-select';
import cn from 'classnames';
import { Arrow, CircleErrorNew, NewCloseCircle } from '@simon/ui/Icon';
import styles from './Select.module.scss';

const useComponents = ({
  isMulti,
  maxShownValues,
  error,
  valueLabel,
  valueLabelClassName,
}) => ({
  // ClearIndicator
  // Replaces with a custom Close icon.
  ClearIndicator: useCallback(
    ({ selectProps, ...props }) => (
      <components.ClearIndicator {...props} className={styles.clearIndicator}>
        <NewCloseCircle width={15} height={15} />
      </components.ClearIndicator>
    ),
    []
  ),

  // DropdownIndicator
  // Replaces with a custom Arrow icon and flips it if the menu is open.
  DropdownIndicator: useCallback(
    ({ selectProps, ...props }) => (
      <components.DropdownIndicator
        {...props}
        className={cn(styles.dropdownIndicator, {
          [styles.isOpen]: selectProps.menuIsOpen,
        })}
      >
        <Arrow width={10} height={10} />
      </components.DropdownIndicator>
    ),
    []
  ),

  // MultiValue
  // Limits multiValue labels to no more than `maxShownValues`.
  MultiValue: useCallback(
    ({ data, selectProps, getValue, ...props }) => (
      <Fragment>
        {selectProps.value.indexOf(data) < maxShownValues && (
          <components.MultiValue
            data={data}
            {...props}
            selectProps={selectProps}
          >
            {props.children}
          </components.MultiValue>
        )}
      </Fragment>
    ),
    [maxShownValues]
  ),

  // ValueContainer
  // Adds an extra label (e.g. [+2]) that indicates the number of selected options exceeding `maxShownValues`.
  ValueContainer: useCallback(
    ({ children, getValue, ...props }) => (
      <React.Fragment>
        {valueLabel && (
          <span className={cn(styles.valueLabel, valueLabelClassName)}>
            {valueLabel}
          </span>
        )}
        <components.ValueContainer {...props}>
          {children}
          {getValue().length > maxShownValues && (
            <div className={styles.multiValueCount}>
              <span>+{getValue().length - maxShownValues}</span>
            </div>
          )}
        </components.ValueContainer>
      </React.Fragment>
    ),
    [maxShownValues, valueLabel, valueLabelClassName]
  ),

  // IndicatorSeparator
  // Replaces with a custom error icon.
  IndicatorSeparator: () =>
    error ? (
      <span className={styles.indicatorSeparator}>
        <CircleErrorNew width={20} height={20} />
      </span>
    ) : null,

  // Option
  // Overrides a multi option with a custom Checkbox component.
  Option: useCallback(
    ({ children, data, ...props }) =>
      isMulti ? (
        <components.Option
          {...props}
          className={cn(
            styles.option,
            props.isFocused && styles.focused,
            props.isDisabled && styles.disabled
          )}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={props.isSelected}
                indeterminate={data.indeterminate}
                disabled={props.isDisabled}
                sx={{ padding: 0 }}
              />
            }
            label={children}
            className={cn(styles.spanCheckbox)}
          />
        </components.Option>
      ) : (
        <components.Option {...props}>{children}</components.Option>
      ),
    [isMulti]
  ),
});

export default useComponents;
