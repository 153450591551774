import React, { useEffect, useState } from 'react';
import { Link, Navigate, useParams } from 'react-router-dom';
import useAuth from './useAuth';
import PageTitle from './common/PageTitle';
import Alert from '@simon/ui/Alert';
import { Info } from '@simon/ui/Icon';
import {
  Button,
  CircularProgress,
  Link as SupernovaLink,
  TextField,
  InputAdornment,
} from '@icapitalnetwork/supernova-core';
import Spinner from '@simon/ui/Spinner';
import ErrorMessage from './common/ErrorMessage';
import LockIcon from '@mui/icons-material/Lock';
import {
  COMPANY_NAME,
  PHONE_NUMBER,
  SUPPORT_EMAIL_ADDRESS,
} from '@simon/core/constants/branding';

export default function ActivateAccount() {
  const { token } = useParams();
  const {
    loginCredentials,
    loginSessionToken,
    transaction: transactionCtx,
  } = useAuth();
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [formState, setFormState] = useState(() => ({
    status: transactionCtx ? 'verify-ok' : 'pending',
    transaction: transactionCtx,
    error: null,
  }));
  const { transaction } = formState;

  // Verify activation token from email.
  useEffect(() => {
    if (!token) {
      return;
    }

    (async () => {
      try {
        const transaction = await loginCredentials({ token });
        setFormState(s => ({
          ...s,
          transaction,
          status: 'verify-ok',
        }));
      } catch (e) {
        setFormState(s => ({
          ...s,
          error: e,
          status: 'verify-error',
        }));
      }
    })();
  }, [loginCredentials, token]);

  // No resumed transaction, nor token.
  // Direct access is forbidden.
  if (!transaction && !token) {
    return <Navigate to=".." />;
  }

  // Verifying recovery token.
  if (formState.status === 'pending') {
    return (
      <div>
        <PageTitle title={`Welcome to ${COMPANY_NAME}!`} />
        <Spinner isMainLoader />
      </div>
    );
  }

  // Verification error. Form to request a new one.
  if (formState.status === 'verify-error') {
    return (
      <React.Fragment>
        <PageTitle title="Password Link Expired" />
        <Alert
          showIcon
          icon={<Info width={14} height={14} color="#f5222d" />}
          message="Your password link is no longer valid."
          description={
            <React.Fragment>
              The activation token associated with your account has expired.
              <br />
              To get help setting up your account, reach out to our team at{' '}
              <SupernovaLink href={`mailto:${SUPPORT_EMAIL_ADDRESS}`}>
                {SUPPORT_EMAIL_ADDRESS}
              </SupernovaLink>{' '}
              or{' '}
              <SupernovaLink href={`tel:${PHONE_NUMBER}`}>
                {PHONE_NUMBER}
              </SupernovaLink>
              .
            </React.Fragment>
          }
          type="error"
        />
        <Button
          variant="text"
          size="md"
          fullWidth
          sx={{ mt: 0.5 }}
          component={Link}
          to=".."
        >
          Back to Sign-in
        </Button>
      </React.Fragment>
    );
  }
  // Activation token verified (status: verify-ok)

  // PASSWORD_RESET, recoveryType=ACCOUNT_ACTIVATION
  if (transaction.status === 'PASSWORD_RESET') {
    return (
      <React.Fragment>
        <PageTitle
          title="Reset Password"
          subtitle={
            <React.Fragment>
              Enter a new password for your account.
              <br />
              <strong>{transaction.user.profile.login}</strong>
            </React.Fragment>
          }
        />
        <form
          onSubmit={async e => {
            e.preventDefault();
            setFormState(s => ({ ...s, status: 'pwd-change-fetching' }));
            try {
              const transaction = await formState.transaction.resetPassword({
                newPassword,
              });

              // SUCCESS, login
              if (transaction.status === 'SUCCESS') {
                // todo onEvent(EVENT.ResetPasswordChangeSuccess);
                return await loginSessionToken(transaction.sessionToken);
              }

              setFormState(s => ({
                ...s,
                // status: 'pwd-change-ok',
                error: null,
                transaction,
              }));
            } catch (e) {
              // onEvent(EVENT.ResetPasswordChangeError, { error: e });
              setFormState(s => ({
                ...s,
                status: 'pwd-change-error',
                error: 'Password requirements were not met.',
              }));
            }
          }}
        >
          <TextField
            fullWidth
            hideClearButton
            autoFocus
            type="password"
            autoComplete="new-password"
            placeholder="New Password"
            name="new-password"
            value={newPassword}
            onChange={e => {
              setNewPassword(e.target.value);
            }}
            required
            aria-label="New Password"
            InputProps={{
              startAdornment: (
                // @ts-ignore
                <InputAdornment position="start">
                  <LockIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            fullWidth
            hideClearButton
            type="password"
            autoComplete="new-password"
            placeholder="Confirm New Password"
            name="confirm-new-password"
            value={confirmNewPassword}
            onChange={e => {
              setConfirmNewPassword(e.target.value);
            }}
            required
            aria-label="Confirm New Password"
            sx={{ mt: 0.5 }}
            InputProps={{
              startAdornment: (
                // @ts-ignore
                <InputAdornment position="start">
                  <LockIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
          />
          {formState.error && <ErrorMessage error={formState.error} />}
          <Button
            fullWidth
            size="md"
            type="submit"
            sx={{ mt: 1 }}
            disabled={
              formState.status === 'pwd-change-fetching' ||
              !newPassword ||
              !confirmNewPassword ||
              newPassword !== confirmNewPassword
            }
            startIcon={
              formState.status === 'pwd-change-fetching' && (
                <CircularProgress sx={{ color: 'inherit' }} size="1rem" />
              )
            }
          >
            Reset Password
          </Button>
        </form>
      </React.Fragment>
    );
  }

  if (transaction.status.includes('MFA_')) {
    return <Navigate replace to="../mfa" />;
  }

  return <Navigate replace to="/" />;
}
