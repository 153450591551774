import Cookies from 'js-cookie';
import authService from './authService';
import createAPI, { SIMON_API_URL } from '@simon/core/services/api';
import showSessionExpiredNotification from '@simon/core/utils/session-expired';

// Can't use simonApi directly because it has a reference to @simon/auth which is unresolved during build.
export const simonApi = createAPI({
  fetch: window.fetch,
  interceptors: {
    // @ts-ignore
    request: async (uri, config) => {
      // add CSRF token (if present) to all non-GET requests
      if (config.method !== 'GET') {
        const csrfToken = Cookies.get('simon-csrf-token');
        if (csrfToken) {
          config.headers['x-simon-csrf-token'] = csrfToken;
        }
      }
      // Implicitly true
      if (config.authorization !== false) {
        const token = await authService?.getAccessToken?.();
        if (token) {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          console.warn(
            `${config.method} ${uri} request marked as secure but no tokens available.`
          );
        }
      }
      if (uri.startsWith('http')) {
        // Absolute URL
        return [uri, config];
      }
      // Relative URL, prepend SIMON API base URL
      return [`${SIMON_API_URL}${uri}`, config];
    },

    responseError: err => {
      // Show popup for expired sessions
      if (
        err.status === 401 &&
        // don't show failures from non-cancelled requests after logout
        authService?.isAuthenticated?.()
      ) {
        showSessionExpiredNotification();
      }

      // reject the promise
      throw err;
    },
  },
});

export const setCSRFToken = token => Cookies.set('icn-csrf-token', token);

let icnApiPrefix = '';
export const setICNApiPrefix = prefix => (icnApiPrefix = prefix);

export const icnApi = createAPI({
  fetch: window.fetch,
  interceptors: {
    // @ts-ignore
    request: async (uri, config) => {
      // add CSRF token (if present) to all non-GET requests
      if (config.method !== 'GET') {
        const csrfToken = Cookies.get('icn-csrf-token');
        if (csrfToken) {
          config.headers['x-csrf-token'] = csrfToken;
        }
      }

      // Implicitly true
      if (config.authorization !== false) {
        const token = await authService?.getAccessToken?.();
        if (token) {
          if (!config.headers) {
            config.headers = {};
          }
          config.headers.Authorization = `Bearer ${token}`;
        } else {
          console.warn(
            `${config.method} ${uri} request marked as secure but no tokens available.`
          );
        }
      }

      config.credentials = 'same-origin';

      if (uri.startsWith('http')) {
        // Absolute URL
        return [uri, config];
      }

      return [
        icnApiPrefix?.startsWith('http')
          ? icnApiPrefix + uri
          : `${icnApiPrefix || ''}/api${uri}`,
        config,
      ];
    },

    responseError: err => {
      // Show popup for expired sessions
      if (
        err.status === 401 &&
        // don't show failures from non-cancelled requests after logout
        authService?.isAuthenticated?.()
      ) {
        showSessionExpiredNotification();
      }

      // reject the promise
      throw err;
    },
  },
});

export const postICNSignIn = (email: string, password: string) =>
  icnApi.post(
    `/v1/login.json`,
    {
      user: {
        email,
        password,
        white_label_partner_id: 0,
      },
      query_string: {},
    },
    {
      authorization: false,
    }
  );

// Factor title. Also used to check if a factor is supported.
export const getFactorTitle = factor =>
  ({
    OKTA: {
      push: 'OKTA Verify',
      question: 'Security Question',
      sms: 'Text',
      call: 'Call',
    },
  }[factor.provider]?.[factor.factorType]);

export const formattedPhoneNumber = (code, number) => {
  const cleanNumber = number.replace(/\D/g, '');
  return `+${code}-${cleanNumber.substring(0, 3)}-${cleanNumber.substring(
    3,
    6
  )}-${cleanNumber.substring(6)}`;
};
