import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageTitle from './common/PageTitle';
import useAuth from './useAuth';
import { EVENT } from './constants';
import ErrorMessage from './common/ErrorMessage';
import Button from '@icapitalnetwork/supernova-core/Button';
import usePolling from '@simon/core/hooks/usePolling';
import styles from './MfaEnrollActivate.module.scss';
import { COMPANY_NAME } from '@simon/core/constants/branding';

export default function MfaEnrollActivate() {
  const { transaction } = useAuth();

  const selectedFactor = transaction.factor;

  return (
    <React.Fragment>
      <PageTitle
        title={`Welcome to ${COMPANY_NAME}!`}
        subtitle={
          <React.Fragment>
            Multi-factor authentication set up for:
            <br />
            <strong>{transaction.user.profile.login}</strong>
          </React.Fragment>
        }
      />
      {selectedFactor?.provider === 'OKTA' &&
        selectedFactor?.factorType === 'push' && (
          <OktaPushFactor transaction={transaction} factor={selectedFactor} />
        )}
    </React.Fragment>
  );
}

function OktaPushFactor({ transaction, factor }) {
  const { loginSessionToken, onEvent, setTransaction } = useAuth();
  const [formState, setFormState] = useState(() => ({
    status: 'pending',
    error: null,
  }));
  const navigate = useNavigate();

  usePolling(transaction.poll, {
    onSuccess: async nextTransaction => {
      if (nextTransaction.status === 'SUCCESS') {
        onEvent(EVENT.MFAEnrollSuccess, {
          provider: factor.provider,
          factorType: factor.factorType,
        });
        loginSessionToken(nextTransaction.sessionToken);
        return false;
      }

      if (nextTransaction.factorResult !== 'WAITING') {
        setFormState({
          status: 'poll-error',
          error:
            nextTransaction.factorResult === 'TIMEOUT'
              ? 'QR code expired'
              : `Error: ${nextTransaction.factorResult}`,
        });
        return false;
      }

      // continue polling
      return true;
    },
    onError: e => {
      setFormState({ status: 'poll-error', error: e });
    },
    delay: transaction.factorResult === 'WAITING' ? 4000 : null,
  });

  return (
    <React.Fragment>
      <div className={styles.oktaPushContainer}>
        <label htmlFor="answer" className={styles.securityQuestionLabel}>
          Scan the QR code using OKTA Verify mobile app to sign in.
        </label>
        <img src={factor.activation.qrcode?.href} />
        {formState.error && <ErrorMessage error={formState.error} />}
        <div>
          <Button
            size="md"
            onClick={async () => {
              setTransaction(await transaction.prev());
              navigate('..');
            }}
          >
            Refresh QR code
          </Button>
        </div>
      </div>
      <Button
        variant="text"
        size="md"
        fullWidth
        sx={{ mt: 'auto' }}
        onClick={async () => {
          await transaction.cancel();
          setTransaction(null);
          navigate('..');
        }}
      >
        Back to Sign-in
      </Button>
    </React.Fragment>
  );
}
