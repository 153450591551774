import { Map, List, OrderedMap, Set } from 'immutable';

const MILLISECOND = 1;
const SECOND = 1000 * MILLISECOND;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const YEAR = 365 * DAY;
const MONTH = YEAR / 12;

export const TIME_CONSTANTS = {
  MILLISECOND,
  SECOND,
  MINUTE,
  HOUR,
  DAY,
  WEEK,
  MONTH,
  YEAR,
};

export const DATE_NOW = new Date();

// default empty values for common structures
// used mostly in selectors to prevent components re-rendering caused by reference change
export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};
export const EMPTY_IMMUTABLE_MAP = Map();
export const EMPTY_IMMUTABLE_LIST = List();
export const EMPTY_IMMUTABLE_SET = Set();
export const EMPTY_IMMUTABLE_ORDERED_MAP = OrderedMap();

export const NUMBER_OF_STEPS_IN_YEAR = {
  Annual: 1,
  Quarterly: 3,
  Monthly: 12,
};

export const WEEKS_PER_MONTHS = 52 / 12;

export const WHITESPACE_REGEX = / /g;

export const YES = 'Yes';
export const NO = 'No';
export const GOOD = 'Good';
export const OTHER = 'Other';
export const SUCCESS = 'Success';

export const SIMON = 'SIMON';
export const iCapital = 'iCapital';

export const PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP_RIGHT: 'topRight',
  TOP_LEFT: 'topLeft',
  BOTTOM_RIGHT: 'bottomRight',
  BOTTOM_LEFT: 'bottomLeft',
};

export const PROMISE_FULFILLED = 'fulfilled';
export const PROMISE_REJECTED = 'rejected';
