import React from 'react';
import cn from 'classnames';
import { CheckMarkCircleVariation, ErrorCopy2 } from '@simon/ui/Icon';
import styles from './MfaAlert.module.scss';

export default function MfaAlert({ children, isError = false }) {
  return (
    <div
      className={cn(styles.alertContainer, {
        [styles.alertContainerError]: isError,
        [styles.alertContainerSuccess]: !isError,
      })}
    >
      {isError && (
        <div className={styles.errorSvg}>
          <ErrorCopy2 width={20} />
        </div>
      )}
      {!isError && (
        <div className={styles.successSvg}>
          <CheckMarkCircleVariation width={20} />
        </div>
      )}
      <p className={styles.alertMessage}>{children}</p>
    </div>
  );
}
