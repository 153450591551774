import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import useAuth from './useAuth';
import usePrevious from '@simon/core/hooks/usePrevious';

export default function useRestoreOriginalUrl() {
  const { authState, getOriginalUrl, removeOriginalUrl, isSwitchingUser } =
    useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const prevAuthState = usePrevious(authState);

  useEffect(() => {
    if (!authState?.isAuthenticated) return;

    // Restore original URL after successful authentication
    const originalUrl = getOriginalUrl();
    if (originalUrl) {
      navigate(originalUrl, { replace: true });
      removeOriginalUrl();
    }
    // Redirect to / once authenticated and on a page part of the auth stack
    else if (
      !isSwitchingUser &&
      prevAuthState?.isAuthenticated === false &&
      location.pathname.startsWith('/auth')
    ) {
      navigate('/', { replace: true });
    }
  }, [
    authState,
    getOriginalUrl,
    navigate,
    isSwitchingUser,
    location.pathname,
    prevAuthState,
    removeOriginalUrl,
  ]);
}
