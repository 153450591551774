/*
  Timezones
*/

export const TIMEZONE_NYC = 'America/New_York';

/*
  Dates
*/

// Thursday
const DAY_OF_THE_WEEK = 'dddd';
// Tue
const DAY_OF_THE_WEEK_02 = 'ddd';
// 2022
export const MOMENT_YEAR_FORMAT = 'YYYY';
// 2022-06-02
export const MOMENT_ISO_DATE_FORMAT = 'YYYY-MM-DD';
// 02-Jun-2022
export const MOMENT_EU_DATE_FORMAT = 'DD-MMM-YYYY';
// 02 Jun 22
export const MOMENT_EU_DATE_FORMAT_02 = 'DD MMM YY';
// Jun-2022
export const MOMENT_EU_MONTH_YEAR_FORMAT = 'MMM-YYYY';
// 20220602
export const MOMENT_ISO_DATE_FORMAT_NO_DELIMETER = 'YYYYMMDD';
// 06/02/2022
export const MOMENT_US_FORMAT = 'MM/DD/YYYY';
// 6/2/2022
export const MOMENT_US_FORMAT_SINGLE = 'M/D/YYYY';
// 06/02/22
export const MOMENT_US_FORMAT_SHORT = 'MM/DD/YY';
// 6/2/22
export const MOMENT_US_FORMAT_SHORT_VARIATION_2 = 'M/D/YY';
// Jun 2, 2022
export const MOMENT_FORMAT_02 = 'MMM D, YYYY';
// Jun 02, 2022
export const MOMENT_FORMAT_03 = 'MMM DD, YYYY';
// June 2, 2022
export const MOMENT_FORMAT_04 = 'MMMM D, YYYY';
// June 02, 2022
export const MOMENT_FORMAT_PDF = 'MMMM DD, YYYY';
// Jun-2022
export const MOMENT_MONTH_YEAR_FORMAT = 'MMM-YYYY';
// June 02
export const MOMENT_MONTH_YEAR_FORMAT_02 = 'MMMM YYYY';

/*
  Times
*/

// EDT
const TIMEZONE = 'z';
// AM
const AM_PM = 'A';
// 9am
const SHORTENED_TIME = 'ha';
// 03:21
const HOURS_MINUTES_12 = 'hh:mm';
// 15:21
const HOURS_MINUTES_24 = 'HH:mm';
// 03:21:38
const HOURS_MINUTES_SECONDS_12 = 'hh:mm:ss';
// 3:21
const HOURS_MINUTES_12_NO_LEADING_ZEROS = 'h:mm';
// 15:21:38
const HOURS_MINUTES_SECONDS_24 = 'HH:mm:ss';
// 03:21 PM
export const MOMENT_FORMAT_12_HOURS_MINUTES_AM = `${HOURS_MINUTES_12} ${AM_PM}`;
// 3:21 PM
export const MOMENT_FORMAT_12_HOURS_MINUTES_AM_NO_LEADING_ZEROS = `${HOURS_MINUTES_12_NO_LEADING_ZEROS} ${AM_PM}`;
// 03:21:38 PM
export const MOMENT_FORMAT_12_HOURS_MINUTES_SECONDS_AM = `${HOURS_MINUTES_SECONDS_12} ${AM_PM}`;
// 03:21 PM EDT
export const MOMENT_FORMAT_12_HOURS_MINUTES_AM_ZONE = `${MOMENT_FORMAT_12_HOURS_MINUTES_AM} ${TIMEZONE}`;
// 09:21 AM
export const MOMENT_FORMAT_24_HOURS_MINUTES_AM = `${HOURS_MINUTES_24} ${AM_PM}`;
// 09:21 AM EDT
export const MOMENT_FORMAT_24_HOURS_MINUTES_AM_ZONE = `${MOMENT_FORMAT_24_HOURS_MINUTES_AM} ${TIMEZONE}`;
// 09:21 EDT
export const MOMENT_FORMAT_24_HOURS_MINUTES_ZONE = `${HOURS_MINUTES_24} ${TIMEZONE}`;

/*
  Dates + Times
*/

// 06/02/2022 09:21:38 - MM/DD/YYYY hh:mm:ss
export const MOMENT_US_FORMAT_TIME = `${MOMENT_US_FORMAT} ${HOURS_MINUTES_SECONDS_24}`;
// 06/02/2022 09:21 - MM/DD/YYYY hh:mm
export const MOMENT_US_FORMAT_TIME_MINUTES = `${MOMENT_US_FORMAT} ${HOURS_MINUTES_12}`;
// 06/02/2022 09:21 - MM/DD/YYYY HH:mm
export const MOMENT_US_FORMAT_24_HOURS_MINUTES = `${MOMENT_US_FORMAT} ${HOURS_MINUTES_24}`;
// 06/02/2022 09:21AM - MM/DD/YYYY hh:mmA
export const MOMENT_US_FORMAT_TIME_MINUTES_AM = `${MOMENT_US_FORMAT_TIME_MINUTES}${AM_PM}`;
// 06/02/2022 09:21 AM EDT - MM/DD/YYYY hh:mm A z
export const MOMENT_US_FORMAT_TIME_12_HOUR_MINUTES_ZONE = `${MOMENT_US_FORMAT} ${MOMENT_FORMAT_12_HOURS_MINUTES_AM_ZONE}`;
// 06/02/2022 09:21 EDT - MM/DD/YYYY HH:mm z
export const MOMENT_US_FORMAT_TIME_24_HOUR_MINUTES_ZONE = `${MOMENT_US_FORMAT} ${MOMENT_FORMAT_24_HOURS_MINUTES_ZONE}`;
// 06/02/22 09:21 AM EDT - MM/DD/YY hh:mm A z
export const MOMENT_US_FORMAT_SHORT_TIME_12_HOUR_MINUTES_ZONE = `${MOMENT_US_FORMAT_SHORT} ${MOMENT_FORMAT_12_HOURS_MINUTES_AM_ZONE}`;
// 09:21 AM EDT 06/02/22 - HH:mm A MM/DD/YY z
export const MOMENT_US_FORMAT_24_HOUR_MINUTES_ZONE_SHORT_TIME = `${MOMENT_FORMAT_24_HOURS_MINUTES_AM_ZONE} ${MOMENT_US_FORMAT_SHORT}`;
// 06/02/2022 09:21 AM - MM/DD/YYYY hh:mm A
export const MOMENT_FORMAT_TIME_12_HOUR_MINUTES_ZONE = `${MOMENT_US_FORMAT} ${MOMENT_FORMAT_12_HOURS_MINUTES_AM}`;
// June 02 2022, 9am - MMMM DD YYYY, ha
export const MOMENT_FORMAT_LONG = `${MOMENT_FORMAT_PDF}, ${SHORTENED_TIME}`;
// June 2, 2022 09:21 AM - MMMM D, YYYY hh:mm A
export const MOMENT_FORMAT_04_MOMENT_FORMAT_12_HOURS_MINUTES = `${MOMENT_FORMAT_04} ${MOMENT_FORMAT_12_HOURS_MINUTES_AM}`;
// June 2, 2022 09:21 AM - MMMM D, YYYY h:mm A
export const MOMENT_FORMAT_04_MOMENT_FORMAT_12_HOURS_MINUTES_NO_LEADING_ZEROS = `${MOMENT_FORMAT_04} ${MOMENT_FORMAT_12_HOURS_MINUTES_AM_NO_LEADING_ZEROS}`;
// 2022-06-02T09:51:04 - YYYY-MM-DDTHH:mm:ss
export const MOMENT_ISO_DATE_24_HOURS_MINUTES_SECONDS = `${MOMENT_ISO_DATE_FORMAT}T${HOURS_MINUTES_SECONDS_24}`;
// 2022-06-02T09:21:38.628Z - YYYY-MM-DD[T]HH:mm:ss.SSS[Z]
export const MOMENT_ISO_DATE_24_HOUR_MINUTES_SECONDS_UTC = `${MOMENT_ISO_DATE_FORMAT}[T]${HOURS_MINUTES_SECONDS_24}.SSS[Z]`;
// 2022-06-02T09:21:38+0000 - YYYY-MM-DD[T]HH:mm:ssZZ
export const MOMENT_ISO_DATE_24_HOUR_MINUTES_SECONDS_UTC_HHmm = `${MOMENT_ISO_DATE_FORMAT}[T]${HOURS_MINUTES_SECONDS_24}+0000`;
// Thursday, June 02, 2022, 09:21 AM - dddd, MMMM DD, YYYY, hh:mm A
export const MOMENT_US_FORMAT_WITH_DAY_NAME_AND_TIME = `${DAY_OF_THE_WEEK}, ${MOMENT_FORMAT_PDF}, ${MOMENT_FORMAT_12_HOURS_MINUTES_AM}`;
// Tue 02 June 22 09:21:38 AM - dd DD MMMM YY hh:mm:ss A
export const MOMENT_EU_FORMAT_WITH_DAY_NAME_AND_TIME = `${DAY_OF_THE_WEEK_02} ${MOMENT_EU_DATE_FORMAT_02} ${MOMENT_FORMAT_12_HOURS_MINUTES_SECONDS_AM}`;
// 06/02/2022 | 03:21 PM - MM/DD/YYYY | hh:mm A
export const MOMENT_US_FORMAT_TIME_EMAIL = `${MOMENT_US_FORMAT} | ${MOMENT_FORMAT_12_HOURS_MINUTES_AM}`;
// 06-02-2022_16-14 - MM-DD-YYYY_HH-mm
export const MOMENT_FILE_NAME_DATE_TIME = 'MM-DD-YYYY_HH-mm';
