import loadScript from '@simon/core/utils/loadScript';

export const initEqualWeb = () => {
  window.interdeal = {
    sitekey: '2156342afb4929dfe24188de4bba3089',
    Position: 'left',
    Menulang: 'EN',
    domains: {
      js: 'https://aacdn.nagich.com/',
      acc: 'https://access.nagich.com/',
    },
    btnStyle: {
      vPosition: ['90%', undefined],
      scale: ['0.4', '1'],
      color: { main: '#2f66a5', second: 'white' },
      icon: {
        type: 3,
        shape: 'circle',
        outline: true,
      },
    },
  };
  loadScript(window.interdeal.domains.js + 'core/4.5.4/accessibility.js', {
    defer: true,
    crossOrigin: 'anonymous',
    integrity:
      'sha512-J5/6Qjg54z2N3BXS6KyGkQofTakIOMOe7cHKhLy03QezCMmHO2TJftsD/niW3/kzH3HGLKUBAI73EapyIYcMCw==',
  });
};
